export enum ActionTypes {
  ADD_ANNUAL_MILEAGE = "ADD NEW ANNUAL MILEAGE",
  ADD_FINANCED_AMOUNT = "ADD NEW FINANCED AMOUNT",
  UPDATE_ANNUAL_MILEAGE = "UPDATE ANNUAL MILEAGE",
  SET_ANNUAL_MILEAGES_INIT_STATE = "GET ANNUAL MILEAGE FROM SERVER",
  SET_FINANCED_AMOUNTS_INIT_STATE = "GET FINANCED AMOUNT FROM SERVER",
  SET_ANNUAL_MILEAGES_FILTER_INIT_STATE = "GET ANNUAL MILEAGE FROM SERVER FILTER",
  SET_FINANCED_AMOUNT_FILTER_INIT_STATE = "GET FINANCED AMOUNT FROM SERVER FILTER",

  ADD_CREDIT_RATING = "ADD NEW CREDIT RATING",
  UPDATE_CREDIT_RATING = "UPDATE CREDIT RATING",
  SET_CREDIT_RATING_INIT_STATE = "GET CREDIT RATING FROM SERVER",

  ADD_ASSET_MAKE = "ADD NEW ASSET MAKE",
  UPDATE_ASSET_MAKE = "UPDATE ASSET MAKE",
  SET_ASSET_MAKE_INIT_STATE = "GET ASSET MAKE FROM SERVER",

  ADD_ASSET_MODEL = "ADD NEW ASSET MODEL",
  UPDATE_ASSET_MODEL = "UPDATE ASSET MODEL",
  SET_ASSET_MODEL_INIT_STATE = "SET ASSET MODEL FROM SERVER",
  SET_ASSET_MODEL_BY_MAKE = "SET ASSET MODEL BY MAKE",

  ADD_ASSET_TRIM = "ADD NEW Trim",
  UPDATE_ASSET_TRIM = "UPDATE Trim",
  SET_ASSET_TRIM_INIT_STATE = "SET Trim FROM SERVER",
  SET_ASSET_TRIM_BY_MODEL = "SET Trim BY MODEL",
  ADD_INTEREST_CHARTS = "ADD_INTEREST_CHARTS",
  SET_INTEREST_CHART_BY_CHART = "SET_INTEREST_CHART_BY_CHART",
  UPDATE_INTEREST_CHARTS = "UPDATE_INTEREST_CHARTS",
  SET_INTEREST_CHART_INIT_STATE = "SET_INTEREST_CHART_INIT_STATE",

  SET_TERMS_INITIAL_DATA = "SET_TERMS_INITIAL_DATA",

  SET_CHARTS_INIT_STATE = "SET_CHARTS_INIT_STATE",
  ADD_CHARTS = "ADD_CHARTS",
  SET_DRAWER_OPEN = "SET DRAWER OPEN",

  ADD_CHART = "ADD CHART",
  ADD_RV_CHART = "ADD RV CHART",
  UPDATE_RV_CHART = "UPDATE RV CHART",
  SET_RV_CHART_INIT_STATE = "GET RV CHARTS FROM SERVER",
  SET_LENDER_PROFILE_INIT_STATE = "GET LENDER PROFILE FROM SERVER",
  SET_TOAST = "SET TOAST",

  ADD_LENDER_ADDRESS = "ADD LENDER ADRESSES",
  UPDATE_LENDER_ADDRESS = "UPDATE LENDER ADDRESSES",
  SET_LENDER_ADDRESS_INIT_STATE = "GET LENDER ADDRESS FROM SERVER",
  ADD_PROGRAM = "ADD PROGRAM",
  SET_LENDER_ADDRESS_FOR_VERIFICATION = "GET LENDER ADDRESS FROM SERVER TO CALL ADDRESS VERIFICATION API",

  SET_PROGRAM_INIT_STATE = "SET PROGRAM INIT STATE",

  ADD_FP_ASSET_GROUP = "ADD FP ASSET GROUP",
  REMOVE_FP_ASSET_GROUP = "REMOVE FP ASSET GROUP",

  SET_STATES_INITIAL_DATA = "SET_STATES_INITIAL_DATA",
  SET_DOCUMENT_TYPES_INITIAL_DATA = "SET_DOCUMENT_TYPES_INITIAL_DATA",

  SET_STIPULATION_INITIAL_DATA = "SET_STIPULATION_INITIAL_DATA",
  UPDATE_STIPULATION = "UPDATE STIPULATION",

  SET_CURRENCIES_INITIAL_DATA = "SET_CUURENCIES_INITIAL_DATA",

  SET_FEE_NAMES_INITIAL_DATA = "SET_FEE_NAMES_INITIAL_DATA",
  UPDATE_FEE_NAMES = "UPDATE FEE NAMES",

  SET_DECISION_STATUS_INITIAL_DATA = "SET_DECISION_STATUS_INITIAL_DATA",
  UPDATE_DECISION_STATUS = "UPDATE DECISION STATUS",

  SET_CONTRACT_ALLOWED_ANNUM_MILLEAGE_DATA = "SET CONTRACT ALLOWED ANNUM MILLEAGE DATA",
  UPDATE_CONTRACT_ALLOWED_ANNUM_MILLEAGE = "UPDATE CONTRACT ALLOWED ANNUM MILLEAGE",

  SET_INTEGRATION_STATUS_INITIAL_DATA = "SET_INTEGRATION_STATUS_INITIAL_DATA",
  UPDATE_INTEGRATION_STATUS = "UPDATE INTEGRATION STATUS",

  SET_PIN_STATE = "SET PIN STATE",

  STORAGE_PATH_NOT_CONFIGURED = "Storage Path not configured. Please contact your System Administrator.",
  DISABLED_COMMISSION_CHART = "Please complete the mandatory configurations in commission tab.",
  DISABLED_SUBSIDY_CHART = "Please complete the mandatory configurations in subsidy tab.",
}
