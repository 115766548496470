import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useStoreContext } from "../Stores/SetupStore/Store";
import { IRvChart, IRvChartCriteria } from "../Interface";

export const getAllRvChartsFromServer = () => {
  return ConfigurationAPI.get("/rv-chart").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllRvCharts = () => {
  const { actions } = useStoreContext();

  return useQuery(QueryKeys.GET_ALL_RV_CHARTS, async () => {
    return getAllRvChartsFromServer().then((data) => {
      // dispatch(setRvChartInitialData(data));
      return data;
    });
  });
};

export const getAllRVChartsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(`/rv-chart/filter?`.concat(columnFilters)).then(
    (response) => {
      return response.data;
    }
  );
};

export const UseQuery_getAllRVCharts_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_RV_CHARTS_FILTER, columnFilters],
    async () => {
      return getAllRVChartsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useMutation_AddNewRvChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IRvChartCriteria>>(
    (rvChart) => {
      return ConfigurationAPI.post("/rv-chart/criteria", rvChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewRvCriteria = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<Array<IRvChart>>(
    (rvChart) => {
      return ConfigurationAPI.post("/rv-chart", rvChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_UpdateRvChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IRvChart>>(
    (rvChart: any) => {
      return ConfigurationAPI.patch(`/rv-chart/${rvChart.id}`, rvChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_DeleteRvChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IRvChart>>(
    (rvChartId: any) => {
      return ConfigurationAPI.delete(`/rv-chart/${rvChartId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_CheckDuplicateRVChartName: any = () => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      return ConfigurationAPI.get<any>(
        params ? `${"/rv-charts/name"}?${params}` : "/rv-charts/name"
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};
