export const CUSTOM_LINK_TYPES = ["Header", "Footer", "Sidebar"];
export const CUSTOM_LINK_STATUSES = ["ACTIVE", "DEACTIVE"];
export const PRICING_PROGRAM = [
  "Internal Program Evaluation",
  "External",
  "Standard Rates",
  "Standard Charts",
];
export const CREDIT_DECISION = [
  "Internal",
  "External",
];
export const E_CONTRACTING = [
  "Not Required",
  "Internal",
  "External",
];