import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Grid, Textarea, Typography } from "@ntpkunity/controls";
import { TextAreaWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { useGetDealerChatByDealerId } from "services/dealer-configurations.service";
import { IDealerChat } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
 
interface DealerChatProps {
  form: any;
  dealer_code: string | number | null;
  formDefaultData: any
}
 
const DealerChat: FC<DealerChatProps> = ({ form, dealer_code,formDefaultData }) => {
  const { actions } = useStoreContext();
  const theme = useTheme();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = form;
 
  const { mutate: getDealerChat } = useGetDealerChatByDealerId()
 
  const [dealerChat, setDealerChat] = useState<IDealerChat | null>(null);
  const [charCounts, setCharCounts] = useState({
    welcome_chat: 0,
    offline_message: 0,
  });

  const MAX_CHAR_LIMIT = 200;
 
  useEffect(() => {
    if (dealer_code) {
      getDealerChat(
        { id: dealer_code },
        {
          onSuccess(data: IDealerChat) {
            if (data) {
              formDefaultData.current = {
                ...formDefaultData.current,
                chat: data,
                theme: getValues("theme"),
              };
              setDealerChat(data);
              setValue("chat.id", data.id);
              setValue("chat.welcome_chat", data.welcome_chat);
              setValue("chat.offline_message", data.offline_message);
              setCharCounts({
                welcome_chat: data.welcome_chat?.length || 0,
                offline_message: data.offline_message?.length || 0,
              });
            } else {
              setDealerChat(null);
              setValue("chat.id", null);
              setValue("chat.welcome_chat", "");
              setValue("chat.offline_message", "");
              setCharCounts({
                welcome_chat: 0,
                offline_message: 0,
              });
            }
          },
        }
      );
    }
  }, [dealer_code, getDealerChat, setValue]);
 
  const handleChatCounter = (name: string, charCount: number) => {
    setCharCounts((prev) => ({
      ...prev,
      [name]: charCount,
    }));

    if (charCount > MAX_CHAR_LIMIT) {
      actions.setToast({
        toastMessage: "Character limit reached!",
        toastState: true,
        variant: "error",
      });
    }
 
  };

 
  return (
    <>
      <Grid theme={theme} container columnSpacing={2} rowSpacing={3}>
        <Grid theme={theme} item sm={6} xs={12}>
          <TextAreaWrap className="chat-wrap">
            <Controller
              name="chat.welcome_chat"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Textarea
                    theme={theme}
                    fullWidth={true}
                    type={"text"}
                    placeholder="Type here.."
                    id={"WelcomeMessage"}
                    label="Welcome Message"
                    {...field}
                   onChange={(e) => {
                     
                      if (e.length <= MAX_CHAR_LIMIT) {
                        field.onChange(e);
                        handleChatCounter("welcome_chat",e.length );
                      } else {
                        handleChatCounter("welcome_chat", e.length);
                      }
                    }}
                    value={field.value ?? ""}
                />
              )}
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              textAlign="right"
              className="counter"
              children={charCounts.welcome_chat <= MAX_CHAR_LIMIT ?`${charCounts.welcome_chat}/${MAX_CHAR_LIMIT}`:`${MAX_CHAR_LIMIT}/${MAX_CHAR_LIMIT}` }
            />
          </TextAreaWrap>
        </Grid>
        <Grid theme={theme} item sm={6} xs={12}>
          <TextAreaWrap className="chat-wrap">
            <Controller
              name="chat.offline_message"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Textarea
                    theme={theme}
                    fullWidth={true}
                    type={"text"}
                    placeholder="Type here.."
                    id={"OfflineMessage"}
                    label="Offline Message"
                    {...field}
                    onChange={(e) => {
                      if (e.length <= MAX_CHAR_LIMIT) {
                        field.onChange(e);
                        handleChatCounter("offline_message",e.length );
                      } else {
                        handleChatCounter("offline_message", e.length);
                      }
                    }}
                    value={field.value ?? ""}
                />
              )}
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              textAlign="right"
              className="counter"
              children={charCounts.offline_message <= MAX_CHAR_LIMIT ?`${charCounts.offline_message}/${MAX_CHAR_LIMIT}`:`${MAX_CHAR_LIMIT}/${MAX_CHAR_LIMIT}`}
            />
          </TextAreaWrap>
        </Grid>
      </Grid>
    </>
  );
};
 
export default DealerChat;
