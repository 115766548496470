import { ActionTypes } from "./ActionTypes";
import { QueryKeys } from "./UseQueryKeys";
import {
  AssetButtonGroupOptions,
  DecisionStatusOptions,
} from "./AssetButtonGroupOptions";
import { ActionOptions } from "./ActionOptions";
import { ToastMessages } from "./ToastMessages";
import { ValidationMessages } from "./ValidationMessages";
import { DecisionStatus } from "./DecisionStatus";
import { ChartTypes } from "./ChartTypes";
import { AddressType } from "./AddressTypes";
import { Stipulation } from "./Stipulation";
import { ProgramState } from "./ProgramState";
import { DialogMessages } from "./DialogMessages";
import { feedbackOptionsValue } from "./Feedback";
import { PermissionTypes } from "./PermissionTypes";
import { TimeZone } from "./TimeZone";
import {
  CommissionType,
  AmortizationMethod,
  CommissionOverride,
} from "./ProgramCommission";
import {
  CalculationMethod,
  SubsidyAmortizationMethod,
  TaxAmortization,
  SubsidyOverride,
  PurchaseOptionType,
} from "./ProgramSubsidy";
import {
  StationeryAction,
  StationeryEvent,
  StationeryRole,
  StationeryType,
  StType,
} from "./StationeryType";
import { Module } from "./LenderModule";
import { FinancialInsuranceCategory } from "./FinancialInsuranceCategory";
import {UserRole} from "./UserRole"
export {
  ActionTypes,
  QueryKeys,
  AssetButtonGroupOptions,
  DecisionStatusOptions,
  ActionOptions,
  ToastMessages,
  ValidationMessages,
  ChartTypes,
  DecisionStatus,
  AddressType,
  Stipulation,
  ProgramState,
  DialogMessages,
  feedbackOptionsValue,
  CommissionType,
  AmortizationMethod,
  CommissionOverride,
  CalculationMethod,
  SubsidyAmortizationMethod,
  TaxAmortization,
  SubsidyOverride,
  PurchaseOptionType,
  StType,
  StationeryRole,
  StationeryType,
  StationeryAction,
  StationeryEvent,
  PermissionTypes,
  Module,
  FinancialInsuranceCategory,
  UserRole,
  TimeZone,
};

export enum OrderState {
  Draft = "Draft",
  New = "New",
  Cancel = "Cancel",
}
export enum Option_Categories {
  AlloyWheels = "Alloy Wheels",
  CommunicationTechnology = "Communication & Technology",
  E_Mobility = "E-Mobility",
  ExteriorDesign = "Exterior Design",
  InteriorDesign = "Interior Design",
  LightAlloyWheels = "Light Alloy Wheels",
  Luggage_Leisure = "Luggage & Leisure",
  Maintenance_Care = "Maintenance & Care",
  Music_Electronics = "Music & Electronics",
  Protection_Safety = "Protection & Safety",
}
export enum Vehicle_Type {
  "Pre-Owned" = 0,
  "Certified Pre-Owned" = 1,
  "New" = 2,
}
export enum Lisiting_Status {
  "Not Listed" = 0,
  "Listed" = 1,
}
export enum Daily_Update {
  "Disabled" = 0,
  "Enabled" = 1,
}
export enum Status {
  Inquiry = "Inquiry",
  AvailableInInventory = "Available In Inventory",
  InContarct = "In Contarct",
  Available = "Available",
  NotAvailable = "Not Available",
}

export enum ActionType {
  SET_INVENTORY_INIT_STATE = "GET INVENTORY DATA FROM SERVER",
}

export enum InstallationMode {
  PRE_INSTALLED = "Pre-Installed",
  OPTIONAL = "Optional",
}

export enum AddOnStatus {
  "Active" = "true",
  "Inactive" = "false",
}

export enum AvailableFinanceTypes {
  "Finance" = "Finance",
  "Lease" = "Lease",
  "Finance&Lease" = "Finance&Lease",
}
export enum ScheduleOptions {
  "PickUp" = "Pick up",
  "Delivery" = "Delivery",
  "Pickup&Deliver" = "Pickup&Delivery",
}
