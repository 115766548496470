import { ConfigurationAPI } from "services";
import { useQuery } from "react-query";
import { QueryKeys } from "Enums";

export const getAll_Countries_FromServer = () => {
  return ConfigurationAPI.get("/countries").then((res) => {
    return res.data;
  });
};
export const UseQuery_GetAll_Countries = () => {
  return useQuery(QueryKeys.GET_ALL_COUNTRIES, async () => {
    return getAll_Countries_FromServer().then((data) => {
      return data;
    });
  });
};
