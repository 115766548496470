import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Input,
  Typography,
  Select,
  Button,
  ConfirmationDialog,
} from "@sharedComponents";
import { INewTermsSetupPopUp } from "./AddNewTermSetup.type";
import { ITerms } from "Interface";
import { Controller, useForm } from "react-hook-form";
import { UseMutation_AddTerm, UseMutation_UpdateTerm } from "services";
import { logout } from "@ntpkunity/controls-ums";
import { ActionOptions, ValidationMessages, DialogMessages } from "Enums";
import { Grid, Icon, PersistentDrawer } from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";

const AddNewTermsSetup: FC<INewTermsSetupPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  termsSetupData,
  enabledPin,
}) => {
  const theme = useTheme();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<ITerms>({
    mode: "onChange",
    defaultValues: {
      terms_from: "1",
      terms_to: "999",
      is_active: true,
    },
  });
  const watchMonths = watch();
  const { mutate: AddCTerms } = UseMutation_AddTerm();
  const { mutate: UpdateTerms } = UseMutation_UpdateTerm(getValues("id"));
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  useEffect(() => {
    const subscription = watch(() => {
      let termFrom, termTo;
      termFrom = getValues("terms_from") >= "0" ? getValues("terms_from") : 0;
      termTo = getValues("terms_to") >= "0" ? getValues("terms_to") : 0;
    });
    return () => subscription.unsubscribe();
  }, [watchMonths]);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", termsSetupData?.id);
      setValue("terms_from", termsSetupData.terms_from);
      setValue("terms_to", termsSetupData.terms_to);
      setValue("is_active", termsSetupData.is_active);
    }
    if (actionType === ActionOptions.ADD) {
      reset();
    }
  }, [termsSetupData]);

  const submit = (data) => {
    if (actionType === ActionOptions.ADD) {
      AddCTerms(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
    }
    if (actionType === ActionOptions.EDIT) {
      UpdateTerms(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
    }
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  const onTermFromChange = (e) => {
    if (e < 999 && e >= 1) {
      setValue("terms_from", e);
    }
  };
  const onTermToChange = (e) => {
    if ((e <= 999 && e > 0) || e == "") {
      setValue("terms_to", e);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(submit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <form>
              <Grid
                theme={theme}
                container
                item
                columnSpacing={2}
                rowSpacing={3}
              >
                <Grid theme={theme} item xs={6}>
                  <Controller
                    name="terms_from"
                    control={control}
                    defaultValue={"1"}
                    rules={{
                      required: {
                        value: true,
                        message: ValidationMessages.TERM_FROM_REQUIRED,
                      },
                      validate: {
                        lessthenzero: (value) => {
                          if (parseInt(value || "0") <= 0) {
                            return ValidationMessages.TERM_LESS_THEN_ZERO;
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={"1"}
                        label={"Term From"}
                        fullWidth={true}
                        type="number"
                        max={999}
                        endAdornment={
                          <>
                            <span className="MuiInputAdornment-root">
                              <Typography
                                theme={theme}
                                variant="caption"
                                children="Months"
                              />
                            </span>
                          </>
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          onTermFromChange(e);
                        }}
                        {...field}
                        error={errors?.terms_from?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid theme={theme} item xs={6}>
                  <Controller
                    name="terms_to"
                    control={control}
                    defaultValue={"999"}
                    rules={{
                      required: {
                        value: true,
                        message: ValidationMessages.TERM_TO_REQUIRED,
                      },
                      validate: {
                        lessthenzero: (value) => {
                          if (parseInt(value || "0") <= 0) {
                            return ValidationMessages.TERM_LESS_THEN_ZERO;
                          } else if (
                            parseInt(value) < parseInt(getValues("terms_from"))
                          ) {
                            return ValidationMessages.TERM_TO_GREATER_THAN_OR_EQUAL_TO_TERM_FROM;
                          } else return true;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={"120"}
                        label={"Term To"}
                        fullWidth={true}
                        max={999}
                        type="number"
                        endAdornment={
                          <>
                            <span className="MuiInputAdornment-root">
                              <Typography
                                theme={theme}
                                variant="caption"
                                children="Months"
                              />
                            </span>
                          </>
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          onTermToChange(e);
                        }}
                        {...field}
                        error={errors?.terms_to?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid theme={theme} item xs={12}>
                  <Controller
                    name="is_active"
                    control={control}
                    defaultValue={true}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        theme={theme}
                        label={"Status"}
                        placeholder={"Select"}
                        items={[
                          { text: "Enabled", value: true },
                          { text: "Disabled", value: false },
                        ]}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </>
        }
        customFooter={
          <form onSubmit={handleSubmit(submit)}>
            <Button primary text="Save" fullWidth type="submit" />
          </form>
        }
      />
    </>
  );
};

export default AddNewTermsSetup;
