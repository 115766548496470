import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { ActionOptions, QueryKeys } from "Enums";
import {
  IChartCriteria,
  IAssetCondition,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
  IProductType,
} from "Interface";
import CommissionTable from "./commission-table";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { AddNewCommissionCharts } from "components";
import { useQueryClient } from "react-query";
const CommissionChart: FC = () => {
  const [commissionChartState, setCommissionChartState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Chart");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IChartCriteria>(undefined);

  const [state, dispatch] = useSetupsStore();
  const queryClient = useQueryClient();

  const financeType: Array<IFinanceType> = queryClient.getQueryData(
    QueryKeys.Get_All_FINANCE_TYPE
  );
  const customerType: Array<ICustomerType> = queryClient.getQueryData(
    QueryKeys.Get_All_CUSTOMER_TYPE
  );
  const assetType: Array<IAssetType> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_TYPE
  );
  const assetCategory: Array<IAssetCategory> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_CATEGORY
  );
  const productType: Array<IProductType> = queryClient.getQueryData(
    QueryKeys.Get_All_PRODUCT_TYPE
  );

  useEffect(() => {
    if (commissionChartState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [commissionChartState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Chart");
        setActionData(value);
        setCommissionChartState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onOptionsClick = (key, value) => {
    switch (key) {
      case ActionOptions.ADD_NEW_CHART:
        const criteriaData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Chart");
        setActionData(criteriaData);
        dispatch(setOpenDrawer(true));
        setCommissionChartState(true);
        return;
      case ActionOptions.RENAME_CHART:
        const editData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.RENAME_CHART);
        setActionTitle("Edit Chart");
        setActionData(editData);
        dispatch(setOpenDrawer(true));
        setCommissionChartState(true);
        return;
      case ActionOptions.ADD_CRITERIA:
        const data = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_CRITERIA);
        setActionTitle("Add Criteria");
        setActionData(data);
        dispatch(setOpenDrawer(true));
        setCommissionChartState(true);
        return;
      default:
        return;
    }
  };
  const onEditCriteria = (data) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Criteria");
    setActionData(data);
    dispatch(setOpenDrawer(true));
    setCommissionChartState(true);
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <CommissionTable
          onOptionsClick={(key, value) => onOptionsClick(key, value)}
          onEditCriteria={(data) => onEditCriteria(data)}
        />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Chart"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {commissionChartState && (
          <AddNewCommissionCharts
            openPopUp={commissionChartState}
            setPopUpState={setCommissionChartState}
            title={actionTitle}
            actionType={actionType}
            commissionChartData={actionData}
            financeType={financeType}
            customerType={customerType}
            assetCategory={assetCategory}
            assetType={assetType}
            producttype={productType}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default CommissionChart;
