import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { DataTableWrap } from "@sharedComponents";
import { Box, Button } from "@ntpkunity/controls";
import { AddNewDocumentType } from "../../components";
import { IDocumentTypes, IFinanceType } from "../../Interface";
import DocumentTypeTable from "./document-type-table";
import { IDocumentTypeProps } from "./document-type";
import { setOpenDrawer } from "../../Stores/SetupStore/Actions";
import { useSetupsStore } from "../../Stores/SetupStore/Store";
import { ActionOptions, QueryKeys } from "Enums";
import {
  UseQuery_getAllFinancedAmount,
  UseQuery_GetAllCustomerType,
  UseQuery_GetAllFinanceTypes,
} from "services";
import { useQueryClient } from "react-query";
import { IFinancedAmount, ICustomerType, IProductType } from "Interface";

const DocumentType = () => {
  const queryClient = useQueryClient();
  const [documentTypeState, setDocumentTypeState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Document Type"
  );
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IDocumentTypes>();
  const theme = useTheme();

  UseQuery_GetAllFinanceTypes();
  UseQuery_GetAllCustomerType();
  UseQuery_getAllFinancedAmount();

  const financeTypes: Array<IFinanceType> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const customerTypes: Array<ICustomerType> = queryClient.getQueryData(
    QueryKeys.Get_All_CUSTOMER_TYPE
  );
  const AllFinancedAmounts: Array<IFinancedAmount> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS
  );

  useEffect(() => {
    if (documentTypeState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [documentTypeState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Document Type");
        setActionData(value);
        setDocumentTypeState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };

  const onEdit = (formData: IDocumentTypes) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Document Type");
    setActionData(formData);
    setDocumentTypeState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <DocumentTypeTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Document Type"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>
        {documentTypeState && (
          <AddNewDocumentType
            openPopUp={documentTypeState}
            setPopUpState={setDocumentTypeState}
            title={actionTitle}
            actionType={actionType}
            documentTypeData={actionData}
            financeTypes={financeTypes}
            customerTypes={customerTypes}
            AllFinancedAmounts={AllFinancedAmounts}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default DocumentType;
