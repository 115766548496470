import { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionWrap,
  ConfirmationDialog,
} from "@sharedComponents";
import { useTheme } from "@mui/material";
import { Icon, Button, Box } from "@ntpkunity/controls";
import { MailingAddress, InvoiceAddress, OfficeAddress } from "components";
import { IGeneralProps } from "./general.type";
import { UseMutation_DeleteAddress } from "services";
import { AddressType, DialogMessages, QueryKeys } from "Enums";
import { IAddress } from "Interface";
import { useQueryClient } from "react-query";
import { Watch } from "@mui/icons-material";

const CompanyAddress: FC<IGeneralProps> = ({
  control,
  errors,
  setValue,
  getValues,
  resetMailingAddress,
  applyInvoiceValidation,
  setApplyInvoiceValidation,
  applyOfficeAddressValidation,
  setApplyOfficeAddressValidation,
  companyCountries,
  setFormValue,
  isAddressValidationActive,
  mailingAddressState,
  setMailingAddressState,
  invoiceingAddressState,
  setInvoiceingAddressState,
  officeAddressState,
  setOfficeAddressState,
}) => {
  const [concatMailingAddress, setConcatMailingAddress] = useState<string>();
  const [concatInvoiceAddress, setConcatInvoiceAddress] = useState<string>();
  const [concatOfficeAddress, setConcatOfficeAddress] = useState<string>();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [deleteAddress, setDeleteAddress] = useState<IAddress>();
  const { mutate: deleteCompanyAddress } = UseMutation_DeleteAddress();

  const theme = useTheme();
  const queryClient = useQueryClient();

  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  useEffect(() => {
    var address: IAddress = companyProfile?.addresses?.find(
      (x) => x.address_type == AddressType.MAILING
    );
    getValues("MailingAddress.address_line_1") == "" || address == null
      ? setConcatMailingAddress("")
      : setConcatMailingAddress(
          [
            address.address_line_1,
            address.address_line_2,
            address.city,
            address.state_name ? address.state_name : address?.field5,
            address?.zip_code,
          ]
            .filter(Boolean)
            .join(", ")
        );
    address = companyProfile?.addresses?.find(
      (x) => x.address_type == AddressType.INVOICE
    );
    getValues("InvoicingAddress.address_line_1") == "" || address == null
      ? setConcatInvoiceAddress("")
      : setConcatInvoiceAddress(
          [
            address.address_line_1,
            address.address_line_2,
            address.city,
            address.state_name ? address.state_name : address?.field5,
            address?.zip_code,
          ]
            .filter(Boolean)
            .join(", ")
        );
    address = companyProfile?.addresses?.find(
      (x) => x.address_type == AddressType.OFFICE
    );
    getValues("OfficeAddress.address_line_1") == "" || address == null
      ? setConcatOfficeAddress("")
      : setConcatOfficeAddress(
          [
            address.address_line_1,
            address.address_line_2,
            address.city,
            address.state_name ? address.state_name : address?.field5,
            address?.zip_code,
          ]
            .filter(Boolean)
            .join(", ")
        );
    address = companyProfile?.addresses?.find(
      (x) => x.address_type == AddressType.GARAGING
    );
  }, [companyProfile]);

  const showDeleteDrawer = (event, address) => {
    event.stopPropagation();
    if (address) {
      setDeleteAddress(address);
      setConfirmationPopupState(true);
    }
  };
  const onDeleteConfirm = () => {
    deleteCompanyAddress(deleteAddress?.id, {
      onSuccess: (data, variables, context) => {
        switch (deleteAddress?.address_type) {
          case AddressType.OFFICE:
            setApplyOfficeAddressValidation(false);
            break;
          case AddressType.INVOICE:
            setApplyInvoiceValidation(false);
            break;
        }
        setDeleteAddress(undefined);
      },
    });
  };

  return (
    <>
      <AccordionWrap className="u-accordion-group">
        <Accordion
          className="u-accordian-wrap u-accordion-group"
          theme={theme}
          variant1={"grouped"}
          items={[
            {
              content: (
                <>
                  {companyCountries?.length > 0 && (
                    <MailingAddress
                      control={control}
                      errors={errors}
                      watch={Watch}
                      getValues={getValues}
                      companyCountries={companyCountries}
                      setValue={setValue}
                      setConcatMailingAddress={setConcatMailingAddress}
                      setFormValue={setFormValue}
                      hasAddressTooltip={true}
                      isAddressValidationActive={isAddressValidationActive}
                    />
                  )}
                </>
              ),
              isExpanded: companyCountries?.length > 0 ? true : false,
              key: "string",
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <span>Mailing Address</span>
                  </Box>
                </>
              ),
              subtitle:
                companyCountries?.length > 0 ? concatMailingAddress : "",
              actionBtn: (
                <>
                  {companyCountries?.length > 0 && (
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={
                        <Icon
                          name="ResetIcon"
                          onClick={(event: any) => {
                            event.stopPropagation();
                            resetMailingAddress();
                            setConcatMailingAddress("");
                          }}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
            {
              content: (
                <>
                  {companyCountries?.length > 0 && (
                    <InvoiceAddress
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      watch={Watch}
                      applyInvoiceValidation={applyInvoiceValidation}
                      setApplyInvoiceValidation={setApplyInvoiceValidation}
                      companyCountries={companyCountries}
                      setConcatInvoiceAddress={setConcatInvoiceAddress}
                      setValue={setValue}
                      setFormValue={setFormValue}
                      hasAddressTooltip={true}
                      isAddressValidationActive={isAddressValidationActive}
                    />
                  )}
                </>
              ),
              isExpanded: false,
              key: "string",
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <span>Invoice Address</span>
                  </Box>
                </>
              ),
              subtitle:
                companyCountries?.length > 0 ? concatInvoiceAddress : "",
              actionBtn: (
                <>
                  {companyCountries?.length > 0 && (
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={
                        <Icon
                          name="DeleteIcon"
                          onClick={(event) => {
                            const address: IAddress =
                              companyProfile?.addresses?.find(
                                (x) => x.address_type == AddressType.INVOICE
                              );
                            showDeleteDrawer(event, address);
                          }}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
            {
              content: (
                <>
                  {companyCountries?.length > 0 && (
                    <OfficeAddress
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      watch={Watch}
                      companyCountries={companyCountries}
                      applyOfficeAddressValidation={
                        applyOfficeAddressValidation
                      }
                      setConcatOfficeAddress={setConcatOfficeAddress}
                      setValue={setValue}
                      setApplyOfficeAddressValidation={
                        setApplyOfficeAddressValidation
                      }
                      setFormValue={setFormValue}
                      hasAddressTooltip={true}
                      isAddressValidationActive={isAddressValidationActive}
                    />
                  )}
                </>
              ),
              isExpanded: false,
              key: "string",
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <span>Office Address</span>
                  </Box>
                </>
              ),
              subtitle: companyCountries?.length > 0 ? concatOfficeAddress : "",
              actionBtn: (
                <>
                  {companyCountries?.length > 0 && (
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={
                        <Icon
                          name="DeleteIcon"
                          onClick={(event) => {
                            const address: IAddress =
                              companyProfile?.addresses?.find(
                                (x) => x.address_type == AddressType.OFFICE
                              );
                            showDeleteDrawer(event, address);
                          }}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </AccordionWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};
export default CompanyAddress;
