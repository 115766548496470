import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setContractAllowedAnnumMileageInitialData } from "Stores/SetupStore/Actions";
import {
  IContractAllowedAnnumMileageFilter,
  IContractAllowedAnnumMileage,
} from "Interface";

export const getAllContractAllowedAnnumMileageFromServer = () => {
  return ConfigurationAPI.get("/contract-allowed-annum-mileage").then((res) => {
    return res.data;
  });
};

export const getAllContractAllowedAnnumMileageFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/contract-allowed-annum-mileage/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllContractAllowedAnnumMileage = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery(QueryKeys.GET_ALL_ANNUAL_MILEAGES, async () => {
    return getAllContractAllowedAnnumMileageFromServer().then((data) => {
      dispatch(setContractAllowedAnnumMileageInitialData(data));
      return data;
    });
  });
};

export const UseQuery_getAllContractAllowedAnnumMileage_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_CONTRACT_ALLOWED_ANNUM_MILEAGE_FILTER, columnFilters],
    async () => {
      return getAllContractAllowedAnnumMileageFilterFromServer(
        columnFilters
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddContractAllowedAnnumMileage = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IContractAllowedAnnumMileage>(
    (contractAllowedAnnumMileage) => {
      return ConfigurationAPI.post(
        "/contract-allowed-annum-mileage",
        contractAllowedAnnumMileage
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_CONTRACT_ALLOWED_ANNUM_MILEAGE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateContractAllowedAnnumMileage = (
  contractAllowedAnnumMileageId: any
): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IContractAllowedAnnumMileageFilter>(
    (contractAllowedAnnumMileage: any) => {
      return ConfigurationAPI.patch(
        `/contract-allowed-annum-mileage/${contractAllowedAnnumMileage.id}`,
        contractAllowedAnnumMileage
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_CONTRACT_ALLOWED_ANNUM_MILEAGE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteContractAllowedAnnumMileage = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IContractAllowedAnnumMileage>(
    (contract_allowed_annum_mileage_id) => {
      return ConfigurationAPI.delete(
        `/contract-allowed-annum-mileage/${contract_allowed_annum_mileage_id}`
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_CONTRACT_ALLOWED_ANNUM_MILEAGE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
