import { useTheme } from "@mui/material";
import { IInputProps, unityTheme, Input } from "@ntpkunity/controls";
import { FC } from "react";

const Component: FC<Partial<IInputProps>> = ({
  fullWidth,
  type,
  startAdornment,
  endAdornment,
  error,
  inputClass,
  formControlClass,
  inputWrapperSx,
  formControlSx,
  wrapperClass,
  InputSx,
  InputLableSx,
  errorSx,
  helperSx,
  label,
  onChange,
  variant,
  id,
  onBlur,
  helperText,
  placeholder,
  value,
  masking,
  inputMask,
  format,
  maskNumeric,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Input
      theme={theme}
      fullWidth={fullWidth}
      type={type}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      error={error}
      inputClass={inputClass}
      formControlClass={formControlClass}
      inputWrapperSx={inputWrapperSx}
      formControlSx={formControlSx}
      wrapperClass={wrapperClass}
      InputSx={InputSx}
      InputLableSx={InputLableSx}
      errorSx={errorSx}
      helperSx={helperSx}
      label={label}
      onChange={onChange}
      variant={variant}
      id={id}
      onBlur={onBlur}
      helperText={helperText}
      placeholder={placeholder}
      value={value}
      masking={masking}
      inputMask={inputMask}
      format={format}
      maskNumeric={maskNumeric}
      {...props}
    />
  );
};

export default Component;
