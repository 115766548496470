import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useSetupsStore, useStoreContext } from "../Stores/SetupStore/Store";
import { IChartCriteria, ICriteria } from "../Interface";

export const getAllSubsidyChartsFromServer = () => {
  return ConfigurationAPI.get("/subsidy").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllSubsidyCharts = () => {
  return useQuery(QueryKeys.GET_ALL_SUBSIDY_CHARTS, async () => {
    return getAllSubsidyChartsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAllSubsidyChartsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(`/subsidy/filter?`.concat(columnFilters)).then(
    (response) => {
      return response.data;
    }
  );
};

export const UseQuery_getAllSubsidyCharts_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER, columnFilters],
    async () => {
      return getAllSubsidyChartsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_DeleteSubsidyChart = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ICriteria>(
    (id) => {
      return ConfigurationAPI.delete(`/subsidy/${id}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewSubsidyCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IChartCriteria>>(
    (downPaymentChart) => {
      return ConfigurationAPI.post("/subsidy/criteria", downPaymentChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewSubsidyCriteria = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (subsidyChart) => {
      return ConfigurationAPI.post("/subsidy", subsidyChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_UpdateSubsidyCharts = (
  down_payment_id: number
): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (subsidyCharts: any) => {
      return ConfigurationAPI.patch(
        `/subsidy/${subsidyCharts.id}`,
        subsidyCharts
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
export const UseMutation_CheckCriteriaAttached: any = () => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      return ConfigurationAPI.get<any>(
        params ? `${"/chart/type"}?${params}` : "/chart/type"
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};
