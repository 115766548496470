export enum AddressType {
  MAILING = "Mailing",
  INVOICE = "Invoice",
  OFFICE = "Office",
  GARAGING = "Garaging",
  DEALER_ADDRESS = "DealerAddress",
  DRIVING_LICENSE = "DrivingLicense",
  BILLING = "Billing",
  InsuranceAddress = "InsuranceAddress",
  Residential = "Residential",
}
