import { ConfigurationAPI } from "services";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setAssetModelByMake } from "Stores/SetupStore/Actions";
import { IAssetModel } from "Interface";

export const getAllAssetModelsFromServer = () => {
  return ConfigurationAPI.get("/asset-model").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllAssetModels = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_MODELS, () => {
    return getAllAssetModelsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAssetModelsByMake = (make_id) => {
  return ConfigurationAPI.get(`/asset-model/by-make/${make_id}`).then((res) => {
    return res.data;
  });
};

export const UseQuery_GetModelByMake = (make_id, options: any = {}) => {
  const [, dispatch] = useSetupsStore();
  return useQuery(
    QueryKeys.GET_ASSET_MODEL_BY_MAKE,
    () => {
      return getAssetModelsByMake(make_id).then((data) => {
        dispatch(setAssetModelByMake(data, make_id));
        return data;
      });
    },
    options
  );
};

export const useMutation_AddNewAssetModel = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<Array<IAssetModel>>(
    (assetModel) => {
      return ConfigurationAPI.post("/asset-model", assetModel);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MODELS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useMutation_AddNewAssetModelMultiple: any = () => {
  const queryClient = useQueryClient();
  return useMutation<Array<IAssetModel>>(
    (assetModels) => {
      return ConfigurationAPI.post("/asset-model/add-multiple", assetModels);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MODELS);
      },
    }
  );
};

export const useMutation_UpdateAssetModel: any = (modelId) => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<Array<IAssetModel>>(
    (assetModel: any) => {
      return ConfigurationAPI.patch(
        `/asset-model/${assetModel.id}`,
        assetModel
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MODELS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_TRIMS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useMutation_DeleteAssetModel: any = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IAssetModel>>(
    (modelId: any) => {
      return ConfigurationAPI.delete(`/asset-model/${modelId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MODELS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_CheckDuplicateModelCode: any = () => {
  return useMutation<IAssetModel>(
    (model_code) => {
      return ConfigurationAPI.get(`/asset-model/model_code/${model_code}`);
    },
    {
      onSuccess: async (response: any) => {
        return response.data;
      },
    }
  );
};
