import { useContext, useEffect, useCallback, useState } from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
  useNavigate,
} from "react-router-dom";
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true) {
  const navigatorContext = useContext(NavigationContext);
  const navigator: any = navigatorContext?.navigator;
  useEffect(() => {
    localStorage.setItem("DirtyState", when.toString());
    if (!when) {
      return;
    }
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message, when = true, callback, cancelCallback) {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      //if ( window.confirm( message ) ) tx.retry();'
      window.dispatchEvent(new Event("formState"));
      const result = callback(when);
      const test = cancelCallback(tx);
      // if (result){
      //     tx.retry();
      // }
      // return tx
    },
    [message, when]
  );
  useBlocker(blocker, when);
}
