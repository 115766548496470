import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IAssetUsage } from "Interface";

export const getAllAssetUsagesFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/asset-usage/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_Asset_Usages_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_ASSET_USAGES_FILTER, columnFilters],
    async () => {
      return getAllAssetUsagesFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const getActiveAssetUsagesFromServer = () => {
  return ConfigurationAPI.get("/asset-usage/active").then((res) => {
    return res.data;
  });
};

export const getAllAssetUsagesFromServer = () => {
  return ConfigurationAPI.get("/asset-usages").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetActiveAssetUsages = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_USAGES_FILTER, async () => {
    return getActiveAssetUsagesFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_GetAllAssetUsages = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_USAGES, async () => {
    return getAllAssetUsagesFromServer().then((data) => {
      return data;
    });
  });
};

export const UseMutation_AddAssetUsage = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IAssetUsage>(
    (assetUsage) => {
      return ConfigurationAPI.post("/asset-usages", assetUsage);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_USAGES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_USAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateAssetUsage = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IAssetUsage>(
    (assetUsage: any) => {
      return ConfigurationAPI.patch(
        `/asset-usages/${assetUsage.id}`,
        assetUsage
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_USAGES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_USAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteAssetUsage = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IAssetUsage>(
    (assetUsageId) => {
      return ConfigurationAPI.delete(`/asset-usages/${assetUsageId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_USAGES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_USAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
export const useMutation_CheckAssetUsageCode: any = () => {
  return useMutation<IAssetUsage>(
    (asset_usage_code) => {
      return ConfigurationAPI.get(`/asset-usages/code/${asset_usage_code}`);
    },
    {
      onSuccess: async (response: any) => {
        return response.data;
      },
    }
  );
};
