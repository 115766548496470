import { ConfigurationAPI } from "services";
import { IAssetMake } from "Interface";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import Http from "shared/helper/http-api";

export const getAllAssetMakesFromServer = () => {
  return ConfigurationAPI.get("/asset-make").then((res) => {
    return res.data;
  });
};

export const getAllAssetMakesModelsTrimsFromServer = async () => {
  const res = await ConfigurationAPI.get("/asset-make/asset_hierarchy");
  return res.data;
};

export const UseQuery_GetAllAssetMakes = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_MAKES, async () => {
    return getAllAssetMakesFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_GetAllAssetMakesModelsTrims = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS, async () => {
    return getAllAssetMakesModelsTrimsFromServer();
  });
};

// export const getAllAssetMakesFromServer = () => {
//   const apiService = Http.createConnection();
//   return apiService.get(`${GET_ALL_ASSET_MAKES_MODELS_TRIMS}`).then((res) => {
//     return res;
//   });
// };
export const getAllAssetMakesFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(`asset-data/filter?`.concat(columnFilters)).then(
    (response) => {
      return response.data;
    }
  );
};
export const UseQuery_getAllAssets_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS, columnFilters],
    async () => {
      return getAllAssetMakesFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useMutation_AddNewAssetMake: any = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IAssetMake>(
    (AssetMake) => {
      return ConfigurationAPI.post("/asset-make", AssetMake);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES_LIST);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useMutation_AddNewAssetMakeMultiple: any = () => {
  const queryClient = useQueryClient();
  return useMutation<Array<IAssetMake>>(
    (assetMakes) => {
      return ConfigurationAPI.post("/asset-make/add-multiple", assetMakes);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES_LIST);
      },
    }
  );
};

export const useMutation_UpdateAssetMake: any = (makeId) => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IAssetMake>(
    (AssetMake: any) => {
      return ConfigurationAPI.patch(`/asset-make/${AssetMake.id}`, AssetMake);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MODELS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_TRIMS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES_LIST);
        setTimeout(
          () =>
            actions.setToast({
              toastMessage: ToastMessages.Record_Updated_Success,
              toastState: true,
            }),
          500
        );
      },
    }
  );
};

export const useMutation_DeleteAssetMake: any = () => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  ("");
  return useMutation<IAssetMake>(
    (makeId) => {
      return ConfigurationAPI.delete(`/asset-make/${makeId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_MAKES_LIST);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
