import { FC, useEffect, useState } from "react";
import {
  PersistentDrawer,
  Icon,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "@sharedComponents";
import { useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ICreditRating, IPreQualification, ISelectItemList } from "Interface";
import { logout } from "@ntpkunity/controls-ums";
import { INewPreQualificationPopUp } from "./AddNewPreQualification.type";
import {
  ActionOptions,
  ValidationMessages,
  DialogMessages,
  QueryKeys,
} from "Enums";
import {
  UseMutation_AddPreQualification,
  UseMutation_UpdatePreQualification,
  UseQuery_GetAllPreQualifications,
} from "services";
import { usePrompt } from "utilities/usePrompt";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";

const AddNewPreQualification: FC<INewPreQualificationPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  PreQualificationData,
  enabledPin,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm<IPreQualification>();
  const navigate = useNavigate();
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [creditScore, setCreditScorce] = useState<string>("");
  const [rangeSelection, setRangeSelection] =
    useState<Array<ISelectItemList>>();
  const [preQualifications, setPreQualifications] = useState<
    IPreQualification[]
  >([]);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const { data: preQualificationData } = UseQuery_GetAllPreQualifications();
  const { mutate: AddPreQualification } = UseMutation_AddPreQualification();
  const { mutate: UpdatePreQualification } = UseMutation_UpdatePreQualification(
    getValues("id")
  );
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [navigationState, setNavigationState] = useState<any>();
  const currencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const creditRatings: Array<ICreditRating> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CREDIT_RATINGS
  );

  useEffect(() => {
    if (preQualificationData) {
      setPreQualifications(preQualificationData);
    }
  }, [preQualificationData]);

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === companyProfile?.default_currency
    );

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", PreQualificationData?.id);
    }
    setValue("credit_score_range", PreQualificationData?.credit_score_range);
    setCreditScorce(PreQualificationData?.credit_score_range || "");
    setValue("financed_amount", PreQualificationData?.financed_amount);
    setValue(
      "is_active",
      PreQualificationData ? PreQualificationData.is_active : true
    );
  }, [PreQualificationData]);

  useEffect(() => {
    const creditScoreArray = creditRatings
      ?.filter((x) => x.is_active)
      ?.map((score) => ({
        value: `${score.score_from} - ${score.score_to}`,
        text: `${score.score_from} - ${score.score_to}`,
      }));

    if (creditScore) {
      const selectedCreditScore = creditRatings?.find((x) => {
        const range = `${x.score_from} - ${x.score_to}`;
        return range === creditScore && !x.is_active;
      });
      if (selectedCreditScore) {
        creditScoreArray.push({
          value: `${selectedCreditScore.score_from} - ${selectedCreditScore.score_to}`,
          text: `${selectedCreditScore.score_from} - ${selectedCreditScore.score_to}`,
        });
      }
    }

    const usedRanges = preQualifications?.map((pq) => pq.credit_score_range);
    const filteredRangeSelection = creditScoreArray?.filter(
      (item) =>
        !usedRanges.includes(item.value) ||
        item.value === PreQualificationData?.credit_score_range
    );

    setRangeSelection(filteredRangeSelection);
  }, [creditRatings, creditScore, preQualifications]);

  const onSubmit = (data: IPreQualification) => {
    if (data.id && data.id > 0) {
      UpdatePreQualification(data, {
        onSuccess: (response) => {
          if (enabledPin) {
            reset({
              credit_score_range: data.credit_score_range,
              is_active: true,
            });
          } else {
            reset();
            setCreditScorce("");
          }
          setPreQualifications((prev) => [...prev, data]);
          onClose();
        },
        onError: (error) => {
          handleError(error);
        },
      });
    } else {
      AddPreQualification(data, {
        onSuccess: (response) => {
          if (enabledPin) {
            reset({
              credit_score_range: data.credit_score_range,
              is_active: true,
            });
          } else {
            reset();
            setCreditScorce("");
          }
          onClose();
        },
        onError: (error) => {
          handleError(error);
        },
      });
    }
  };

  const handleError = (error) => {
    if (
      error?.response?.data?.detail?.message ===
      "This credit score range and financed amount already exists"
    ) {
      setError("credit_score_range", { message: "Range should be unique" });
      setError("financed_amount", {
        message: "Financed amount should be unique",
      });
      return;
    } else {
      onClose();
      actions.setToast({
        toastMessage: error?.response?.data?.detail?.message,
        toastState: true,
        variant: "error",
      });
    }
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    isDirty,
    (data) => {
      setLeavePageState(data);
      localStorage.setItem("DirtyState", data);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  const onCreditScoreSelection = (e) => {
    const selectedValue = e.target.value;
    setCreditScorce(selectedValue);
    setValue("credit_score_range", selectedValue);
  };

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="credit_score_range"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.CREDIT_SCORE_RANGE,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="credit_score_range"
                  theme={theme}
                  label={"Credit Score Range"}
                  value={creditScore}
                  items={rangeSelection}
                  onChange={(e) => {
                    onChange(e.target.value);
                    onCreditScoreSelection(e);
                  }}
                  selectError={errors?.credit_score_range?.message}
                />
              )}
            />
            <Controller
              name="financed_amount"
              control={control}
              rules={{
                required: ValidationMessages.FINANCED_AMOUNT_IS_REQUIRED,
                pattern: {
                  value: /^\d+(\.\d{1,2})?$/,
                  message: ValidationMessages.FINANCED_AMOUNT_IS_REQUIRED,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"0.00"}
                  label={"Financed Amount"}
                  type="text"
                  startAdornment={
                    <span className="adornment-text">{defaultCurrency}</span>
                  }
                  id="financed_amount"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  masking={true}
                  maskNumeric={true}
                  maskDecimalScale={2}
                  error={errors?.financed_amount?.message}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewPreQualification;
