import { StoreActionType } from "Types";
import { IAssetMake } from "Interface";
import { ActionTypes } from "Enums";

export const AssetMakeReducer = (
  state: Array<IAssetMake> = [],
  action: StoreActionType
): Array<IAssetMake> => {
  switch (action.type) {
    case ActionTypes.ADD_ASSET_MAKE:
      return [...state, action.payload];

    case ActionTypes.SET_ASSET_MAKE_INIT_STATE:
      return [...action.payload];

    case ActionTypes.UPDATE_ASSET_MAKE:
      const indexToUpdate = state.findIndex(
        (assetMake) => assetMake.id == action.payload?.make_id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
