import { useTheme } from "@mui/material";
import { ISelect, unityTheme, Select } from "@ntpkunity/controls";
import { FC } from "react";

const Component: FC<Partial<ISelect>> = ({
  onChange,
  items,
  label,
  helperText,
  value,
  sxProps,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Select
      theme={theme}
      items={items}
      label={label}
      helperText={helperText}
      value={value}
      sxProps={sxProps}
      onChange={onChange}
      {...props}
    />
  );
};

export default Component;
