import {
  IAddOns,
  IInventory,
  IPaginatedResponse,
  IToast,
  IPaginatedResponse_AddOns,
  IDealerFee,
  IFinancialInsurance,
  IPaginatedResponse_OemOptions,
  IOemOption,
} from "Interface";
import { Theme } from "@mui/system";

export default interface IActions {
  payload: unknown;
  type: string;
}

export interface IThemeConfig {
  theme: Theme;
}

export interface IBrowserStorage {
  localStorage: any;
  sessionStorage: any;
}

// TODO: add relevant types
export type StoreContextType = {
  actions: {
    setTheme: (data: "default" | "dark") => unknown;
    setSessionStorage: (data: string) => void;
    setLocalStorage: (data: string) => void;
    setAssetInventoryInitialData: (data: IPaginatedResponse) => void;
    updateInventoryRow: (data: IInventory) => void;
    setToast: (data: IToast) => void;
    setOptionsInitialData: (data: IPaginatedResponse_AddOns) => void;
    updateOptionsRow: (data: IAddOns) => void;
    deleteOptionsRow: (data: IAddOns) => void;
    addOptionsRow: (data: IAddOns) => void;
    setOemOptionsInitialData: (data: IPaginatedResponse_OemOptions) => void;
    updateOemOptionsRow: (data: IOemOption) => void;
    deleteOemOptionsRow: (data: IOemOption) => void;
    addOemOptionsRow: (data: IOemOption) => void;
    setDealerFeeInitialData: (data: IDealerFee[]) => void;
    deleteDealerFeeRow: (data: IDealerFee) => void;
    updateDealerFeeRow: (data: IDealerFee) => void;
    addDealerFeeRow: (data: IDealerFee) => void;
    setFinancialInsuranceProductData: (data: any) => void;
    setFinancialInsuranceInitialData: (data: IFinancialInsurance[]) => void;
    addFinancialInsuranceRow: (data: IFinancialInsurance) => void;
    updateFinancialInsuranceRow: (data: IFinancialInsurance) => void;
    deleteFinancialInsuranceRow: (data: IFinancialInsurance) => void;
  };
  states: {
    theme: IThemeConfig | null;
    localStorage: any;
    sessionStorage: any;
    inventoryData: IPaginatedResponse;
    toastData: IToast;
    optionData: IPaginatedResponse_AddOns;
    oemoptionData: IPaginatedResponse_OemOptions;
    dealerFeeData: IDealerFee[];
    financialInsuranceProductData: any;
    financialInsuranceInitialData: IFinancialInsurance[];
  };
};

// TODO: add relevant default values
export const defaultStoreValues = {
  states: {
    theme: null,
    sessionStorage: null,
    localStorage: null,
    inventoryData: { pageNum: 0, pageSize: 0, result: [], totalResults: 0 },
    optionData: { pageNum: 0, pageSize: 0, result: [], totalResults: 0 },
    oemoptionData: { pageNum: 0, pageSize: 0, result: [], totalResults: 0 },
    toastData: {
      toastMessage: "",
      toastState: false,
      toastLength: undefined,
      variant: undefined,
      showIcon: false,
    },
    dealerFeeData: [],
    financialInsuranceInitialData: [],
    financialInsuranceProductData: [],
  },
  actions: {
    setTheme: (): void => undefined,
    setSessionStorage: (): void => undefined,
    setLocalStorage: (): void => undefined,
    setAssetInventoryInitialData: (): void => undefined,
    updateInventoryRow: (): void => undefined,
    setToast: (): void => undefined,
    setOptionsInitialData: (): void => undefined,
    updateOptionsRow: (): void => undefined,
    deleteOptionsRow: (): void => undefined,
    addOptionsRow: (): void => undefined,
    setOemOptionsInitialData: (): void => undefined,
    updateOemOptionsRow: (): void => undefined,
    deleteOemOptionsRow: (): void => undefined,
    addOemOptionsRow: (): void => undefined,
    setDealerFeeInitialData: (): void => undefined,
    deleteDealerFeeRow: (): void => undefined,
    updateDealerFeeRow: (): void => undefined,
    addDealerFeeRow: (): void => undefined,
    setFinancialInsuranceProductData: (): void => undefined,
    setFinancialInsuranceInitialData: (): void => undefined,
    addFinancialInsuranceRow: (): void => undefined,
    updateFinancialInsuranceRow: (): void => undefined,
    deleteFinancialInsuranceRow: (): void => undefined,
  },
};
