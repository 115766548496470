import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useSetupsStore, useStoreContext } from "../Stores/SetupStore/Store";
import { setInterestChartByCharId } from "../Stores/SetupStore/Actions";
import { IInterestCharts, IInterestChartCriteria, ICharts } from "../Interface";

export const getAllInterestChartsFromServer = () => {
  return ConfigurationAPI.get("/interest-chart").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllInterestCharts = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery(QueryKeys.GET_ALL_INTEREST_CHARTS, async () => {
    return getAllInterestChartsFromServer().then((data) => {
      return data;
    });
  });
};

export const useMutation_AddNewInterestCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IInterestChartCriteria>>(
    (interestChart) => {
      return ConfigurationAPI.post("/interest-chart/criteria", interestChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewInterestCriteria = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IInterestCharts>>(
    (interestChart) => {
      return ConfigurationAPI.post("/interest-chart", interestChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_UpdateInterestCharts = (
  interest_chart_id: number
): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IInterestCharts>>(
    (interestCharts: any) => {
      return ConfigurationAPI.patch(
        `/interest-chart/${interestCharts.id}`,
        interestCharts
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_DeleteInterestChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IInterestCharts>>(
    (interestChartId: any) => {
      return ConfigurationAPI.delete(`/interest-chart/${interestChartId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const getInterestChartsByChartId = (chart_id) => {
  return ConfigurationAPI.get(
    `/interest-chart-criteria/by-chart/${chart_id}`
  ).then((res) => {
    return res.data;
  });
};

export const UseQuery_getInterestChartByChartId = (
  chart_id,
  options: any = {}
) => {
  const [, dispatch] = useSetupsStore();

  return useQuery(
    QueryKeys.GET_ALL_INTEREST_CHARTS_BY_CHART,
    () => {
      return getInterestChartsByChartId(chart_id).then((data) => {
        dispatch(setInterestChartByCharId(data, chart_id));
        return data;
      });
    },
    options
  );
};

export const UseMutation_CheckDuplicateInterestName: any = () => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      return ConfigurationAPI.get<any>(
        params
          ? `${"/interest-charts/name"}?${params}`
          : "/interest-charts/name"
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};
