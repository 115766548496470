import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setDecisionStatusInitialData } from "Stores/SetupStore/Actions";
import { IDecisionStatus } from "Interface";

export const getAll_DecisionsStatuses_FromServer = () => {
  return ConfigurationAPI.get("/decision_status").then((res) => {
    return res.data;
  });
};

export const getAllDecisionStatusFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/decision_statuses/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_GetAll_DecisionStatuses = () => {
  const [, dispatch] = useSetupsStore();
  return useQuery(QueryKeys.GET_ALL_DECISION_STATUS, async () => {
    return getAll_DecisionsStatuses_FromServer().then((data) => {
      dispatch(setDecisionStatusInitialData(data));
      return data;
    });
  });
};
export const useGetAllDecisionStatus = (): any => {
  const queryClient = useQueryClient();
  const [, dispatch] = useSetupsStore();
  return useMutation<IDecisionStatus>(
    (body: any) => {
      return ConfigurationAPI.get("/decision_status");
    },
    {
      onSuccess(_response: any) {},
    }
  );
};

export const UseQuery_getAllDecisionStatus_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_DECISION_STATUS_FILTER, columnFilters],
    async () => {
      return getAllDecisionStatusFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddDecisionStatus = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IDecisionStatus>(
    (decision_data) => {
      return ConfigurationAPI.post("/decision_status", decision_data);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DECISION_STATUS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateDecisionStatus = (
  DecisionStatusId: number
): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IDecisionStatus>(
    (decision_status: any) => {
      return ConfigurationAPI.patch(
        `/decision_status/${decision_status.id}`,
        decision_status
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DECISION_STATUS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteDecisionStatus = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IDecisionStatus>(
    (DecisionStatusId) => {
      return ConfigurationAPI.delete(`/decision_status/${DecisionStatusId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DECISION_STATUS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
