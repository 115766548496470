import { FC, useState } from "react";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ChevronDown from "../../shared/assets/images/chevron-down";
import {
  Icon,
  DataTable,
  Button,
  Checkbox,
  Box,
  Input,
  ImageViewer,
} from "@ntpkunity/controls";
import { UseQuery_getAllAssets_filter } from "services";
import { DataTableWrap, PaginationWrap } from "@sharedComponents";
import AssetMakeTable from "./asset-make-table";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IAssetDataFilter,
} from "Interface";
interface IAssetCatalogueTableProps {
  showCheckBox?: boolean;
  onEditAssetMake?: (data: IAssetMake) => unknown;
  onEditAssetModel?: (data: IAssetModel) => unknown;
  onEditAssetTrim?: (data: IAssetTrim) => unknown;
  onAddAssetModel?: (data: IAssetMake) => unknown;
  onAddAssetTrim?: (data: IAssetMake) => unknown;
  onAddAssetModelTrim?: (data: IAssetModel) => unknown;
}

const AssetCatalogueTable: FC<IAssetCatalogueTableProps> = ({
  showCheckBox,
  onEditAssetMake,
  onEditAssetModel,
  onEditAssetTrim,
  onAddAssetModel,
  onAddAssetTrim,
  onAddAssetModelTrim,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [makeModelTrimFilter, setMakeModelTrimFilter] = useState("");
  const [codeFilter, setCodeFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const theme = useTheme();
  const { data } = UseQuery_getAllAssets_filter(columnFilters);
  const assetMakes: IAssetDataFilter = data;
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (
      makeModelTrimFilter.trimStart() != null &&
      makeModelTrimFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&make_child=${makeModelTrimFilter}`);
    }
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&code=${codeFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow>
                {showCheckBox ? (
                  <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox label="" theme={theme} />
                  </TableCell>
                ) : null}
                <TableCell>Make/Model/Trim</TableCell>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>

              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="makeModelTrim"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setMakeModelTrimFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={makeModelTrimFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>

                <TableCell className="img-cell" />

                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="description"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setDescriptionFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={descriptionFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="code"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setCodeFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={codeFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="status"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setStatusFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={statusFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>

                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {assetMakes?.result?.map((item, index) => (
                <AssetMakeTable
                  onEditAssetMake={(data) => onEditAssetMake(data)}
                  onEditAssetModel={(data) => onEditAssetModel(data)}
                  onEditAssetTrim={(data) => onEditAssetTrim(data)}
                  key={item.id}
                  makeIndex={index}
                  assetMake={item}
                  showCheckBox={showCheckBox}
                  onAddAssetModel={(data) => onAddAssetModel(data)}
                  onAddAssetTrim={(data) => onAddAssetTrim(data)}
                  onAddAssetModelTrim={(data) => onAddAssetModelTrim(data)}
                  handleImage={handleImage}
                />
              ))}
            </>
          }
        />
        <PaginationWrap>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={assetMakes ? assetMakes?.total_results : -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown,
              MenuProps: { disablePortal: true },
            }}
          />
        </PaginationWrap>
      </DataTableWrap>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={images}
        isLoading={false}
        visible={visibility}
        overlayCheck={true}
        onClose={handleClose}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};

export default AssetCatalogueTable;
