import { useTheme } from "@mui/material";
import { Input, Select } from "@ntpkunity/controls";
import { FC, useState } from "react";
import { validation } from "shared/helper/methods";
import { Controller } from "react-hook-form";
import { UseQuery_GetAllFinanceTypes } from "services";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { IFinanceTypeModel } from "Interface";

export const CreditTier: FC<{
  form: any;
  index: number;
  popUpOpenState: string;
  disabled: boolean;
}> = ({ form, index, disabled }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const theme = useTheme();
  const queryClient = useQueryClient();
  UseQuery_GetAllFinanceTypes();
  const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  return (
    <>
      <Controller
        name={`credit_tier_margins[${index}].tier_name`}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            theme={theme}
            label={"Tier Name"}
            placeholder={"Select"}
            disabled={disabled}
            items={[
              { text: "PRPL", value: "PRPL" },
              { text: "PROG", value: "PROG" },
            ]}
          />
        )}
      />
      <Controller
        name={`credit_tier_margins[${index}].finance_type`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Finance Type"}
            placeholder={"Select"}
            items={available_finance?.map((item) => {
              return { text: item.name, value: item.name };
            })}
            disabled={disabled}
            value={value ?? []}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`credit_tier_margins[${index}].margin_rate`}
        control={control}
        rules={validation("Margin Rate", true)}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(val) => {
              const formattedValue = val
                ?.replace(/[^0-9.]/g, "")
                ?.replace(/(\..*)\./g, "$1")
                ?.replace(/^(\d*\.{0,1}\d{0,6}).*$/, "$1");

              setValue(
                `credit_tier_margins[${index}].margin_rate`,
                formattedValue ? parseFloat(formattedValue) : undefined
              );
            }}
            theme={theme}
            fullWidth
            label={"Margin Rate"}
            type="text"
            endAdornment="%"
            error={errors?.credit_tier_margins?.[index]?.margin_rate?.message}
            masking
            disabled={disabled}
            maskDecimalScale={6}
            maskNumeric
          />
        )}
      />
    </>
  );
};
