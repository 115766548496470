import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme, styled } from "@mui/material";
import {
  Box,
  IBoxProps,
  Button,
  PersistentDrawer,
  Icon,
} from "@ntpkunity/controls";

import {
  useDeleteCustomLink,
  useGetProfilebyDealerCode,
  useSaveDealerCustomLink,
} from "services/dealer-configurations.service";
import { useStoreContext } from "Stores/SetupStore/Store";
import { DialogMessages, ToastMessages, ActionOptions } from "Enums";
import { ConfirmationDialog, DataTableWrap } from "@sharedComponents";

import CustomLinkDrawer from "./custom-link-drawer";
import CustomLinksTable from "./custom-links-table";
import { IDealerCustomLink } from "Interface";

const CustomLinks: FC<{
  profileData: any;
  setProfileData: (v: any) => void;
  onPopupStateChange: (popUpState: any) => unknown;
}> = ({ onPopupStateChange, profileData, setProfileData }) => {
  const theme = useTheme();
  const form = useForm<IDealerCustomLink>();
  const { actions } = useStoreContext();
  // save custom link
  const { mutate: saveCustomLink, isLoading: saveLoading } =
    useSaveDealerCustomLink();
  const { mutate: deleteCustomLink, isLoading: deleteLoading } =
    useDeleteCustomLink();
  const { mutate: getDealerPrfile, isLoading: profileLoading } =
    useGetProfilebyDealerCode();

  const [popUpState, setPopUpState] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [customLinks, setCustomLinks] = useState<IDealerCustomLink[]>([]);
  const [deletedId, setDeletedId] = useState<any>(null);

  useEffect(() => {
    onPopupStateChange(popUpState);
    if (!popUpState) setIsEditMode(false);
  }, [popUpState]);

  useEffect(() => {
    if (profileData) {
      setCustomLinks(profileData?.custom_menu_links);
    }
  }, [profileData]);

  const handleshow = () => {
    form.reset({} as IDealerCustomLink);
    setPopUpState(true);
  };

  const handleEditLink = (values: IDealerCustomLink, isDirect = false) => {
    if (isDirect) {
      onSubmit(values, ActionOptions.EDIT);
    } else {
      form.reset({ ...values } as IDealerCustomLink);
      setIsEditMode(true);
      setPopUpState(true);
    }
  };
  const deleteLink = () => {
    if (deletedId) {
      onDelete(deletedId);
    }
    setConfirmationPopupState(false);
  };


  const handleSubmit = (action_type: string | null) => {
    return (formValues: IDealerCustomLink) => {
      onSubmit(formValues, action_type);
    };
  };

  const onSubmit = (formValues: IDealerCustomLink, action_type: string | null): void => {
    console.log("Action  Type ")
    const customLink = {
      ...formValues,
      dealer_code: profileData?.dealer_code,
      menu_order: 1,
    };
    if (!customLink.id) {
      customLink.dealer_id = profileData?.id;
    }
    delete customLink["is_deleted"];
    saveCustomLink(customLink, {
      onSuccess() {
        form.reset({} as IDealerCustomLink);
        if(action_type ==  ActionOptions.ADD){
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true,
          });
        }
        else {
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
        }
        getLastestDealerProfile();
      },
      onError: async (error: any) => {
        if (error?.response?.data?.detail?.code != 400) {
          actions.setToast({
            toastMessage: error?.error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        }
      },
    });
    setPopUpState(false);
  };

  const onDelete = (id: any): void => {
    deleteCustomLink(
      {
        id: deletedId,
        dealer_code: profileData?.dealer_code,
      },
      {
        onSuccess() {
          form.reset({} as IDealerCustomLink);
          setDeletedId(null);
          actions.setToast({
            toastMessage: ToastMessages.Record_Deleted_Success,
            toastState: true,
          });
          getLastestDealerProfile();
        },
        onError: async (error: any) => {
          if (error?.response?.data?.detail?.code != 400) {
            actions.setToast({
              toastMessage: error?.error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      }
    );
    setPopUpState(false);
  };
  const getLastestDealerProfile = (): void => {
    getDealerPrfile(
      {
        dealer_code: profileData?.dealer_code,
      },
      {
        onSuccess(data: any) {
          if (data) {
            // setCustomLinks(data?.custom_menu_links)
            setProfileData(data);
          }
        },
        onError: async (error: any) => {
          if (error?.response?.data?.detail?.code != 400) {
            actions.setToast({
              toastMessage: error?.error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      }
    );
    setPopUpState(false);
  };

  const handleDeleteLink = (id: any) => {
    setConfirmationPopupState(true);
    setDeletedId(id);
  };

  return (
    <>
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={deleteLink}
        onCancel={() => {}}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.confirmationTitle}
        confirmationText={DialogMessages.confirmationText}
        primaryBtnText={DialogMessages.deleteBtnText}
        icon={<Icon name="DialogDeleteIcon" />}
      />
      <DataTableWrap theme={theme} className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <CustomLinksTable
          customLinks={customLinks}
          handleEditLink={handleEditLink}
          handleDeleteLink={handleDeleteLink}
        />
        <Button
          theme={theme}
          secondary
          fullWidth
          text={"Add New Link"}
          onClick={handleshow}
        />
      </DataTableWrap>
      <PersistentDrawer
        title={isEditMode ? "Edit Custom Link" : "Add New Custom Link"}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        enabledPin={false}
        theme={theme}
        children={
          <>
            <CustomLinkDrawer form={form} />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save Link"
            fullWidth
            onClick={() => form.handleSubmit(handleSubmit(ActionOptions.ADD))()}
          />
        }
      />
    </>
  );
};

export default CustomLinks;
