import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import {
  AssetSetup,
  Charts,
  Programs,
  ProgramList,
  InventoryManagement,
  DealerOptions,
  DealerConfigurations,
  FinancialInsurance,
  DealerManagement,
  OrderSetup,
  GeneralSetup,
  ManageUsers,
  UserProfile,
  Feedback,
  ProgramSetup,
  CompanyLenderList,
  DealerLenderList,
} from "pages";
import { APP_ROUTES } from "shared/config/links";
import { indexPermissions } from "@ntpkunity/controls-ums/indexPermissions";
import { Validate } from "@ntpkunity/controls-ums";
import DefaultRoute from "../../pages/default-routing";
import { ValidateDealerUser } from "../../components";

const ConfigurationRoutes: FC = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.COMPANY_PROFILE} element={
        <ValidateDealerUser>
        <DefaultRoute />
        </ValidateDealerUser>
        } />
      <Route
        path={APP_ROUTES.Asset_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.ASSET_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <AssetSetup />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.General_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.GENERAL_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <GeneralSetup />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Order_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.ORDER_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <OrderSetup />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Charts}
        element={
          <Validate
            permission={indexPermissions.ROUTING.CHARTS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <Charts />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Program}
        element={
          <Validate
            permission={indexPermissions.ROUTING.PROGRAM}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <Programs />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Program + "/:id"}
        element={
          <Validate
            permission={indexPermissions.ROUTING.PROGRAM}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <Programs />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Programs}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.PROGRAMS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <ProgramList />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Inventory_Management}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.INVENTORY_MANAGEMENT}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <InventoryManagement />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Dealer_Options}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.DEALER_OPTIONS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <DealerOptions />
            </ValidateDealerUser>
          </Validate>
        }
      />
      {/* Hellooooo */}
      <Route
        path={`${APP_ROUTES.Dealer_Configurations}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.DEALER_CONFIGURATIONS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <DealerConfigurations />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Financial_Insurance}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.FINANCIAL_INSURANCE}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <FinancialInsurance />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Dealer_Management}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.DEALERSHIP_MANAGEMENT}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <DealerManagement />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Manage_Users}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.MANAGE_USERS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <ManageUsers />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Program_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.ORDER_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ValidateDealerUser>
            <ProgramSetup />
            </ValidateDealerUser>
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Company_Lender_List}`}
        element={
        <ValidateDealerUser>
        <CompanyLenderList />
        </ValidateDealerUser>
      }
      />
      <Route
        path={`${APP_ROUTES.Dealer_Lender_List}`}
        element={
        <ValidateDealerUser>
        <DealerLenderList />
        </ValidateDealerUser>
      }
      />
      <Route path={`${APP_ROUTES.User_Profile}`} element={
        <ValidateDealerUser>
        <UserProfile />
        </ValidateDealerUser>
        } />
      <Route path={`${APP_ROUTES.Feedback}`} element={
        <ValidateDealerUser>
        <Feedback />
        </ValidateDealerUser>
        } />
    </Routes>
  );
};

export default ConfigurationRoutes;
