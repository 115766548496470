import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button, Radio, Select } from "@ntpkunity/controls";
import { DataTableWrap, SortDropdownWrap } from "@sharedComponents";
import { AddNewInterestCharts } from "components";
import { ActionOptions, QueryKeys } from "Enums";
import {
  IAssetCondition,
  IBusinessUnits,
  IOtherCosts,
  ICreditRating,
  IFinancedAmount,
  IInterestChartCriteria,
  IInterestCharts,
  ITerms,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
  IProductType,
  ITradingAge,
} from "Interface";
import ChartTable from "./charts-table";
import {
  UseQuery_GetAllAssetConditions,
  UseQuery_getAllBusinessUnits,
  UseQuery_getAllOtherCosts,
  UseQuery_getAllFinancedAmount,
  UseQuery_getAllCreditRatings,
  UseQuery_GetAllTerms,
} from "services";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";

const InterestChart: FC = () => {
  UseQuery_GetAllAssetConditions();
  UseQuery_getAllBusinessUnits();
  UseQuery_getAllOtherCosts();
  UseQuery_getAllFinancedAmount();
  UseQuery_GetAllTerms();
  UseQuery_getAllCreditRatings();

  const [interestChartState, setInterestChartState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Margin Rate");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] =
    useState<IInterestChartCriteria>(undefined);

  const [state, dispatch] = useSetupsStore();
  const queryClient = useQueryClient();
  const AllAssetConditions: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );
  const AllBusinessUnits: Array<IBusinessUnits> = queryClient.getQueryData(
    QueryKeys.Get_All_Business_Units
  );
  const AllOtherCosts: Array<IOtherCosts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_OTHER_COSTS
  );
  const AllFinancedAmounts: Array<IFinancedAmount> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS
  );
  const AllTerms: Array<ITerms> = queryClient.getQueryData(
    QueryKeys.GET_ALL_TERMS
  );
  const AllCreditRatings: Array<ICreditRating> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CREDIT_RATINGS
  );
  const financeType: Array<IFinanceType> = queryClient.getQueryData(
    QueryKeys.Get_All_FINANCE_TYPE
  );
  const customerType: Array<ICustomerType> = queryClient.getQueryData(
    QueryKeys.Get_All_CUSTOMER_TYPE
  );
  const assetType: Array<IAssetType> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_TYPE
  );
  const assetCategory: Array<IAssetCategory> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_CATEGORY
  );
  const productType: Array<IProductType> = queryClient.getQueryData(
    QueryKeys.Get_All_PRODUCT_TYPE
  );

  const tradingAge: Array<ITradingAge> = queryClient.getQueryData(
    QueryKeys.GET_ALL_TRADING_AGE
  );
  useEffect(() => {
    if (interestChartState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [interestChartState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Margin Rate");
        setActionData(value);
        setInterestChartState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onOptionsClick = (key, value) => {
    switch (key) {
      case ActionOptions.ADD_NEW_CHART:
        const criteriaData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Criteria");
        setActionData(criteriaData);
        dispatch(setOpenDrawer(true));
        setInterestChartState(true);
        return;
      case ActionOptions.RENAME_CHART:
        const editData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.RENAME_CHART);
        setActionTitle("Edit Chart");
        setActionData(editData);
        dispatch(setOpenDrawer(true));
        setInterestChartState(true);
        return;
      case ActionOptions.ADD_CRITERIA:
        const data = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_CRITERIA);
        setActionTitle("Add Criteria");
        setActionData(data);
        dispatch(setOpenDrawer(true));
        setInterestChartState(true);
        return;
      default:
        return;
    }
  };
  const onEditCriteria = (data) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Margin Rate Criteria");
    setActionData(data);
    dispatch(setOpenDrawer(true));
    setInterestChartState(true);
  };

  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button-sort">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <ChartTable
          onOptionsClick={(key, value) => onOptionsClick(key, value)}
          onEditCriteria={(data) => onEditCriteria(data)}
        />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Chart"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {interestChartState && (
          <AddNewInterestCharts
            openPopUp={interestChartState}
            setPopUpState={setInterestChartState}
            title={actionTitle}
            actionType={actionType}
            interestChartData={actionData}
            AllBusinessUnits={AllBusinessUnits}
            AllOtherCosts={AllOtherCosts}
            financeType={financeType}
            customerType={customerType}
            assetCategory={assetCategory}
            assetType={assetType}
            producttype={productType}
            businessTradingAge={tradingAge}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default InterestChart;
