import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useSetupsStore, useStoreContext } from "../Stores/SetupStore/Store";
import { IChartCriteria, ICriteria } from "../Interface";

export const getAllDownPaymentChartsFromServer = () => {
  return ConfigurationAPI.get("/down-payment").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllDownPaymentCharts = () => {
  return useQuery(QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS, async () => {
    return getAllDownPaymentChartsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAllDownPaymentChartsFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/down-payment/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllDownPaymentCharts_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER, columnFilters],
    async () => {
      return getAllDownPaymentChartsFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const useMutation_AddNewDownPaymentCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IChartCriteria>>(
    (downPaymentChart) => {
      return ConfigurationAPI.post("/down-payment/criteria", downPaymentChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewDownPaymentCriteria = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (downPaymentChart) => {
      return ConfigurationAPI.post("/down-payment", downPaymentChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_UpdateDownPaymentCharts = (
  down_payment_id: number
): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (downPaymentCharts: any) => {
      return ConfigurationAPI.patch(
        `/down-payment/${downPaymentCharts.id}`,
        downPaymentCharts
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_DeleteDownPaymentChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (downPaymentChartId: any) => {
      return ConfigurationAPI.delete(`/down-payment/${downPaymentChartId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
