import { FC } from "react";
import { useTheme } from "@mui/material";
import { Button, PersistentDrawer, Select } from "@ntpkunity/controls";
import { QueryKeys, ValidationMessages } from "Enums";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  UseQuery_getAllActiveLanguages,
  useMutation_AddNewLenderLanguage,
} from "services";
import { ILanguageModel } from "Interface";

const AddLanguage: FC<{
  open: boolean;
  setOpen: any;
}> = ({ open, setOpen }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const queryClient = useQueryClient();
  UseQuery_getAllActiveLanguages();
  const { mutate: addLanguage, isLoading } = useMutation_AddNewLenderLanguage();

  const languages: ILanguageModel[] = queryClient.getQueryData(
    QueryKeys.GET_ALL_ACTIVE_LANGUAGES
  );

  const onSubmit = (data) => {
    addLanguage(data, {
      onSuccess() {
        setOpen(false);
      },
    });
  };

  return (
    <>
      <PersistentDrawer
        openPopUp={open}
        setPopUpState={setOpen}
        title={"Add New Language"}
        theme={theme}
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                required: ValidationMessages.LANGUAGE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="languageId"
                  testid="languageTest"
                  theme={theme}
                  label={"Choose Language"}
                  placeholder={"Select"}
                  items={languages?.map((language) => ({
                    text: language.name,
                    value: language.code,
                  }))}
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  selectError={errors?.code?.message as any}
                  sxProps={""}
                ></Select>
              )}
            />
            <Controller
              name="is_default"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="isDefault"
                  testid="defaultTest"
                  theme={theme}
                  label={"Default Language"}
                  placeholder={"Select"}
                  items={[
                    { text: "No", value: false },
                    { text: "Yes", value: true },
                  ]}
                  value={value}
                  onChange={onChange}
                  sxProps={undefined}
                ></Select>
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            theme={theme}
            text={"Save Changes"}
            fullWidth={true}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddLanguage;
