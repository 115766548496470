import { styled } from "@mui/material/styles";
import { Box } from "@ntpkunity/controls";

export const MenuTooltipWrap = styled(
  Box,
  {}
)<any>(({ theme }) => ({
  ".MuiTooltip-popper": {
    width: 250,
    right: "10px !important",
    textAlign: "left",
    ".MuiTooltip-tooltip": {
      maxWidth: "100%",
      whiteSpace: "break-spaces",
      display: "inline-block",
    },
  },
  ".u-dropdown-menu": {
    ".MuiMenu-paper": {
      overflowY: "initial",
      overflowX: "initial",
    },
    ".u-dropdown-item": {
      "&.Mui-disabled": {
        opacity: 1,
        pointerEvents: "initial",
        cursor: "not-allowed",
        color: theme.palette.grey[300],

        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.grey[300],
        },
      },
    },
  },
}));
