import React, { FC } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, DataTable, Input } from "@ntpkunity/controls";
import { MultiSelectAll, BorderedTableWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { IFinanceTypeModel, IStates } from "Interface";

interface IFeesMappingProps {
  form: any;
  setUpdatedFeesRows: any;
  financeTypes: Array<IFinanceTypeModel>;
  statesData: Array<IStates>;
  feesMappings: any;
}

const FeesTable: FC<IFeesMappingProps> = ({
  form,
  setUpdatedFeesRows,
  financeTypes,
  statesData,
  feesMappings,
}) => {
  const theme = useTheme();
  const { control, setValue, getValues } = form;

  const stateItems =
    statesData?.map((state) => ({
      text: state.name,
      value: state.id,
    })) || [];

  const financeTypeItems =
    financeTypes?.map((finance_type) => ({
      text: finance_type.name,
      value: finance_type.id,
    })) || [];

  const handleRowChange = (index) => {
    setUpdatedFeesRows((prev) => new Set(prev).add(index));
  };

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <TableRow>
            <TableCell className="sr-num"></TableCell>
            <TableCell>Fee Name</TableCell>
            <TableCell>Finance Type</TableCell>
            <TableCell>State</TableCell>
            <TableCell>External Code</TableCell>
          </TableRow>
        }
        tbodyChildren={feesMappings?.data?.map((item, index) => (
          <React.Fragment key={index}>
            <TableRow className="child-tr">
              <TableCell className="sr-num">{index + 1}</TableCell>
              <TableCell>{item.fee_name}</TableCell>
              <Controller
                name={`feesMapping.${index}.fee_name_id`}
                control={control}
                defaultValue={item.fee_name_id}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <Controller
                name={`feesMapping.${index}.id`}
                control={control}
                defaultValue={item.id}
                render={({ field }) => <input type="hidden" {...field} />}
              />

              <TableCell className="input-cell">
                <Controller
                  name={`feesMapping.${index}.finance_type`} // Use this format
                  control={control}
                  defaultValue={item.finance_type || []}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelectAll
                      id={`feesMapping.${index}.finance_type`}
                      name={`feesMapping.${index}.finance_type`}
                      theme={theme}
                      isSelectAll={false}
                      getValues={getValues}
                      setValue={setValue}
                      items={financeTypeItems}
                      value={value as any[]}
                      onChange={(e) => {
                        onChange(e);
                        handleRowChange(index);
                      }}
                      disablePortal={false}
                    />
                  )}
                />
              </TableCell>
              <TableCell className="input-cell">
                <Controller
                  name={`feesMapping.${index}.state`}
                  control={control}
                  defaultValue={item.state || []}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelectAll
                      id={`feesMapping.${index}.state`}
                      name={`feesMapping.${index}.state`}
                      theme={theme}
                      isSelectAll={false}
                      getValues={getValues}
                      setValue={setValue}
                      items={stateItems}
                      value={value as any[]}
                      onChange={(e) => {
                        onChange(e);
                        handleRowChange(index);
                      }}
                      disablePortal={false}
                    />
                  )}
                />
              </TableCell>
              <TableCell className="input-cell">
                <Controller
                  name={`feesMapping.${index}.external_code`}
                  control={control}
                  defaultValue={item.external_code || ""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      type="string"
                      id={`feesMapping.${index}.external_code`}
                      onChange={(e) => {
                        onChange(e);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      />
    </BorderedTableWrap>
  );
};

export default FeesTable;
