import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IFPCategoryMappings, IOptionMapping, IFeesMapping } from "Interface";

// Fees Mapping Management

export const useMutation_GetAllFeesMappings = (providerId) => {
  return useMutation<any>(() => {
    return ConfigurationAPI.get(`/fees-mapping?provider_id=${providerId}`);
  });
};

export const UseMutation_AddFeesMappings = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IFeesMapping[], unknown, IFeesMapping[]>(
    (fees_mapping_data: IFeesMapping[]) => {
      const requestData = fees_mapping_data?.map((item) => ({
        ...item,
        is_active: true,
        is_deleted: false,
      }));
      return ConfigurationAPI.post("/fees-mapping", requestData);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_MAPPINGS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

// Option Mapping Management

export const useMutation_GetAllOptionMappings = (providerId) => {
  return useMutation<any>(() => {
    return ConfigurationAPI.get(`/option-mapping?provider_id=${providerId}`);
  });
};

export const UseMutation_AddOptionMappings = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IOptionMapping[], unknown, IOptionMapping[]>(
    (option_mapping_data: IOptionMapping[]) => {
      const requestData = option_mapping_data?.map((item) => ({
        ...item,
        is_active: true,
        is_deleted: false,
      }));
      return ConfigurationAPI.post("/option-mapping", requestData);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_MAPPINGS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useMutation_GetFPCategoryMappings = (providerId) => {
  return useMutation<any>(() => {
    return ConfigurationAPI.get(
      `/fp-category-mapping?provider_id=${providerId}`
    );
  });
};

export const UseMutation_AddFPCategoryMappings = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IFPCategoryMappings[], unknown, IFPCategoryMappings[]>(
    (category_mapping_data: IFPCategoryMappings[]) => {
      const requestData = category_mapping_data?.map((item) => ({
        ...item,
        is_active: true,
        is_deleted: false,
      }));
      return ConfigurationAPI.post("/fp-category-mapping", requestData);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FP_CATEGORY_MAPPINGS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};
