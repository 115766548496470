import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IFinancedAmount, IFinancedAmountFilter } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setFinancedAmountInitialData } from "Stores/SetupStore/Actions";

export const getAllFinancedAmountFromServer = () => {
  return ConfigurationAPI.get("/Financed-Amount").then((res) => {
    return res.data;
  });
};
export const getActiveFinancedAmountFromServer = () => {
  return ConfigurationAPI.get("/Financed-Amount/active").then((res) => {
    return res.data;
  });
};

export const getAllFinancedAmountFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/Financed-Amount/filter?`.concat(columnFilters)
  ).then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllFinancedAmount = () => {
  return useQuery<Array<IFinancedAmount>>(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS,
    async () => {
      return getAllFinancedAmountFromServer().then((data) => {
        return data;
      });
    }
  );
};
export const UseQuery_getActiveFinancedAmount = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery<Array<IFinancedAmount>>(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS_ACTIVE,
    async () => {
      return getActiveFinancedAmountFromServer().then((data) => {
        dispatch(setFinancedAmountInitialData(data));
        return data;
      });
    }
  );
};

export const UseQuery_getAllFinancedAmount_filter = (columnFilters: string) => {
  return useQuery<IFinancedAmountFilter>(
    [QueryKeys.GET_ALL_FINANCED_AMOUNTS_FILTER, columnFilters],
    () => {
      return getAllFinancedAmountFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddFinancedAmount = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<IFinancedAmount>(
    (financedAmount) => {
      return ConfigurationAPI.post("/Financed-Amount", financedAmount);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCED_AMOUNTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FINANCED_AMOUNTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_AddFinancedAmountMultiple = (): any => {
  const queryClient = useQueryClient();
  return useMutation<Array<IFinancedAmount>>(
    (financedAmount) => {
      return ConfigurationAPI.post(
        "/Financed-Amount/add-multiple",
        financedAmount
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FINANCED_AMOUNTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FINANCED_AMOUNTS);
      },
    }
  );
};

export const UseMutation_UpdateFinancedAmount = (mileageId: number): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IFinancedAmount>(
    (financedAmount: any) => {
      return ConfigurationAPI.patch(
        `/Financed-Amount/${financedAmount.id}`,
        financedAmount
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCED_AMOUNTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FINANCED_AMOUNTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteFinancedAmount = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IFinancedAmount>(
    (amountId) => {
      return ConfigurationAPI.delete(`/Financed-Amount/${amountId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCED_AMOUNTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FINANCED_AMOUNTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
