import React, { FC } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, DataTable, Input } from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { BorderedTableWrap } from "@sharedComponents";

interface IOptionMappingProps {
  form: any;
  setUpdatedOptionRows: any;
  optionMappings: any;
}

const OptionsTable: FC<IOptionMappingProps> = ({
  form,
  setUpdatedOptionRows,
  optionMappings,
}) => {
  const theme = useTheme();
  const { control } = form;

  const handleRowChange = (index) => {
    setUpdatedOptionRows((prev) => new Set(prev).add(index));
  };

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="sr-num"></TableCell>
              <TableCell>Category</TableCell>
              <TableCell>External Code</TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={optionMappings?.data?.map((item, index) => (
          <React.Fragment key={item.id || index}>
            <TableRow className="child-tr">
              <TableCell className="sr-num">{index + 1}</TableCell>
              <TableCell>{item.option_category}</TableCell>
              <Controller
                name={`optionMapping.${index}.option_category_id`}
                control={control}
                defaultValue={item.option_category_id}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <Controller
                name={`optionMapping.${index}.id`}
                control={control}
                defaultValue={item.id}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <TableCell className="input-cell">
                <Controller
                  name={`optionMapping.${index}.external_code`}
                  control={control}
                  defaultValue={item.external_code || ""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      type="string"
                      id={`optionMapping.${index}.external_code`}
                      onChange={(newValue) => {
                        onChange(newValue);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      />
    </BorderedTableWrap>
  );
};

export default OptionsTable;
