import { StoreActionType } from "Types";
import { IStates } from "Interface";
import { ActionTypes } from "Enums";

export const StatesReducer = (
  state: Array<IStates> = [],
  action: StoreActionType
): Array<IStates> => {
  switch (action.type) {
    case ActionTypes.SET_STATES_INITIAL_DATA:
      return [...action.payload];

    default:
      return state;
  }
};
