import { useTheme } from "@mui/material";
import { IConfirmationPopUp } from "./ConfirmationDialog.type";
import { Dialog, Typography, Box, Button } from "@ntpkunity/controls";
import ReactMarkdown from "react-markdown";
import { DialogWrap } from "../DialogWrap";

const ConfirmationDialog = <T extends any>({
  openPopUp,
  setPopUpState,
  confirmationText,
  confirmationTitle,
  onConfirm,
  onCancel,
  icon,
  primaryBtnText,
}: IConfirmationPopUp<T>) => {
  const theme = useTheme();

  const onClose = () => {
    setPopUpState(false);
  };

  const onCancelClick = (e) => {
    onClose();
    onCancel(e);
  };

  const onConfirmClick = (e) => {
    setPopUpState(false);
    onConfirm(e);
  };

  return (
    <Dialog
      size="xs"
      variant="confirmation"
      open={openPopUp}
      noFooter={false}
      className={"u-dialog-sm"}
      onCloseDialog={onClose}
      theme={theme}
      disablePortal
      customFooter={
        <>
          <Button
            theme={theme}
            secondary
            text={"Cancel"}
            onClick={onCancelClick}
          />

          <Button
            theme={theme}
            onClick={onConfirmClick}
            primary
            text={primaryBtnText}
          />
        </>
      }
      children={
        <>
          <DialogWrap>
            <Box theme={theme} className="dialog-icon">
              {icon}
            </Box>

            <Box theme={theme}>
              <Typography
                theme={theme}
                mb={2}
                className="content-title"
                variant="h2"
                component="h2"
              >
                {confirmationTitle}
              </Typography>

              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component="span"
              >
                <ReactMarkdown>{confirmationText}</ReactMarkdown>
              </Typography>
            </Box>
          </DialogWrap>
        </>
      }
    />
  );
};

export default ConfirmationDialog;
