import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ITradingAge } from "Interface";

export const getAllTradingAgeFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/business-trading-age/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const getAllTradingAgeFromServer = () => {
  return ConfigurationAPI.get("/business-trading-age").then((res) => {
    return res.data;
  });
};
export const getActiveTradingAgeFromServer = () => {
  return ConfigurationAPI.get("/business-trading-age/active").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllTradingAge = () => {
  return useQuery(QueryKeys.GET_ALL_TRADING_AGE, async () => {
    return getAllTradingAgeFromServer().then((data) => {
      return data;
    });
  });
};
export const UseQuery_GetActiveTradingAge = () => {
  return useQuery(QueryKeys.GET_ALL_TRADING_AGE_ACTIVE, async () => {
    return getActiveTradingAgeFromServer().then((data) => {
      return data;
    });
  });
};

export const UseMutation_AddBusinessTradingAge = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ITradingAge>(
    (term) => {
      return ConfigurationAPI.post("/business-trading-age", term);
    },
    {
      onSuccess: async (res: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE_FILTER),
          queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE),
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true,
          });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_AddTradingAgeMultiple = (): any => {
  const queryClient = useQueryClient();
  return useMutation<ITradingAge>(
    (terms) => {
      return ConfigurationAPI.post("/business-trading-age/add-multiple", terms);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE);
      },
    }
  );
};

export const UseMutation_UpdateTradingAge = (ageId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ITradingAge>(
    (age: any) => {
      return ConfigurationAPI.patch(`/business-trading-age/${age.id}`, age);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE),
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteTradingAge = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ITradingAge>(
    (ageId) => {
      return ConfigurationAPI.delete(`/business-trading-age/${ageId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TRADING_AGE),
          actions.setToast({
            toastMessage: ToastMessages.Record_Deleted_Success,
            toastState: true,
          });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseQuery_getAllTradingAge_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_TRADING_AGE_FILTER, columnFilters],
    async () => {
      return getAllTradingAgeFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};
