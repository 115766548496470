import TableCell from "@mui/material/TableCell";
import React, { FC, useState } from "react";
import TableRow from "@mui/material/TableRow";
import { Switch } from "@sharedComponents";
import { Icon, Menu, Button, Box, Input } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { ActionOptions } from "Enums";
import { useUpdateLenderIntegrationParentStatus } from "services";
import TBProviderRow from "./TBProviderRow";

const TBIntegrationRow: FC<{
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  onProviderMapping: (id) => unknown;
  integrationRow: any;
  setParentIntegrationState: any;
}> = ({
  onEdit,
  onDelete,
  integrationRow,
  setParentIntegrationState,
  onProviderMapping,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { mutate: updateLenderIntegrationParentStatus } =
    useUpdateLenderIntegrationParentStatus();

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        setParentIntegrationState(true);
        onEdit(value);
        return;
    }
  };

  return (
    <>
      {integrationRow?.providers.length > 0 ? (
        <TableRow key={`parent${integrationRow.id}`} className="child-tr">
          <TableCell>
            {open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )}
            {integrationRow?.integration_type}
          </TableCell>
          <TableCell></TableCell>
          <TableCell className="action-cell fixed-cell">
            <Menu
              id={"ellipses" + integrationRow?.id}
              theme={theme}
              disablePortal
              options={[
                {
                  optionText: (
                    <>
                      <Icon className="menu-icon" name="EditIcon" />
                      {ActionOptions.ADD_PROVIDER}
                    </>
                  ),
                  optionkey: ActionOptions.EDIT,
                  optionValue: {},
                },
              ]}
              handleOptionClick={(e, key, value) =>
                handleSelection(e, key, integrationRow)
              }
              render={(onMenuSelection) => (
                <Button
                  defaultBtn
                  iconText={<Icon name="MoreIcon" />}
                  onClick={onMenuSelection}
                ></Button>
              )}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </TableCell>
        </TableRow>
      ) : (
        ""
      )}

      {open ? (
        <TBProviderRow
          onDelete={onDelete}
          onProviderMapping={onProviderMapping}
          integrationRow={integrationRow}
          integrationProviders={integrationRow.providers}
          setParentIntegrationState={setParentIntegrationState}
          key={integrationRow.id}
        />
      ) : null}
    </>
  );
};
export default TBIntegrationRow;
