import { FC, useState, useEffect } from "react";
import { Grid, Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { useTheme } from "@mui/material";
import { AddNewPreQualification } from "components";
import { ActionOptions } from "Enums";
import { IPreQualification } from "Interface";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import PreQualificationTable from "./pre-qualification-table";

const PreQualification: FC = () => {
  const [PreQualificationState, setPreQualificationState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Range");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IPreQualification>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (PreQualificationState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [PreQualificationState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Range");
        setActionData(value);
        setPreQualificationState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IPreQualification) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Range");
    setActionData(formData);
    setPreQualificationState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <PreQualificationTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Range"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {PreQualificationState && (
          <AddNewPreQualification
            openPopUp={PreQualificationState}
            setPopUpState={setPreQualificationState}
            title={actionTitle}
            actionType={actionType}
            PreQualificationData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default PreQualification;
