import { IAddress, ICompanyProfile, IThemeBuilder } from "Interface";

export default class CompanyModel {
  company_profile: ICompanyProfile;
  company_addresses: Array<IAddress>;
  theme?: IThemeBuilder;
  company_documents?: any;
  deleted_documents?: any;
  pricing_program?: string;
}
