import { StoreActionType } from "Types";
import { IFpGeneral } from "Interface";
import { ActionTypes } from "Enums";

export const ProgramsReducer = (
  state: Array<IFpGeneral> = [],
  action: StoreActionType
): Array<IFpGeneral> => {
  switch (action.type) {
    case ActionTypes.SET_PROGRAM_INIT_STATE:
      return [...action.payload];
    default:
      return state;
  }
};
