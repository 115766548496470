import { useQuery, UseQueryResult } from "react-query";
import { QueryKeys } from "../../Enums/UseQueryKeys";
import { getUserByRole } from "../../services/dealer-management.service";
import { useGetAllPermissions } from "@ntpkunity/controls-ums";
import { UserByRole } from "../../Types/userByRole";

export const useGetUserByRole = (): UseQueryResult<UserByRole[]> => {
  const permissions = useGetAllPermissions();
  const roleId = permissions?.permissions?.[0]?.role_id;
  return useQuery<UserByRole[]>({
    queryKey: [QueryKeys.GET_USER_BY_ROLE],
    queryFn: () => getUserByRole(roleId),
    enabled: !!roleId,
    retry: 2,
  });
};
