import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IAnnualMileage, IAnnualMileageFilter } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setAnnualMileageInitialData } from "Stores/SetupStore/Actions";

export const getAllMileageFromServer = () => {
  return ConfigurationAPI.get("/annual-mileage").then((res) => {
    return res.data;
  });
};
export const getActiveMileageFromServer = () => {
  return ConfigurationAPI.get("/annual-mileage/active").then((res) => {
    return res.data;
  });
};

export const getAllMileageFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/annual-mileage/filter/?`.concat(columnFilters)
  ).then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllMileage = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery<Array<IAnnualMileage>>(
    QueryKeys.GET_ALL_ANNUAL_MILEAGES,
    async () => {
      return getAllMileageFromServer().then((data) => {
        dispatch(setAnnualMileageInitialData(data));
        return data;
      });
    }
  );
};
export const UseQuery_getActiveMileage = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery<Array<IAnnualMileage>>(
    QueryKeys.GET_ALL_ANNUAL_MILEAGES_ACTIVE,
    async () => {
      return getActiveMileageFromServer().then((data) => {
        dispatch(setAnnualMileageInitialData(data));
        return data;
      });
    }
  );
};

export const UseQuery_getAllMileage_filter = (columnFilters: string) => {
  return useQuery<IAnnualMileageFilter>(
    [QueryKeys.GET_ALL_ANNUAL_MILEAGES_FILTER, columnFilters],
    () => {
      return getAllMileageFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddAnnualMileage = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<IAnnualMileage>(
    (annualMileage) => {
      return ConfigurationAPI.post("/annual-mileage", annualMileage);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_AddAnnualMileageMultiple = (): any => {
  const queryClient = useQueryClient();
  return useMutation<Array<IAnnualMileage>>(
    (annualMileages) => {
      return ConfigurationAPI.post(
        "/annual-mileage/add-multiple",
        annualMileages
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES);
      },
    }
  );
};

export const UseMutation_UpdateAnnualMileage = (mileageId: number): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IAnnualMileage>(
    (annualMileage: any) => {
      return ConfigurationAPI.patch(
        `/annual-mileage/${annualMileage.id}`,
        annualMileage
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteAnnualMileage = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IAnnualMileage>(
    (mileageId) => {
      return ConfigurationAPI.delete(`/annual-mileage/${mileageId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ANNUAL_MILEAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
