export enum CommissionType {
  CRITERIA = "Criteria",
}
export enum AmortizationMethod {
  ANNUITY = "Annuity",
  STRAIGHT_LINE = "Straight line",
  STRAIGHT_LINE_WITH_ACTUAL_NUMBER_OF_DAYS = "Straight line with actual number of days",
  STRAIGHT_LINE_PRO_RATA = "Straight line pro rata",
  ANNUITY_ACTUAL_NUMBER_OF_DAYS = "Annuity - Actual number of days",
}
export enum CommissionOverride {
  YES = "Yes",
  NO = "No",
}
