import { INewRvChartPopUp } from "./AddNewRvChart.type";
import { FC, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  ConfirmationDialog,
  Input,
  MultiSelectWrap,
  Select,
  TextBox,
} from "@sharedComponents";
import {
  useMutation_AddNewRvChart,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
  UseMutation_AddCharts,
  UseMutation_UpdateCharts,
  useMutation_AddNewRvCriteria,
  useMutation_UpdateRvChart,
  UseMutation_CheckDuplicateRVChartName,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { SetupContext } from "context";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
  ToastMessages,
} from "Enums";
import {
  ISelectItemList,
  IRvChartCriteria,
  ICharts,
  IRvChart,
  IAssetMake,
  IAssetModel,
  IAssetTrim,
} from "Interface";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import {
  Grid,
  Autocomplete,
  PersistentDrawer,
  Icon,
  MultiSelect,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";

const AddNewRvCharts: FC<INewRvChartPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  rvChartData,
  AllAnnualMileage,
  financeType,
  customerType,
  assetCategory,
  assetType,
}) => {
  const theme = useTheme();

  const { mutate: CheckNameDuplicate } =
    UseMutation_CheckDuplicateRVChartName();
  const [state, dispatch] = useSetupsStore();
  const { actions } = useStoreContext();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    getValues,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IRvChartCriteria>();
  const { mutate: AddRvChart } = useMutation_AddNewRvChart();
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };
  const { assetConditions, terms, AllFinancedAmounts, assetUsages } =
    useContext(SetupContext);
  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();
  const queryClient = useQueryClient();
  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [chartEnable, setChartEnable] = useState<boolean>(false);
  const [criteriaEnable, setCriteriaEnable] = useState<boolean>(false);
  UseMutation_AddCharts;
  const { mutate: AddChart } = UseMutation_AddCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();
  const { mutate: AddNewRvCriteria } = useMutation_AddNewRvCriteria();
  const { mutate: UpdateRvCriteria } = useMutation_UpdateRvChart();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [mileageId, setMileageId] = useState([]);
  const [assetUsage, setAssetUsage] = useState([]);
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const [assetTermId, setAssetTermId] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [financetype, setFinanceType] = useState([]);
  const [customertype, setCustomerType] = useState([]);
  const [assetcategory, setAssetCategory] = useState([]);
  const [assettype, setAssetType] = useState([]);
  const [rvGuaranteed, setRvGuaranteed] = useState([]);
  const [financedAmounts, setFinancedAmounts] = useState([]);
  const [assetMake, setAssetMake] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  const [isChartAdded, setIsChartAdded] = useState<boolean>(false);
  const [chartId, setChartId] = useState(null);
  const [purchaseOption, setPurchaseOption] = useState([]);
  const [chartNameValidation, setChartNameValidation] =
    useState<boolean>(false);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  useEffect(() => {
    if (actionType == ActionOptions.RENAME_CHART && rvChartData) {
      setCriteriaEnable(true);
      setChartEnable(false);
      setValue("chart.chart_name", rvChartData.chart?.chart_name);
      setValue("chart.is_active", rvChartData.chart?.is_active);
    } else if (actionType == ActionOptions.ADD_CRITERIA && rvChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setValue("chart.chart_name", rvChartData.chart?.chart_name);
      setValue("chart.is_active", rvChartData.chart?.is_active);
    }
    // else if (actionType === ActionOptions.ADD_NEW_CHART && !isChartAdded) {
    //   setChartEnable(false);
    //   setCriteriaEnable(false);
    //   reset(undefined);
    // }
    //edit criteria
    else if (actionType == ActionOptions.EDIT && rvChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setValue("chart.chart_name", rvChartData?.chart?.chart_name);
      setValue("chart.is_active", rvChartData?.chart?.is_active);
      setAssetMakeId(rvChartData?.criteria?.asset_make_name);
      setAssetModelId(rvChartData?.criteria?.asset_model_name);
      setAssetTrimId(rvChartData?.criteria?.asset_trim_name);
      setValue("criteria.terms", rvChartData?.criteria?.terms);
      if (rvChartData?.criteria?.terms) {
        setAssetTermId(rvChartData?.criteria?.terms);
      }
      if (rvChartData?.criteria?.asset_condition) {
        setAssetCondition(rvChartData?.criteria?.asset_condition);
      }
      if (rvChartData?.criteria?.finance_type) {
        setFinanceType(rvChartData?.criteria?.finance_type);
      }
      if (rvChartData?.criteria?.customer_type) {
        setCustomerType(rvChartData?.criteria?.customer_type);
      }
      if (rvChartData?.criteria?.asset_category) {
        setAssetCategory(rvChartData?.criteria?.asset_category);
      }
      if (rvChartData?.criteria?.asset_type) {
        setAssetType(rvChartData?.criteria?.asset_type);
      }
      if (rvChartData?.criteria?.asset_usages) {
        setAssetUsage(rvChartData?.criteria?.asset_usages);
      }
      if (rvChartData?.criteria?.annual_usage) {
        setMileageId(rvChartData?.criteria?.annual_usage);
      }
      setValue(
        "criteria.asset_condition",
        rvChartData?.criteria?.asset_condition
      );
      setValue("criteria.finance_type", rvChartData?.criteria?.finance_type);
      setValue("criteria.customer_type", rvChartData?.criteria?.customer_type);
      setValue(
        "criteria.asset_category",
        rvChartData?.criteria?.asset_category
      );
      setValue("criteria.asset_type", rvChartData?.criteria?.asset_type);
      if (rvChartData?.criteria?.is_rv_guaranteed) {
        setRvGuaranteed(rvChartData?.criteria?.is_rv_guaranteed);
      }
      if (rvChartData?.criteria?.financed_amount) {
        setFinancedAmounts(rvChartData?.criteria?.financed_amount);
      }

      if (rvChartData?.criteria?.purchase_option) {
        setPurchaseOption(rvChartData?.criteria?.purchase_option);
      }
      setValue(
        "criteria.is_rv_guaranteed",
        rvChartData?.criteria?.is_rv_guaranteed
      );
      setValue("criteria.rv_type", rvChartData?.criteria?.rv_type);
      setValue("criteria.rv_value", rvChartData?.criteria?.rv_value);
      setValue("criteria.minimum_rv", rvChartData?.criteria?.minimum_rv);
      setValue("criteria.maximum_rv", rvChartData?.criteria?.maximum_rv);
    }
  }, [rvChartData, criteriaEnable, chartEnable]);

  const [assetMakeList, setAssetMakeList] = useState<Array<ISelectItemList>>(
    assetMakes
      ?.filter((x) => x.is_active)
      .map((make) => ({
        value: make.id,
        text: make.name,
      }))
  );

  useEffect(() => {
    setAssetMakeList(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((make) => ({
          value: make.name,
          text: make.name,
        }))
    );
  }, [assetMakes, assetMakeId]);

  const [assetModelList, setAssetModelList] = useState<Array<ISelectItemList>>(
    assetModels
      ?.filter((x) => x.is_active)
      .map((model) => ({
        value: model.id,
        text: model.model_name,
      }))
  );

  useEffect(() => {
    setAssetMake(assetMakes?.filter((x) => x.name == assetMakeId)[0]?.id);
    setAssetModelList(
      assetModels
        ?.filter(
          (models) =>
            assetMake > 0 && models.make_id === assetMake && models.is_active
        )
        .map((model) => ({
          value: model.model_name,
          text: model.model_name,
        }))
    );
  }, [assetMakes, assetModels, assetMakeId, assetMake]);

  const [assetTrimList, setAssetTrimList] = useState<Array<ISelectItemList>>(
    assetTrims
      ?.filter((x) => x.is_active)
      .map((trim) => ({
        value: trim.id,
        text: trim.trim_name,
      }))
  );

  useEffect(() => {
    setAssetModel(
      assetModels?.filter((x) => x.model_name == assetModelId)[0]?.id
    );
    setAssetTrimList(
      assetTrims
        ?.filter(
          (trim) =>
            assetModel > 0 && trim.model_id === assetModel && trim.is_active
        )
        .map((trim) => ({
          value: trim.trim_name,
          text: trim.trim_name,
        }))
    );
  }, [
    assetMakes,
    assetModels,
    assetTrims,
    assetMakeId,
    assetModelId,
    assetModel,
  ]);

  const [annualMileagesList, setAnnualMileagesList] =
    useState<Array<ISelectItemList>>();
  const [termsSelection, setTermsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetConditionSelection, setAssetConditionSelectionState] =
    useState<Array<ISelectItemList>>();
  const [financeTypeSelection, setFinancedTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [customerTypeSelection, setCustomerTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [assetCategorySelection, setAssetCategorySelection] =
    useState<Array<ISelectItemList>>();
  const [assetTypeSelection, setAssetTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetUsageSelection, setAssetUsageSelectionState] =
    useState<Array<ISelectItemList>>();
  useEffect(() => {
    const mileageArray = AllAnnualMileage?.filter((x) => x.is_active)?.map(
      (annualMileage) => ({
        value:
          annualMileage.distance_from.toString() +
          " - " +
          annualMileage.distance_to.toString(),
        text:
          annualMileage.distance_from.toLocaleString() +
          " - " +
          annualMileage.distance_to.toLocaleString(),
      })
    );
    mileageId?.forEach((id) => {
      const selectedMileage = AllAnnualMileage?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedMileage &&
        mileageArray.push({
          value:
            selectedMileage.distance_from.toString() +
            " - " +
            selectedMileage.distance_to.toString(),
          text:
            selectedMileage.distance_from.toLocaleString() +
            " - " +
            selectedMileage.distance_to.toLocaleString(),
        });
    });
    setAnnualMileagesList(mileageArray);
  }, [AllAnnualMileage, mileageId]);

  useEffect(() => {
    const termsArray = terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: `${term.terms_from} - ${term.terms_to}`,
        text: `${term.terms_from} - ${term.terms_to}`,
      }));
    assetTermId?.forEach((id) => {
      const selectedTerms = terms?.find((x) => x.id == id && !x.is_active);
      selectedTerms &&
        termsArray.push({
          value: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
          text: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
        });
    });
    setTermsSelectionState(termsArray);
  }, [terms, assetTermId]);
  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from} - ${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);

  useEffect(() => {
    const assetConditionArray = assetConditions
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCondition.forEach((id) => {
      const selectedAssetConditions = assetConditions?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetConditions &&
        assetConditionArray.push({
          value: selectedAssetConditions?.description,
          text: selectedAssetConditions?.description,
        });
    });
    setAssetConditionSelectionState(assetConditionArray);
  }, [assetConditions, assetCondition]);

  useEffect(() => {
    const financedTypeArray = financeType
      ?.filter((x) => x.is_active)
      ?.map((finance) => ({
        value: `${finance.name}`,
        text: `${finance.name}`,
      }));
    financetype.forEach((id) => {
      const selectedFinancedType = financeType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedType &&
        financedTypeArray.push({
          value: selectedFinancedType?.name,
          text: selectedFinancedType?.name,
        });
    });
    setFinancedTypeSelection(financedTypeArray);
  }, [financeType, financetype]);

  useEffect(() => {
    const customerTypeArray = customerType
      ?.filter((x) => x.is_active)
      ?.map((customer) => ({
        value: `${customer.description}`,
        text: `${customer.description}`,
      }));
    customertype.forEach((id) => {
      const selectedCustomerType = customerType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedCustomerType &&
        customerTypeArray.push({
          value: selectedCustomerType?.description,
          text: selectedCustomerType?.description,
        });
    });
    setCustomerTypeSelection(customerTypeArray);
  }, [customerType, customertype]);

  useEffect(() => {
    const assetCategoryArray = assetCategory
      ?.filter((x) => x.is_active)
      ?.map((category) => ({
        value: `${category.description}`,
        text: `${category.description}`,
      }));
    assetcategory.forEach((id) => {
      const selectedAssetCategory = assetCategory?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetCategory &&
        assetCategoryArray.push({
          value: selectedAssetCategory?.description,
          text: selectedAssetCategory?.description,
        });
    });
    setAssetCategorySelection(assetCategoryArray);
  }, [assetCategory, assetcategory]);

  useEffect(() => {
    const assetTypeArray = assetType
      ?.filter((x) => x.is_active || x.is_active === null)
      ?.map((asset) => ({
        value: `${asset.name}`,
        text: `${asset.name}`,
      }));
    assettype.forEach((id) => {
      const selectedAssetType = assetType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetType &&
        assetTypeArray.push({
          value: selectedAssetType?.name,
          text: selectedAssetType?.name,
        });
    });
    setAssetTypeSelection(assetTypeArray);
  }, [assetType, assettype]);

  useEffect(() => {
    const assetUsageArray = assetUsages
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetUsage.forEach((desc) => {
      const selectedAssetUsages = assetUsages?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetUsages &&
        assetUsageArray.push({
          value: selectedAssetUsages?.description,
          text: selectedAssetUsages?.description,
        });
    });
    setAssetUsageSelectionState(assetUsageArray);
  }, [assetUsages, assetUsage]);

  const onMileageSelection = (e) => {
    setMileageId(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onUsageSelection = (e) => {
    setAssetUsage(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onMakeSelection = (value) => {
    setAssetMakeId(value?.id);
    setAssetModelId(null);
    setAssetTrimId(null);
  };

  const onModelSelection = (value) => {
    setAssetModelId(value?.id);
    setAssetTrimId(null);
  };

  const onTrimSelection = (value) => {
    setAssetTrimId(value?.id);
  };

  const onTermSelection = (e) => {
    setAssetTermId(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onConditionSelection = (e) => {
    setAssetCondition(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onFinanceTypeSelection = (e) => {
    setFinanceType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onCustomerTypeSelection = (e) => {
    setCustomerType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onAssetCategorySelection = (e) => {
    setAssetCategory(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onAssetTypeSelection = (e) => {
    setAssetType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onRvGuaranteedSelection = (e) => {
    setRvGuaranteed(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onPurchaseOptionSelection = (e) => {
    setPurchaseOption(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const addNewChartWithCriteria = (data: IRvChartCriteria) => {
    if (rvChartData?.chart?.id > 0) {
      data.chartId = rvChartData?.chart?.id;
    }
    if (
      !(data?.criteria.rv_value >= 0) ||
      data?.criteria.rv_value == null ||
      isNaN(data?.criteria.rv_value)
    ) {
      setError("criteria.rv_value", {
        message: ValidationMessages.DEFAULT_VALUE_REQUIRED,
      });
    }
    if (data?.criteria.rv_type == null || data?.criteria.rv_type == undefined) {
      setError("criteria.rv_type", {
        message: ValidationMessages.RV_TYPE_REQUIRED,
      });
    }
    if (
      !(data?.criteria.minimum_rv >= 0) ||
      data?.criteria.minimum_rv == null ||
      isNaN(data?.criteria.minimum_rv)
    ) {
      setError("criteria.minimum_rv", {
        message: ValidationMessages.MIN_VALUE_REQUIRED,
      });
    }
    if (
      !(data?.criteria.maximum_rv >= 0) ||
      data?.criteria.maximum_rv == null ||
      isNaN(data?.criteria.maximum_rv)
    ) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.MAX_VALUE_REQUIRED,
      });
    }
    if (data?.criteria.maximum_rv < data?.criteria.minimum_rv) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.MAX_VALUE_GREATER,
      });
    }
    if (data?.criteria?.maximum_rv < data?.criteria?.rv_value) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.Max_GREATER_THAN_DEFAULT,
      });
    }
    if (data?.criteria?.minimum_rv > data?.criteria?.rv_value) {
      setError("criteria.minimum_rv", {
        message: ValidationMessages.MIN_SMALLER_THAN_DEFAULT,
      });
    } else if (
      data?.criteria?.rv_type != null &&
      data?.criteria?.rv_value >= 0 &&
      data?.criteria?.rv_value <= data?.criteria?.maximum_rv &&
      data?.criteria?.rv_value >= data?.criteria?.minimum_rv &&
      data?.criteria?.maximum_rv >= data?.criteria?.minimum_rv &&
      data?.criteria?.minimum_rv >= 0
    ) {
      clearErrors([
        "criteria.minimum_rv",
        "criteria.maximum_rv",
        "criteria.rv_value",
      ]);
      AddRvChart(data, {
        onSuccess: (data, variables, context) => {
          setChartId(data.data.id);
        },
      });
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      setAssetTermId([]);
      setAssetCondition([]);
      setFinanceType([]);
      setCustomerType([]);
      setAssetCategory([]);
      setAssetType([]);
      setPurchaseOption([]);
      setMileageId([]);
      setAssetUsage([]);
      resetField("criteria.rv_value");
      resetField("criteria.minimum_rv");
      resetField("criteria.maximum_rv");
      onClose();
    }
  };
  const updateChart = (data: ICharts) => {
    UpdateChart(data);
    reset();
    onClose();
  };

  const addCriteria = (data: IRvChart) => {
    if (
      !(data?.rv_value >= 0) ||
      data?.rv_value == null ||
      isNaN(data?.rv_value)
    ) {
      setError("criteria.rv_value", {
        message: ValidationMessages.DEFAULT_VALUE_REQUIRED,
      });
    }
    if (data?.rv_type == null || data?.rv_type == undefined) {
      setError("criteria.rv_type", {
        message: ValidationMessages.RV_TYPE_REQUIRED,
      });
    }
    if (
      !(data?.minimum_rv >= 0) ||
      data?.minimum_rv == null ||
      isNaN(data?.minimum_rv)
    ) {
      setError("criteria.minimum_rv", {
        message: ValidationMessages.MIN_VALUE_REQUIRED,
      });
    }
    if (
      !(data?.maximum_rv >= 0) ||
      data?.maximum_rv == null ||
      isNaN(data?.maximum_rv)
    ) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.MAX_VALUE_REQUIRED,
      });
    }
    if (data?.maximum_rv < data?.minimum_rv) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.MAX_VALUE_GREATER,
      });
    } else if (
      data?.rv_type != null &&
      data.rv_value >= 0 &&
      data?.rv_value <= data?.maximum_rv &&
      data?.rv_value >= data?.minimum_rv &&
      data.maximum_rv > data.minimum_rv &&
      data.minimum_rv >= 0
    ) {
      AddNewRvCriteria(data);
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      setAssetTermId([]);
      setAssetCondition([]);
      setFinanceType([]);
      setCustomerType([]);
      setAssetCategory([]);
      setAssetType([]);
      setAssetUsage([]);
      setPurchaseOption([]);
      setMileageId([]);
      resetField("criteria.terms");
      resetField("criteria.asset_condition");
      resetField("criteria.rv_value");
      resetField("criteria.minimum_rv");
      resetField("criteria.maximum_rv");
      onClose();
    }
  };

  const updateCriteria = (data: IRvChart) => {
    if (
      !(data?.rv_value >= 0) ||
      data?.rv_value == null ||
      isNaN(data?.rv_value)
    ) {
      setError("criteria.rv_value", {
        message: ValidationMessages.DEFAULT_VALUE_REQUIRED,
      });
    }
    if (data?.rv_type == null || data?.rv_type == undefined) {
      setError("criteria.rv_type", {
        message: ValidationMessages.RV_TYPE_REQUIRED,
      });
    }
    if (
      !(data?.minimum_rv >= 0) ||
      data?.minimum_rv == null ||
      isNaN(data?.minimum_rv)
    ) {
      setError("criteria.minimum_rv", {
        message: ValidationMessages.MIN_VALUE_REQUIRED,
      });
    }
    if (
      !(data?.maximum_rv >= 0) ||
      data?.maximum_rv == null ||
      isNaN(data?.maximum_rv)
    ) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (data?.maximum_rv < data?.minimum_rv) {
      setError("criteria.maximum_rv", {
        message: ValidationMessages.MAX_VALUE_GREATER,
      });
    } else if (
      data?.rv_type != null &&
      data.rv_value >= 0 &&
      data?.rv_value <= data?.maximum_rv &&
      data?.rv_value >= data?.minimum_rv &&
      data.maximum_rv > data.minimum_rv &&
      data.minimum_rv >= 0
    ) {
      clearErrors([
        "criteria.minimum_rv",
        "criteria.maximum_rv",
        "criteria.rv_value",
      ]);
      UpdateRvCriteria(data);
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      resetField("criteria.terms");
      resetField("criteria.asset_condition");
      resetField("criteria.financed_amount");
      resetField("criteria.purchase_option");
      resetField("criteria.rv_value");
      resetField("criteria.minimum_rv");
      resetField("criteria.maximum_rv");
      onClose();
    }
  };
  const onSubmit: SubmitHandler<IRvChartCriteria> = (
    data: IRvChartCriteria
  ) => {
    data.criteria.asset_make_name = assetMakeId;
    data.criteria.asset_model_name = assetModelId;
    data.criteria.asset_trim_name = assetTrimId;
    data.criteria.annual_usage = mileageId;
    data.criteria.is_rv_guaranteed;
    data.criteria.terms = assetTermId;
    data.criteria.asset_condition = assetCondition;
    data.criteria.finance_type = financetype;
    data.criteria.customer_type = customertype;
    data.criteria.asset_category = assetcategory;
    data.criteria.asset_type = assettype;
    data.criteria.asset_usages = assetUsage;
    data.criteria.is_rv_guaranteed = rvGuaranteed;
    data.criteria.financed_amount = financedAmounts;
    data.criteria.purchase_option = purchaseOption;
    data.chart.chart_type_id = 2;
    if (actionType == ActionOptions.ADD_NEW_CHART) {
      if (
        !(data?.criteria?.rv_value >= 0) ||
        data?.criteria?.rv_value == null
      ) {
        if (
          chartId == null &&
          data?.criteria?.asset_make_name == null &&
          data?.criteria?.asset_model_name == null &&
          data?.criteria?.asset_trim_name == null &&
          data?.criteria?.annual_usage.length == 0 &&
          data?.criteria?.is_rv_guaranteed.length == 0 &&
          data?.criteria?.terms.length == 0 &&
          data?.criteria?.financed_amount.length == 0 &&
          data?.criteria?.purchase_option.length == 0 &&
          data?.criteria?.asset_usages.length == 0 &&
          data?.criteria?.asset_condition.length == 0 &&
          data?.criteria?.customer_type.length == 0 &&
          data?.criteria?.finance_type.length == 0 &&
          data?.criteria?.asset_category.length == 0 &&
          data?.criteria?.asset_type.length == 0
        ) {
          AddChart(data.chart, {
            onSuccess: (data, variables, context) => {
              setChartId(data.data.id);
              setValue("chart.chart_name", data.data.chart_name);
              onClose();
            },
            onError: (error) => {
              reset(undefined);
              setValue("chart.chart_name", data.chart.chart_name);
              if (error?.response?.data?.detail?.code === 400) {
                setError("chart.chart_name", {
                  message: "Chart Name should be unique",
                });
                return;
              } else {
                actions.setToast({
                  toastMessage:
                    error.response.data.detail.message ??
                    ToastMessages.Internal_Server_Error,
                  toastState: true,
                  variant: "error",
                });
              }
            },
          });
          clearErrors([
            "criteria.minimum_rv",
            "criteria.maximum_rv",
            "criteria.rv_value",
          ]);
          setChartEnable(true);
          setIsChartAdded(true);
        } else if (
          (chartId == null && data?.criteria?.asset_make_name != null) ||
          data?.criteria?.asset_model_name != null ||
          data?.criteria?.asset_trim_name != null ||
          data?.criteria?.annual_usage.length != 0 ||
          data?.criteria?.is_rv_guaranteed.length != 0 ||
          data?.criteria?.terms.length != 0 ||
          data?.criteria?.asset_condition.length != 0 ||
          data?.criteria?.customer_type.length != 0 ||
          data?.criteria?.finance_type.length != 0 ||
          data?.criteria?.asset_category.length != 0 ||
          data?.criteria?.asset_type.length != 0 ||
          data?.criteria?.asset_usages.length != 0 ||
          data?.criteria?.financed_amount.length != 0 ||
          data?.criteria?.purchase_option.length != 0
        ) {
          if (
            data?.criteria?.minimum_rv != null &&
            data.criteria?.maximum_rv != null
          ) {
            addNewChartWithCriteria(data);
            setChartEnable(true);
          } else {
            setError("criteria.maximum_rv", {
              message: ValidationMessages.MAX_VALUE_REQUIRED,
            });
            setError("criteria.minimum_rv", {
              message: ValidationMessages.MIN_VALUE_REQUIRED,
            });
            setError("criteria.rv_value", {
              message: ValidationMessages.DEFAULT_VALUE_REQUIRED,
            });
          }
        } else {
          setError("criteria.maximum_rv", {
            message: ValidationMessages.MAX_VALUE_REQUIRED,
          });
          setError("criteria.minimum_rv", {
            message: ValidationMessages.MIN_VALUE_REQUIRED,
          });
          setError("criteria.rv_value", {
            message: ValidationMessages.DEFAULT_VALUE_REQUIRED,
          });
        }
      } else if (data?.criteria?.rv_value >= 0) {
        if (chartId != null) {
          data.criteria.chart_id = chartId;
          clearErrors([
            "criteria.minimum_rv",
            "criteria.maximum_rv",
            "criteria.rv_value",
          ]);
          addCriteria(data.criteria);
          setIsChartAdded(true);
          setChartEnable(true);
        } else {
          addNewChartWithCriteria(data);
          setIsChartAdded(true);
          setChartEnable(true);
        }
      }
    } else if (actionType == ActionOptions.RENAME_CHART) {
      data.chart.id = rvChartData.chart.id;
      updateChart(data.chart);
    } else if (actionType == ActionOptions.ADD_CRITERIA) {
      if (
        !(data?.criteria?.rv_value >= 0) ||
        data?.criteria?.rv_value == null
      ) {
        setError("criteria.rv_value", {
          message: ValidationMessages.DEFAULT_VALUE_REQUIRED,
        });
      }
      if (data?.criteria?.rv_type == null) {
        setError("criteria.rv_type", {
          message: ValidationMessages.RV_TYPE_REQUIRED,
        });
      }
      if (
        !(data?.criteria?.minimum_rv >= 0) ||
        data?.criteria?.minimum_rv == null
      ) {
        setError("criteria.minimum_rv", {
          message: ValidationMessages.MIN_VALUE_REQUIRED,
        });
      }
      if (
        !(data?.criteria?.maximum_rv >= 0) ||
        data?.criteria?.maximum_rv == null
      ) {
        setError("criteria.maximum_rv", {
          message: ValidationMessages.MAX_VALUE_REQUIRED,
        });
      }
      if (data?.criteria?.maximum_rv < data?.criteria?.minimum_rv) {
        setError("criteria.maximum_rv", {
          message: ValidationMessages.MAX_VALUE_GREATER,
        });
      }
      if (data?.criteria?.maximum_rv < data?.criteria?.rv_value) {
        setError("criteria.maximum_rv", {
          message: ValidationMessages.Max_GREATER_THAN_DEFAULT,
        });
      }
      if (data?.criteria?.minimum_rv > data?.criteria?.rv_value) {
        setError("criteria.minimum_rv", {
          message: ValidationMessages.MIN_SMALLER_THAN_DEFAULT,
        });
      } else if (
        data?.criteria?.rv_type != null &&
        data?.criteria?.rv_value >= 0 &&
        data?.criteria?.rv_value <= data?.criteria?.maximum_rv &&
        data?.criteria?.rv_value >= data?.criteria?.minimum_rv &&
        data?.criteria?.maximum_rv >= data?.criteria?.minimum_rv &&
        data?.criteria?.minimum_rv >= 0
      ) {
        clearErrors([
          "criteria.minimum_rv",
          "criteria.maximum_rv",
          "criteria.rv_value",
        ]);
        data.criteria.chart_id = rvChartData.chart.id;
        addCriteria(data.criteria);
      }
    }
    //edit criteria
    else if (actionType == ActionOptions.EDIT) {
      data.criteria.chart_id = rvChartData?.chart?.id;
      data.criteria.id = rvChartData?.criteria?.id;
      updateCriteria(data.criteria);
    }
  };
  const setDefaultValue = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.rv_value", parseFloat(val));
      clearErrors("criteria.rv_value");
      const maxRV = watch("criteria.maximum_rv");
      const minRV = watch("criteria.minimum_rv");
      if (val < minRV) {
        setError("criteria.minimum_rv", {
          message: ValidationMessages.MIN_SMALLER_THAN_DEFAULT,
        });
      } else {
        clearErrors("criteria.minimum_rv");
      }
      if (val > maxRV) {
        setError("criteria.maximum_rv", {
          message: ValidationMessages.Max_GREATER_THAN_DEFAULT,
        });
      } else {
        clearErrors("criteria.maximum_rv");
      }
    }
  };
  const setMinRV = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.minimum_rv", parseFloat(val));
      const maxRV = watch("criteria.maximum_rv");
      const rv = watch("criteria.rv_value");
      if (val > maxRV) {
        setError("criteria.minimum_rv", {
          message: ValidationMessages.MIN_VALUE_SMALLER,
        });
      } else if (val > rv) {
        setError("criteria.minimum_rv", {
          message: ValidationMessages.MIN_SMALLER_THAN_DEFAULT,
        });
      } else {
        clearErrors("criteria.minimum_rv");
      }
    }
  };

  const setMaxRV = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.maximum_rv", parseFloat(val));
      const minRV = watch("criteria.minimum_rv");
      const rv = watch("criteria.rv_value");
      if (minRV > val) {
        setError("criteria.maximum_rv", {
          message: ValidationMessages.MAX_VALUE_GREATER,
        });
      } else if (val < rv) {
        setError("criteria.maximum_rv", {
          message: ValidationMessages.Max_GREATER_THAN_DEFAULT,
        });
      } else {
        clearErrors(["criteria.maximum_rv", "criteria.minimum_rv"]);
      }
    } else {
      const num = val.substr(0, 2);
      setValue("criteria.maximum_rv", num);
    }
  };
  const checkRvChartName = (data: string) => {
    CheckNameDuplicate(
      {
        data: `name=${data}`,
      },
      {
        onSuccess: (response) => {
          setChartNameValidation(response.data);
          trigger("chart.chart_name");
          if (response.data) {
            setError(
              "chart.chart_name",
              {
                message: ValidationMessages.NAME_UNIQUE,
              },
              { shouldFocus: true }
            );
          } else {
            clearErrors("chart.chart_name");
          }
        },
      }
    );
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        onClose={onClose}
        enabledPin={true}
        theme={theme}
        children={
          <>
            <Controller
              name="chart.chart_name"
              control={control}
              rules={{
                required: ValidationMessages.CHART_NAME_REQUIRED,
                validate: {
                  checkUniqueName: () => {
                    if (chartNameValidation) {
                      return ValidationMessages.NAME_UNIQUE;
                    }
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  fullWidth={true}
                  theme={theme}
                  label={"Chart Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={(e) => checkRvChartName(e.target.value)}
                  disabled={chartEnable}
                  error={errors?.chart?.chart_name?.message}
                  type={"text"}
                />
              )}
            />

            <Controller
              name="chart.is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={chartEnable}
                ></Select>
              )}
            />
            <Box className="divider-with-text">
              <Box className="divider-text">BUSINESS CRITERIA</Box>
            </Box>
            <Controller
              name="criteria.asset_make_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  id="drawerMake"
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetMakeList
                      ? assetMakeList?.map((option: any) => {
                          return { label: option.text, id: option.value };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onMakeSelection(value);
                    field.onChange(value);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label={assetMakeId ? "Asset Make" : "Asset Make"}
                  value={
                    assetMakeId
                      ? {
                          label: assetMakeId,
                          id: assetMakeId,
                        }
                      :null
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_model_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerModel"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetModelList
                      ? assetModelList?.map((option: any) => {
                          return { label: option.text, id: option.value };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onModelSelection(value);
                    field.onChange(value);
                  }}
                  label={assetModelId ? "Asset Model" : "Asset Model"}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={
                    assetModelId
                      ? {
                          label: assetModelId,
                          id: assetModelId,
                        }
                      : null
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_trim_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerTrim"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetTrimList
                      ? assetTrimList?.map((option: any) => {
                          return { label: option.text, id: option.value };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onTrimSelection(value);
                    field.onChange(value);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label={assetTrimId ? "Asset Trim" : "Asset Trim"}
                  value={
                    assetTrimId
                      ? {
                          label: assetTrimId,
                          id: assetTrimId,
                        }
                      : null
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_condition"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Condition"}
                    value={assetCondition || []}
                    items={assetConditionSelection}
                    onChange={onConditionSelection}
                    defaultValue={["Used"]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.finance_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Finance Type"}
                    value={financetype || []}
                    items={financeTypeSelection}
                    onChange={onFinanceTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.customer_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Customer Type"}
                    value={customertype || []}
                    items={customerTypeSelection}
                    onChange={onCustomerTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_category"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Category"}
                    value={assetcategory || []}
                    items={assetCategorySelection}
                    onChange={onAssetCategorySelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Type"}
                    value={assettype || []}
                    items={assetTypeSelection}
                    onChange={onAssetTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_usages"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetUsage"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Usage"}
                    value={assetUsage || []}
                    items={assetUsageSelection}
                    onChange={onUsageSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.is_rv_guaranteed"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"RV Guaranteed"}
                    disabled={criteriaEnable}
                    items={[
                      { text: "Yes", value: "True" },
                      { text: "No", value: "False" },
                    ]}
                    value={rvGuaranteed || []}
                    onChange={onRvGuaranteedSelection}
                  />
                )}
              />
            </MultiSelectWrap>
            <Grid
              theme={theme}
              container
              rowSpacing={3}
              columnSpacing={2}
              sx={{ mb: 3 }}
            >
              <Grid theme={theme} item sm={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.terms"
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        theme={theme}
                        label={"Terms"}
                        items={termsSelection}
                        value={assetTermId || []}
                        onChange={onTermSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
              <Grid theme={theme} item sm={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.annual_usage"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <MultiSelect
                        theme={theme}
                        label={"Allowed Annual Mileage"}
                        items={annualMileagesList}
                        value={mileageId || []}
                        onChange={onMileageSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
            </Grid>
            <MultiSelectWrap>
              <Controller
                name="criteria.purchase_option"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerPurchaseOption"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Purchase Option"}
                    value={purchaseOption || []}
                    items={[
                      { text: "$1 out", value: "$1 out" },
                      { text: "FMV", value: "FMV" },
                    ]}
                    onChange={onPurchaseOptionSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.financed_amount"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount Range"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Box className="divider-with-text">
              <Box className="divider-text">OUTPUT PARAMETERS</Box>
            </Box>
            <Controller
              name="criteria.rv_type"
              control={control}
              defaultValue={"0"}
              rules={{
                required: ValidationMessages.RV_TYPE_REQUIRED,
              }}
              render={({ field }) => (
                <Select
                  theme={theme}
                  label={"RV/Balloon Type"}
                  items={[{ text: "Rate", value: 0 }]}
                  {...field}
                  disabled={criteriaEnable}
                  selectError={errors?.criteria?.rv_type?.message as never}
                ></Select>
              )}
            />
            <Controller
              name="criteria.rv_value"
              control={control}
              render={({ field }) => (
                <TextBox
                  theme={theme}
                  label={"Default Value"}
                  type="text"
                  disabled={criteriaEnable}
                  endAdornment={
                    <>
                      <span className="MuiInputAdornment-root">%</span>
                    </>
                  }
                  masking
                  scale={2}
                  numeric
                  {...field}
                  onChange={(val) => {
                    if (parseFloat(val) <= 100) setDefaultValue(val);
                    else setDefaultValue(val.substr(0, 2));
                  }}
                  error={errors?.criteria?.rv_value?.message as never}
                ></TextBox>
              )}
            />
            <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item sm={6}>
                <Controller
                  name="criteria.minimum_rv"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      theme={theme}
                      type="text"
                      {...field}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) setMinRV(val);
                        else setMinRV(val.substr(0, 2));
                      }}
                      label={"Min. Value"}
                      masking
                      numeric
                      disabled={criteriaEnable}
                      error={errors?.criteria?.minimum_rv?.message as never}
                    ></TextBox>
                  )}
                />
              </Grid>
              <Grid theme={theme} item sm={6}>
                <Controller
                  name="criteria.maximum_rv"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      theme={theme}
                      type="text"
                      {...field}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) setMaxRV(val);
                        else setMaxRV(val.substr(0, 2));
                      }}
                      label={"Max. Value"}
                      masking
                      // scale={2}
                      numeric
                      disabled={criteriaEnable}
                      error={errors?.criteria?.maximum_rv?.message as never}
                    ></TextBox>
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewRvCharts;
