import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ChevronDown from "../../shared/assets/images/chevron-down";
import {
  Button,
  Switch,
  Box,
  Input,
  Icon,
  Menu,
  DataTable,
} from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";

import { styled } from "@mui/material/styles";
import { ActionOptions, DialogMessages } from "Enums";
import {
  UseMutation_UpdateAssetUsage,
  UseMutation_DeleteAssetUsage,
  UseQuery_Asset_Usages_filter,
} from "services";
import { IAssetUsage, IAssetUsageFilter } from "Interface";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const select = [{ text: "Select", value: "Select" }];

const AssetUsageTable: FC<{
  onEdit: (data: IAssetUsage) => unknown;
}> = ({ onEdit }) => {
  const [popUpState, setPopUpState] = useState(false);
  const theme = useTheme();

  interface IFormInputs {
    code: string;
  }

  const [codeFilter, setCodeFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_Asset_Usages_filter(columnFilters);

  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const AssetUsageFilter: IAssetUsageFilter = data;
  const [actionData, setActionData] = useState<IAssetUsage>(undefined);
  const { mutate: deleteUsage } = UseMutation_DeleteAssetUsage();
  const { mutate: UpdateAssetUsage } = UseMutation_UpdateAssetUsage(undefined);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&code=${codeFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteUsage(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };
  const handleEnableChange = (data: IAssetUsage, event) => {
    UpdateAssetUsage({ ...data, is_active: event.target.checked });
  };
  const { control, setValue, clearErrors } = useForm<IFormInputs>();

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCodeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={codeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setDescriptionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={descriptionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          AssetUsageFilter?.result &&
          AssetUsageFilter.result.map((AssetUsage, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell>{AssetUsage?.code}</TableCell>
              <TableCell>{AssetUsage?.description}</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={"basic"}
                  switchEnabled={AssetUsage?.is_active}
                  onChange={(event) => handleEnableChange(AssetUsage, event)}
                  label={AssetUsage.is_active ? "Enabled" : "Disabled"}
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  disablePortal
                  theme={theme}
                  options={tableOptions.map((option) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: AssetUsage,
                    };
                  })}
                  handleOptionClick={handleSelection}
                  render={(onMenuSelection) => (
                    <Button
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    />
                  )}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={AssetUsageFilter ? AssetUsageFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default AssetUsageTable;
