import { FC, useEffect, useState } from "react";
import { SliderValueLabel, useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { useStoreContext } from "../Stores/SetupStore/Store";
import {
  UpdateUserProfileComponent,
  ChangePasswordComponent,
} from "@ntpkunity/controls-ums";
import { styled } from "@mui/material/styles";
import { ToastMessages } from "Enums";

const UserProfileWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  display: "table",
  margin: "10vh auto",
  maxWidth: 464,

  ".section": {
    marginBottom: 40,
    paddingBottom: 32,
    borderBottom: "1px solid" + theme.palette.divider,
    display: "inline-block",
  },
}));

const UserProfile: FC = () => {
  const { actions } = useStoreContext();
  const [profileResponse, setProfileResponse] = useState<any>(undefined);
  const [changePasswordResponse, setChangePasswordResponse] =
    useState<any>(undefined);

  useEffect(() => {
    if (profileResponse?.status == 200) {
      actions.setToast({
        toastMessage: ToastMessages.PROFILE_UPDATED_SUCCESS,
        toastState: true,
      });
    } else if (profileResponse?.status) {
      actions.setToast({
        toastMessage: profileResponse?.message,
        toastState: true,
        variant: "error",
      });
    }
  }, [profileResponse]);
  useEffect(() => {
    if (changePasswordResponse?.status == 200) {
      actions.setToast({
        toastMessage: ToastMessages.CHANGE_PASSWORD_SUCCESS,
        toastState: true,
      });
    } else if (changePasswordResponse?.status) {
      actions.setToast({
        toastMessage: changePasswordResponse?.message,
        toastState: true,
        variant: "error",
      });
    }
  }, [changePasswordResponse]);

  const theme = useTheme();

  return (
    <LayoutWithSideNav theme={theme}>
      <UserProfileWrap theme={theme}>
        <Box theme={theme} className="profile">
          <Box className="section" theme={theme}>
            <UpdateUserProfileComponent
              theme={theme}
              setResponse={setProfileResponse}
              layoutText={{
                title: "Your Profile",
                lastNameFieldLabel: "Last Name (Optional)",
                contactNumFieldLabel: "Contact Number (Optional)",
              }}
            />
          </Box>
          <Box className="password" theme={theme}>
            <ChangePasswordComponent
              theme={theme}
              setResponse={setChangePasswordResponse}
              next_screen_url={""}
            />
          </Box>
        </Box>
      </UserProfileWrap>
    </LayoutWithSideNav>
  );
};
export default UserProfile;
