export enum ActionOptions {
  ADD = "Add",
  EDIT = "Edit",
  DELETE = "Delete",
  ADD_NEW_CHART = "Add New Chart",
  RENAME_CHART = "Rename Chart",
  ADD_CRITERIA = "Add Criteria",
  VIEW = "View",
  SAVE_AS = "Save As",
  CANCEL = "Cancel",
  SAVE_AS_DRAFT = "Save As Draft",
  ADD_MODEL = "Add Model",
  ADD_TRIM = "Add Trim",
  UPLOAD_FILE = "Upload File",
  DOWNLOAD_INVENTORY_FILE = "Download Template",
  USER_PROFILE = "MyProfile",
  CHANGE_PASSWORD = "ChangePassword",
  LOGOUT = "Logout",
  ADD_PROVIDER = "Add Provider",
  ADD_VEHICLE = "Add Vehicle",
  BULK_UPDATE = "Bulk Update",
  PROVIDER_MAPPING = "Provider Mapping",
}
