import { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme, TablePagination } from "@mui/material";
import { Icon, Menu, DataTable, Button, Input, Box } from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";
import ChevronDown from "../../shared/assets/images/chevron-down";
import {
  UseQuery_getAllStipulations,
  UseMutation_DeleteStipulation,
  UseQuery_getAllStipulations_filter,
  UseQuery_getAllDocumentTypes,
} from "services";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { IDocumentTypes, IStipulation, IStipulationFilter } from "Interface";
import { useQueryClient } from "react-query";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const StipulationTable: FC<{ onEdit: (data: IStipulation) => unknown }> = ({
  onEdit,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllStipulations_filter(columnFilters);

  const queryClient = useQueryClient();
  UseQuery_getAllDocumentTypes();
  const documentTypes: Array<IDocumentTypes> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DOCUMENT_TYPES
  );
  const stipulationFilter: IStipulationFilter = data;
  const [actionData, setActionData] = useState<IStipulation>(undefined);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [codeFilter, setCodeFilter] = useState("");
  const [stpTypeFilter, setStpTypeFilter] = useState("");
  const [parameterFilter, setParameterFilter] = useState("");
  const [documentTypeFilter, setDocumentTypeFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const { mutate: deleteStipulation } = UseMutation_DeleteStipulation();
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (stpTypeFilter.trimStart() != null && stpTypeFilter.trimStart() != "") {
      query_string = query_string.concat(`&stipulation_type=${stpTypeFilter}`);
    }
    if (
      parameterFilter.trimStart() != null &&
      parameterFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&parameter=${parameterFilter}`);
    }
    if (
      documentTypeFilter.trimStart() != null &&
      documentTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&document_type_id=${documentTypeFilter}`
      );
    }
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&stipulation_code=${codeFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    return query_string;
  };
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };
  const onDeleteConfirm = (e: any) => {
    deleteStipulation(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow className="thead-items-cell">
              <TableCell>Stipulation Code</TableCell>
              <TableCell>Stipulation Type</TableCell>
              <TableCell>Parameter</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell>Message Description</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={"text"}
                    startAdornment={""}
                    endAdornment={""}
                    id={"stipulationCode"}
                    placeholder="Search"
                    onChange={(value) => {
                      setCodeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={codeFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="stipulationType"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStpTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={stpTypeFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="parameter"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setParameterFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={parameterFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="documentType"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setDocumentTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={documentTypeFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="messageDescription"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setDescriptionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={descriptionFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {stipulationFilter?.result &&
              stipulationFilter.result?.map((stipulation, index) => (
                <TableRow className="child-tr" key={index}>
                  <TableCell>{stipulation.stipulation_code}</TableCell>
                  <TableCell>{stipulation.stipulation_type}</TableCell>
                  <TableCell>{stipulation.parameter}</TableCell>
                  <TableCell>
                    {documentTypes?.find(
                      (x) => x.id == stipulation.document_type_id
                    )?.description ?? "-"}
                  </TableCell>
                  <TableCell>{stipulation.description}</TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      disablePortal
                      options={tableOptions.map((option) => {
                        return {
                          optionText: option.optionText,
                          optionkey: option.optionkey,
                          optionValue: stipulation,
                        };
                      })}
                      handleOptionClick={handleSelection}
                      render={(onMenuSelection) => (
                        <Button
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={stipulationFilter ? stipulationFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default StipulationTable;
