import { useReducer } from "react";
import themeReducer, {
  initialState as themeInitialState,
  updateThemeConfig,
} from "./ducks/theme";
import storageReducer, {
  initialState as storageInitialState,
  updateLocalStorage,
  updateSessionStorage,
} from "./ducks/browserStorage";
import {
  IAddOns,
  IDealerFee,
  IFinancialInsurance,
  IInventory,
  IOemOption,
  IPaginatedResponse,
  IPaginatedResponse_AddOns,
  IPaginatedResponse_OemOptions,
  IToast,
} from "Interface";
import inventoryReducer, {
  initialState as inventoryInitialState,
  updateInventoryData,
  updateInventoryDataRow,
} from "./ducks/inventory";
import ToastReducer, {
  initialState as toastInitialState,
  setToast,
} from "./ducks/toast";

import optionReducer, {
  addOptionsDataRow,
  deleteOptionsDataRow,
  initialState as optionsInitialState,
  updateOptionsData,
  updateOptionsDataRow,
} from "./ducks/options";
import oemoptionReducer, {
  addOemOptionsDataRow,
  deleteOemOptionsDataRow,
  initialState as OemoptionsInitialState,
  updateOemOptionsData,
  updateOemOptionsDataRow,
} from "./ducks/oem-options";
import dealerFeeReducer, {
  deleteDealerFeeDataRow,
  initialState as dealerFeeInitialState,
  updateDealerFeeData,
  updateDealerFeeDataRow,
  AddDealerFeeDataRow,
} from "./ducks/dealer-fee";
import financialInsuranceReducer, {
  setFinancialInsuranceProductData,
  setFinancialInsuranceInitialData,
  initialState as FIProductInitialState,
  AddFinancialInsuranceDataRow,
  updateFinancialInsuranceDataRow,
  deleteFinancialInsuranceDataRow,
} from "./ducks/financial-insurance";

import { StoreContextType } from "./configs";

const useReducerActions = (): StoreContextType => {
  const [theme, themeDispatch] = useReducer(themeReducer, themeInitialState);
  const [browserStorage, stroageDispatch] = useReducer(
    storageReducer,
    storageInitialState
  );
  const [inventoryData, inventoryDispatch] = useReducer(
    inventoryReducer,
    inventoryInitialState
  );

  const [toastData, toastDispatch] = useReducer(
    ToastReducer,
    toastInitialState
  );
  const [optionData, optionDispatch] = useReducer(
    optionReducer,
    optionsInitialState
  );
  const [oemoptionData, oemoptionDispatch] = useReducer(
    oemoptionReducer,
    OemoptionsInitialState
  );
  const [dealerFeeData, dealerFeeDispatch] = useReducer(
    dealerFeeReducer,
    dealerFeeInitialState
  );
  const [financialInsuranceProductData, financialInsuranceDispatch] =
    useReducer(financialInsuranceReducer, FIProductInitialState);
  const [financialInsuranceInitialData, financialInsuranceInitialDataDispatch] =
    useReducer(financialInsuranceReducer, FIProductInitialState);
  const actions = {
    setTheme: (data: "default" | "dark") => {
      updateThemeConfig(data, themeDispatch);
    },
    setLocalStorage: (data: string) =>
      updateLocalStorage(data, stroageDispatch),
    setSessionStorage: (data: string) =>
      updateSessionStorage(data, stroageDispatch),
    setAssetInventoryInitialData: (data: IPaginatedResponse) =>
      updateInventoryData(data, inventoryDispatch),
    updateInventoryRow: (data: IInventory) =>
      updateInventoryDataRow(data, inventoryDispatch),
    setToast: (data: IToast) => setToast(data, toastDispatch),
    setOptionsInitialData: (data: IPaginatedResponse_AddOns) =>
      updateOptionsData(data, optionDispatch),
    updateOptionsRow: (data: IAddOns) =>
      updateOptionsDataRow(data, optionDispatch),
    deleteOptionsRow: (data: IAddOns) =>
      deleteOptionsDataRow(data, optionDispatch),
    addOptionsRow: (data: IAddOns) => addOptionsDataRow(data, optionDispatch),
    setOemOptionsInitialData: (data: IPaginatedResponse_OemOptions) =>
      updateOemOptionsData(data, oemoptionDispatch),
    updateOemOptionsRow: (data: IOemOption) =>
      updateOemOptionsDataRow(data, oemoptionDispatch),
    deleteOemOptionsRow: (data: IOemOption) =>
      deleteOemOptionsDataRow(data, oemoptionDispatch),
    addOemOptionsRow: (data: IOemOption) =>
      addOemOptionsDataRow(data, oemoptionDispatch),
    setDealerFeeInitialData: (data: IDealerFee[]) =>
      updateDealerFeeData(data, dealerFeeDispatch),
    deleteDealerFeeRow: (data: IDealerFee) =>
      deleteDealerFeeDataRow(data, dealerFeeDispatch),
    updateDealerFeeRow: (data: IDealerFee) =>
      updateDealerFeeDataRow(data, dealerFeeDispatch),
    addDealerFeeRow: (data: IDealerFee) =>
      AddDealerFeeDataRow(data, dealerFeeDispatch),
    setFinancialInsuranceProductData: (data: any) =>
      setFinancialInsuranceProductData(data, financialInsuranceDispatch),
    setFinancialInsuranceInitialData: (data: any) =>
      setFinancialInsuranceInitialData(
        data,
        financialInsuranceInitialDataDispatch
      ),
    addFinancialInsuranceRow: (data: IFinancialInsurance) =>
      AddFinancialInsuranceDataRow(data, financialInsuranceInitialDataDispatch),
    updateFinancialInsuranceRow: (data: IFinancialInsurance) =>
      updateFinancialInsuranceDataRow(
        data,
        financialInsuranceInitialDataDispatch
      ),
    deleteFinancialInsuranceRow: (data: IFinancialInsurance) =>
      deleteFinancialInsuranceDataRow(
        data,
        financialInsuranceInitialDataDispatch
      ),
  };

  return {
    actions,
    states: {
      theme,
      localStorage: browserStorage.localStorage,
      sessionStorage: browserStorage.sessionStorage,
      inventoryData: inventoryData,
      toastData: toastData,
      optionData: optionData,
      oemoptionData: oemoptionData,
      dealerFeeData: dealerFeeData,
      financialInsuranceProductData: financialInsuranceProductData,
      financialInsuranceInitialData: financialInsuranceInitialData,
    },
  };
};

export default useReducerActions;
