import { StoreActionType } from "Types";
import { ITerms } from "Interface";
import { ActionTypes } from "Enums";

export const TermsReducer = (
  state: Array<ITerms> = [],
  action: StoreActionType
): Array<ITerms> => {
  switch (action.type) {
    case ActionTypes.SET_TERMS_INITIAL_DATA:
      return [...action.payload];

    default:
      return state;
  }
};
