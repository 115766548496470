import IActions from "../configs";
import { Dispatch, ReducerAction } from "react";

import { IOemOption, IPaginatedResponse_OemOptions } from "Interface";

export const SET_OEM_OPTIONS_INIT_STATE = "GET OEM OPTIONS DATA FROM SERVER";
export const UPDATE_OEM_OPTIONS_ROW = "UPDATE_OEM_OPTIONS_ROW";
export const DELETE_OEM_OPTIONS_ROW = "DELETE_OEM_OPTIONS_ROW";
export const ADD_OEM_OPTIONS_ROW = "ADD_OEM_OPTIONS_ROW";
export const initialState: IPaginatedResponse_OemOptions = {
  pageNum: 0,
  pageSize: 0,
  result: [],
  totalResults: 0,
};

const oemoptionReducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case SET_OEM_OPTIONS_INIT_STATE:
      return action.payload as typeof initialState;
    case UPDATE_OEM_OPTIONS_ROW: {
      const index = state.result.findIndex(
        (option) => option.id === (action.payload as IOemOption).id
      );
      state.result[index] = action.payload as any;
      return state;
    }
    case DELETE_OEM_OPTIONS_ROW: {
      const index = state.result.findIndex(
        (option) => option.id === (action.payload as IOemOption).id
      );
      state.result.splice(index, 1) == (action.payload as any);
      return state;
    }
    case ADD_OEM_OPTIONS_ROW: {
      state.result.unshift(action.payload as any);
      return state;
    }
  }
  return state;
};

export const updateOemOptionsData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof oemoptionReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_OEM_OPTIONS_INIT_STATE, payload: data });
  }
};

export const updateOemOptionsDataRow = (
  data: IOemOption,
  dispatch: Dispatch<ReducerAction<typeof oemoptionReducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_OEM_OPTIONS_ROW, payload: data });
  }
};
export const deleteOemOptionsDataRow = (
  data: IOemOption,
  dispatch: Dispatch<ReducerAction<typeof oemoptionReducer>>
): void => {
  if (data) {
    dispatch({ type: DELETE_OEM_OPTIONS_ROW, payload: data });
  }
};
export const addOemOptionsDataRow = (
  data: IOemOption,
  dispatch: Dispatch<ReducerAction<typeof oemoptionReducer>>
): void => {
  if (data) {
    dispatch({ type: ADD_OEM_OPTIONS_ROW, payload: data });
  }
};
export default oemoptionReducer;
