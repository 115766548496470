import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { setCurrencyInitialData } from "Stores/SetupStore/Actions";

export const getAll_CURRENCIES_FromServer = () => {
  return ConfigurationAPI.get("/currencies").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAll_CURRENCIES = () => {
  const [, dispatch] = useSetupsStore();
  return useQuery(QueryKeys.GET_ALL_CURRENCIES, async () => {
    return getAll_CURRENCIES_FromServer().then((data) => {
      dispatch(setCurrencyInitialData(data));
      return data;
    });
  });
};
