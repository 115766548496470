import { StoreActionType } from "Types";
import { IDocumentTypes } from "Interface";
import { ActionTypes } from "Enums";

export const DocumentTypeReducer = (
  document_type: Array<IDocumentTypes> = [],
  action: StoreActionType
): Array<IDocumentTypes> => {
  switch (action.type) {
    case ActionTypes.SET_DOCUMENT_TYPES_INITIAL_DATA:
      return [...action.payload];
    default:
      return document_type;
  }
};
