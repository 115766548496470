import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";

export const getActiveLanguages = () => {
  return ConfigurationAPI.get("/get-active-languages").then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllActiveLanguages = () => {
  return useQuery(QueryKeys.GET_ALL_ACTIVE_LANGUAGES, async () => {
    return getActiveLanguages().then((data) => {
      return data;
    });
  });
};

export const getTenantLanguages = () => {
  return ConfigurationAPI.get("/get-tenant-languages").then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllTenantLanguages = () => {
  return useQuery(QueryKeys.GET_ALL_TENANT_LANGUAGES, async () => {
    return getTenantLanguages().then((data) => {
      return data;
    });
  });
};

export const getLenderLanguagesandTranslations = (columnFilters?: string) => {
  return ConfigurationAPI.get(
    `/get-lender-languages-and-translations/filter?${columnFilters}`
  ).then((res) => {
    return res.data;
  });
};

export const UseQuery_getLanguagesAndTranslations = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_LENDER_LANGUAGES_AND_TRANSLATIONS, columnFilters],
    async () => {
      return getLenderLanguagesandTranslations(columnFilters).then((data) => {
        return data;
      });
    }
  );
};

export const useMutation_AddNewLenderLanguage = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<any>(
    (assetModel) => {
      return ConfigurationAPI.patch("/lender-language", assetModel);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_LENDER_LANGUAGES_AND_TRANSLATIONS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TENANT_LANGUAGES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddPlaceholderAndTranslation = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<any>(
    (assetModel) => {
      return ConfigurationAPI.patch(
        "/lender-placeholder-and-translations",
        assetModel
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_LENDER_LANGUAGES_AND_TRANSLATIONS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeletePlaceholder = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<any>(
    (placeholderId) => {
      return ConfigurationAPI.delete(`/lender-placeholder/${placeholderId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_LENDER_LANGUAGES_AND_TRANSLATIONS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
