import { StoreActionType } from "Types";
import { ICurrencies } from "Interface";
import { ActionTypes } from "Enums";

export const CurrencyReducer = (
  currencies: Array<ICurrencies> = [],
  action: StoreActionType
): Array<ICurrencies> => {
  switch (action.type) {
    case ActionTypes.SET_CURRENCIES_INITIAL_DATA:
      return [...action.payload];
    default:
      return currencies;
  }
};
