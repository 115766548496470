import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IContractTermsFilter, ICreditRating } from "Interface";

export const getAllTermsFromServer = () => {
  return ConfigurationAPI.get("/contract-terms").then((res) => {
    return res.data;
  });
};

export const getAllContractTermsFilterFromServer = (columnFilters?: string) => {
  return ConfigurationAPI.get(
    `/contract-term/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllCreditRatings = () => {
  return useQuery(QueryKeys.GET_ALL_CREDIT_RATINGS, async () => {
    return getAllTermsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getAllContractTerms_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_CONTRACT_TERMS_FILTER, columnFilters],
    async () => {
      return getAllContractTermsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddContractTerms = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ICreditRating>(
    (contractTerms) => {
      return ConfigurationAPI.post(
        "/contract-term/create-contract-term",
        contractTerms
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CONTRACT_TERMS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateContractTerms = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IContractTermsFilter>(
    (contractTerms: any) => {
      return ConfigurationAPI.patch(
        `/contract-term/${contractTerms.id}`,
        contractTerms
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CONTRACT_TERMS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteContractTerms = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ICreditRating>(
    (contractTermsId) => {
      return ConfigurationAPI.delete(`/contract-term/${contractTermsId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CONTRACT_TERMS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
