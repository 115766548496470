import { StoreActionType } from "Types";
import { ICreditRating, IFeeName } from "Interface";
import { ActionTypes } from "Enums";

export const FeeNameReducer = (
  state: Array<IFeeName> = [],
  action: StoreActionType
): Array<IFeeName> => {
  switch (action.type) {
    case ActionTypes.SET_FEE_NAMES_INITIAL_DATA:
      return [...action.payload];

    case ActionTypes.UPDATE_FEE_NAMES:
      const indexToUpdate = state.findIndex(
        (fee_name) => fee_name.id == action.payload?.id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
