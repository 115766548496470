import { ICurrencies } from "Interface";
import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";

export const setCurrencyInitialData = (
  currencies: Array<ICurrencies>
): StoreActionType => {
  return {
    type: ActionTypes.SET_CURRENCIES_INITIAL_DATA,
    payload: currencies,
  };
};
