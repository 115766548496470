import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const TextAreaWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  width: "100%",
  display: "inline-block",
  ".u-form-group": {
    ".MuiFormControl-root": {
      ".u-form-control": {
        "&.textarea": {
          ".MuiInputBase-root": {
            borderRadius: 8,
            height: 72,

            textarea: {
              height: "50px !important",
            },
          },
        },
      },
    },
    "&:last-of-type": {
      marginBottom: "24px !important",
    },
  },
  "&.mb-0": {
    ".u-form-group": {
      "&:last-of-type": {
        marginBottom: "0 !important",
      },
    },
  },
  "&.full-drawer-height": {
    ".u-form-group .MuiFormControl-root .u-form-control.textarea .MuiInputBase-root":
      {
        height: "calc(100vh - 295px)",
        textarea: {
          height: "100% !important",
        },
      },
  },
  "&.chat-wrap": {
    ".u-form-group": {
      marginBottom: 5,
      ".MuiFormControl-root .u-form-control.textarea .MuiInputBase-root": {
        height: 100,
        textarea: {
          height: "100% !important",
        },
      },
    },
    ".counter": {
      color: theme.palette.grey[500],
    },
  },
}));
