import Http from "shared/helper/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  EP_GET_DEALER_FEE,
  EP_DEALER_PREFERENCE,
  EP_GET_FEES,
  EP_DEALER_PREFERENCE_BY_DEALER_CODE,
  EP_SAVE_DEALER_PROFILE,
  EP_SAVE_DEALER_CHAT,
  EP_DEALER_TRADEIN,
  EP_DEALER_PAYMENT,
  EP_DEALER_PAYMENT_BY_DEALER_CODE,
  EP_DEALER_TRADEIN_BY_DEALER_CODE,
  EP_SAVE_PEN_DEALER_ID,
  EP_DEALER_CONFIGURATIONS,
  EP_GET_STATES,
  EP_GET_CONFIG_ALLOWED_MILEAGE,
  EP_CONTRACT_ALLOWED_MILEAGE,
  EP_GET_CONFIG_CONTRACT_TEMRS,
  EP_SAVE_SHIFT_DIGITAL_DEALER_ID,
  EP_GET_All_DEALERS,
  EP_DEALER_BY_DEALER_ID,
  EP_SAVE_STRIPE_ACCOUNT_ID,
  EP_DEALER_PROFILE_BY_DEALER_ID,
  EP_UNREGISTER_WITH_PEN,
  EP_GET_DEALER_INDIVIDUALIZED_AGREEMENT,
  EP_DEALER_INDIVIDUALIZED_AGREEMENT,
  EP_DEALER_CUSTOM_LINK,
  EP_DEALER_CHAT_BY_DEALER_CODE,
} from "shared/config/endpoints";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ConfigurationAPI } from "services";
import {
  IDealerPreference,
  IDealerProfile,
  IDealerTradeIn,
  IDealerPayment,
  IDealerConfigurations,
  IDealerCustomLink,
  IDealerChat,
} from "Interface";
import { QueryKeys, ToastMessages } from "Enums";

export const useGetDealerFee = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(EP_GET_DEALER_FEE);
    },
    {
      onSuccess(_response: any) {
        actions.setDealerFeeInitialData(_response);
      },
    }
  );
  return { data, isLoading, error, mutate };
};
export const useGetDealerFeeByDealerId = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`dealer/fee/dealer-id/${body.id}`);
    },
    {
      onSuccess(_response: any) {
        actions.setDealerFeeInitialData(_response);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteDealerFeeById = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(`${EP_GET_DEALER_FEE}/${body.id}`);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FEE_FILTER);
      },
    }
  );
};
export const useUpdateDealerFeeById = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<
    {
      id: number;
      fee_name: string;
      taxable: string;
      applicable_finance_type: string[];
      default_amount: string;
      states: string;
      dealer_id: string;
    },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_GET_DEALER_FEE}/${body.id}`, body);
    },
    {
      onSuccess(data) {
        // const key = [QueryKeys.GET_ALL_DEALER_FEE_FILTER, data.dealer_id];
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FEE_FILTER);

        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useGetDealerFeeById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_GET_DEALER_FEE}/${body.id}`);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerPreference = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPreference, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<IDealerPreference>(
        `${EP_DEALER_PREFERENCE}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useGetPreferenceByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(
        `${EP_DEALER_PREFERENCE_BY_DEALER_CODE}/${body.id}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerPreference = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPreference, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IDealerPreference>(
        `${EP_DEALER_PREFERENCE}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetPreference = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(EP_DEALER_PREFERENCE);
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetFees = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(EP_GET_FEES);
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetTradeInByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(
        `${EP_DEALER_TRADEIN_BY_DEALER_CODE}/${body.id}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerTradeIn = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerTradeIn, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<IDealerTradeIn>(`${EP_DEALER_TRADEIN}`, body);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerTradeIn = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerTradeIn, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IDealerTradeIn>(
        `${EP_DEALER_TRADEIN}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerProfile = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<IDealerProfile>(`${EP_SAVE_DEALER_PROFILE}`, body);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useGetProfilebyDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_SAVE_DEALER_PROFILE}/${body.id}`);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetProfilebyDealerCode = (): any => {
  const { data, isLoading, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(
        `${EP_SAVE_DEALER_PROFILE}/${body.dealer_code}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerProfilebyId = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IDealerProfile>(
        `${EP_SAVE_DEALER_PROFILE}/${body.id}`,
        body
      );
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
          response?.dealer_id,
        ]);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdatePenDealerId = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IDealerProfile>(
        `${EP_SAVE_PEN_DEALER_ID}/${body.dealer_code}/${body.pen_dealer_id}`,
        body
      );
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
          response?.dealer_id,
        ]);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerPayment = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPayment, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<IDealerPayment>(`${EP_DEALER_PAYMENT}`, body);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetPaymentByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(
        `${EP_DEALER_PAYMENT_BY_DEALER_CODE}/${body.id}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerPaymentById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_DEALER_PAYMENT}/${body.id}`, body);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerConfigurations = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerConfigurations, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<IDealerConfigurations>(
        `${EP_DEALER_CONFIGURATIONS}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useGetStates = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(EP_GET_STATES);
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, error, mutate };
};

export const getAllStatesFromServer = () => {
  const apiService = Http.createConnection();
  return apiService.get(EP_GET_STATES).then((res) => {
    return res;
  });
};

export const UseQuery_getAllStates = () => {
  return useQuery(QueryKeys.GET_ALL_STATES, async () => {
    return getAllStatesFromServer().then((data) => {
      return data;
    });
  });
};

export const useGetContractTerms = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>(() => {
    const apiService = Http.createConnection();
    return apiService.get<any>(EP_GET_CONFIG_CONTRACT_TEMRS);
  });
  return { data, isLoading, error, mutate };
};

export const useGetAllowedMileage = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>(() => {
    const apiService = Http.createConnection();
    return apiService.get<any>(EP_CONTRACT_ALLOWED_MILEAGE);
  });
  return { data, isLoading, error, mutate };
};

export const useSaveDealerFee = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<
    {
      id?: number;
      fee_name: string;
      state_id: number[];
      applicable_finance_type: string[];
      default_amount: number;
      taxable: boolean;
      is_active: boolean;
      dealer_id: number;
    },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_GET_DEALER_FEE}`, body);
    },
    {
      onSuccess(data) {
        // const key = [QueryKeys.GET_ALL_DEALER_FEE_FILTER, data.dealer_id];
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FEE_FILTER);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateshiftDigitalDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `${EP_SAVE_SHIFT_DIGITAL_DEALER_ID}/${body.dealer_code}/${body.shift_dealer_code}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetDealerByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_DEALER_BY_DEALER_ID}/${body.dealer_id}`);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const getAllDealerFeeFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/dealer/fees/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllDealerFee_filter = (
  // dealer_id: number,
  columnFilters: string
) => {
  const key = [QueryKeys.GET_ALL_DEALER_FEE_FILTER, columnFilters];
  return useQuery(
    key,
    async () => {
      return getAllDealerFeeFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useUpdateStripeConnectId = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IDealerProfile>(
        `${EP_SAVE_STRIPE_ACCOUNT_ID}/${body.dealer_code}/${body.stripe_connect_id}`,
        body
      );
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
          response?.dealer_id,
        ]);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useGetDealerProfileByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(
        `${EP_DEALER_PROFILE_BY_DEALER_ID}/${body.dealer_id}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useUnregisterDealerPen = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation(
    (dealer_id) => {
      const apiService = Http.createConnection();
      return apiService.patch(`${EP_UNREGISTER_WITH_PEN}/${dealer_id}`);
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
          response?.dealer_id,
        ]);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useGetIndividualizedAgreementByDealerCode = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(
        `${EP_GET_DEALER_INDIVIDUALIZED_AGREEMENT?.replace(
          "{{dealer_code}}",
          body.id
        )}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useAddIndividualizedAgreement = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<any>(
        `${EP_DEALER_INDIVIDUALIZED_AGREEMENT}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateIndividualizedAgreement = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      const { id, ...payload } = body;
      const apiService = Http.createConnection();
      return await apiService.patch<any>(
        `${EP_DEALER_INDIVIDUALIZED_AGREEMENT}/${body.id}`,
        payload
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteIndividualizedAgreement = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `${EP_DEALER_INDIVIDUALIZED_AGREEMENT}/${body.id}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerCustomLink = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerCustomLink, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      const linkId = body?.id;
      if (linkId && linkId !== undefined) {
        return apiService.patch<IDealerCustomLink>(
          `${EP_DEALER_CUSTOM_LINK}/${linkId}`,
          body
        );
      }
      return apiService.post<IDealerCustomLink>(
        `${EP_DEALER_CUSTOM_LINK}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteCustomLink = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `${EP_DEALER_CUSTOM_LINK}/${body.dealer_code}/${body.id}`
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealerChat = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerChat, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<IDealerChat>(`${EP_SAVE_DEALER_CHAT}`, body);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerChatbyId = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IDealerChat, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IDealerChat>(
        `${EP_SAVE_DEALER_CHAT}/${body.id}`,
        body
      );
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_DEALER_CHAT,
          response?.dealer_id,
        ]);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useGetDealerChatByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_DEALER_CHAT_BY_DEALER_CODE}/${body.id}`);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const UseQuery_GetAllDealerChat = () => {
  return useQuery(QueryKeys.GET_ALL_DEALER_CHAT, async () => {
    return useGetDealerChatByDealerId().then((data) => {
      return data;
    });
  });
};
