export enum AssetButtonGroupOptions {
  ADD_NEW_MAKE = "Add New Make",
  ADD_NEW_MODEL = "Add New Model",
  ADD_NEW_TRIM = "Add New Trim",
  DOWNLOAD_TEMPLATE = "Download Template",
  UPLOAD_FILE = "Upload File",
}

export enum DecisionStatusOptions {
  ADD_NEW_DECISION_STATUS = "Add New Decision Status",
  ADD_NEW_STIPULATION = "Add New Stipulation",
}
