import { FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { useTheme } from "@mui/material";
import { Autocomplete, Grid, Tooltip, Typography } from "@ntpkunity/controls";
import { CustomGrid, PageHeader } from "@sharedComponents";
import { IMetricsAssociationProps } from "./metrics-association.type";
import { Controller } from "react-hook-form";
import { ICharts, ISelectItemList } from "Interface";
import { ActionOptions, ChartTypes, QueryKeys, ActionTypes } from "Enums";
import { useQueryClient } from "react-query";

const MetricsAssociation: FC<IMetricsAssociationProps> = ({
  actionType,
  control,
  watch,
  errors,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const charts: Array<ICharts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CHARTS
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [assetinterestChartsSelection, setAssetInterestChartsSelection] =
    useState<Array<ISelectItemList>>([]);

  const [rvChartsSelection, setRvChartsSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [commissionSelection, setCommissionSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [subsidySelection, setSubsidySelection] = useState<
    Array<ISelectItemList>
  >([]);
  const [downPaymentSelection, setDownPaymentSelection] = useState<
    Array<ISelectItemList>
  >([]);
  const [baseRateSelection, setBaseRateSelection] = useState<
    Array<ISelectItemList>
  >([]);
  const [isCommissionDisabled, setIsCommissionDisabled] =
    useState<boolean>(false);
  const [isSubsidyDisabled, setIsSubsidyDisabled] = useState<boolean>(false);
  const assetInterestChartId = watch(
    "financial_product_charts_association.interest_chart_id"
  )?.id;
  const rvChartId = watch(
    "financial_product_charts_association.rv_chart_id"
  )?.id;
  const commissionId = watch(
    "financial_product_charts_association.program_commission"
  )?.id;
  const subsidyId = watch(
    "financial_product_charts_association.program_subsidy"
  )?.id;
  const downPaymentId = watch(
    "financial_product_charts_association.down_payment"
  )?.id;
  const baseRateChartId = watch(
    "financial_product_charts_association.base_rate_chart"
  )?.id;
  const rvBalloonApplicable = watch(
    "financial_product_financial_parameter.rv_balloon_applicable"
  );
  const programCommission = watch("program_commission");
  const programSubsidy = watch("program_subsidy");
  const subsidyCalculationMethod = watch("program_subsidy.calculation_method");
  const mapChartsData = (
    setCharts: Dispatch<SetStateAction<Array<ISelectItemList>>>,
    chartId: number,
    chartType: number
  ) => {
    setCharts(
      charts
        ?.filter(
          (x) =>
            x.chart_type_id == chartType && (x.is_active || x.id == chartId)
        )
        ?.map((chart) => ({
          value: chart.id,
          text: chart.chart_name,
        }))
    );
  };
  useEffect(() => {
    if (
      programCommission?.commission_type &&
      programCommission?.amortization_method &&
      programCommission?.commission_override
    ) {
      setIsCommissionDisabled(false);
    } else {
      setIsCommissionDisabled(true);
    }
  }, [programCommission]);
  useEffect(() => {
    if (
      programSubsidy?.calculation_method &&
      programSubsidy?.amortization_method &&
      programSubsidy?.subsidy_override &&
      programSubsidy?.tax_amortization
    ) {
      setIsSubsidyDisabled(false);
    } else {
      setIsSubsidyDisabled(true);
    }
  }, [programSubsidy]);
  useEffect(() => {
    if (charts) {
      mapChartsData(setRvChartsSelection, rvChartId, ChartTypes.RvChart);
      mapChartsData(
        setCommissionSelection,
        commissionId,
        ChartTypes.CommissionChart
      );
      mapChartsData(
        setDownPaymentSelection,
        downPaymentId,
        ChartTypes.DownPaymentChart
      );
    }
  }, [charts, rvChartId, commissionId, downPaymentId]);

  useEffect(() => {
    setSubsidySelection(
      charts
        ?.filter(
          (x) =>
            x.chart_type_id == ChartTypes.SubsidyChart &&
            x.output_parameter_type == subsidyCalculationMethod &&
            (x.is_active || x.id == subsidyId)
        )
        ?.map((chart) => ({
          value: chart.id,
          text: chart.chart_name,
        }))
    );
  }, [subsidyId, charts, subsidyCalculationMethod]);
  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    }
  }, [actionType]);
  useEffect(() => {
    setAssetInterestChartsSelection(
      charts
        ?.filter(
          (x) =>
            x.chart_type_id == ChartTypes.InterestChart &&
            (x.is_active || x.id == assetInterestChartId)
        )
        ?.map((chart) => ({
          value: chart.id,
          text: chart.chart_name,
        }))
    );
  }, [charts, assetInterestChartId]);
  useEffect(() => {
    const baseRateChart = charts?.find(
      (x) =>
        x.chart_type_id == ChartTypes.BaseRateChart &&
        (x.is_active || x.id == baseRateChartId)
    );
    setBaseRateSelection(
      baseRateChart
        ? [{ value: baseRateChart.id, text: "Base Rate" }]
        : [{ value: "", text: "" }]
    );
  }, [charts, baseRateChartId]);
  return (
    <>
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={3}
        sx={{ mb: "24px !important" }}
      >
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_charts_association.base_rate_chart"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                theme={theme}
                disabled={isDisabled}
                disablePortal={true}
                capitalizeLabel={true}
                items={
                  baseRateSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                }}
                label={"Base Rate Chart"}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_charts_association.interest_chart_id"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                theme={theme}
                disabled={isDisabled}
                disablePortal={true}
                capitalizeLabel={true}
                items={
                  assetinterestChartsSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                }}
                label={"Margin Rate"}
                value={field.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
                error={
                  errors?.financial_product_charts_association
                    ?.interest_chart_id?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_charts_association.rv_chart_id"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Autocomplete
                {...field}
                theme={theme}
                disabled={!rvBalloonApplicable || isDisabled}
                disablePortal={true}
                capitalizeLabel={true}
                items={
                  rvChartsSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                }}
                label={"RV/Balloon"}
                value={
                  rvChartId
                    ? {
                        label: charts?.find((x) => x.id == rvChartId)
                          ?.chart_name,
                        id: rvChartId,
                      }
                    : { label: "", id: "" }
                }
                error={
                  errors?.financial_product_charts_association?.rv_chart_id
                    ?.message as never
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_charts_association.program_subsidy"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Tooltip
                theme={theme}
                title={
                  isSubsidyDisabled && !isDisabled
                    ? ActionTypes.DISABLED_SUBSIDY_CHART
                    : ""
                }
              >
                <Autocomplete
                  {...field}
                  theme={theme}
                  disabled={isDisabled || isSubsidyDisabled}
                  disablePortal={true}
                  capitalizeLabel={true}
                  items={
                    subsidySelection?.map((option: any) => {
                      return { label: option.text, id: option.value };
                    }) ?? []
                  }
                  onChange={(_e: any, value: any) => {
                    field.onChange(value);
                  }}
                  label={"Subsidy"}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              </Tooltip>
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_charts_association.program_commission"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Tooltip
                theme={theme}
                title={
                  isCommissionDisabled && !isDisabled
                    ? ActionTypes.DISABLED_COMMISSION_CHART
                    : ""
                }
              >
                <Autocomplete
                  {...field}
                  theme={theme}
                  disabled={isDisabled || isCommissionDisabled}
                  disablePortal={true}
                  capitalizeLabel={true}
                  items={
                    commissionSelection?.map((option: any) => {
                      return { label: option.text, id: option.value };
                    }) ?? []
                  }
                  onChange={(_e: any, value: any) => {
                    field.onChange(value);
                  }}
                  label={"Commission"}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              </Tooltip>
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_charts_association.down_payment"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Autocomplete
                {...field}
                theme={theme}
                disabled={isDisabled}
                disablePortal={true}
                capitalizeLabel={true}
                items={
                  downPaymentSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                }}
                label={"Down Payment"}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
      </CustomGrid>
    </>
  );
};
export default MetricsAssociation;
