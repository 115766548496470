import {
  AnnualMileageReducer,
  AnnualMileageFilterReducer,
} from "./annual-mileage.reducer";
import { CreditRatingReducer } from "./credit-rating.reducer";
import { AssetMakeReducer } from "./asset-make.reducer";
import { AssetModelReducer } from "./asset-model.reducers";
import { AssetTrimReducer } from "./asset-trim.reducer";
import { ChartsReducer } from "./charts.reducer";
import { LenderSetupReducer } from "./lender-setup.reducer";
import { InterestChartReducer } from "./interest-charts.reducer";
import { TermsReducer } from "./terms.reducer";
import { RvChartReducer } from "./rv-chart.reducer";
import { LenderProfileReducer } from "./lender-profile.reducer";
import { ToastReducer } from "./toast.reducer";
import { ProgramReducer, FpAssetGroupReducer } from "./Program.reducer";
import { ProgramsReducer } from "./Program-list.reducer";
import { LenderAddressReducer } from "./lender-address.reducer";
import { StatesReducer } from "./states.reducer";
import { DocumentTypeReducer } from "./document-type.reducer";
import { StipulationReducer } from "./stipulation.reducer";
import { CurrencyReducer } from "./currencies.reducer";
import { FeeNameReducer } from "./fee-name.reducer";
import { ContractAllowedAnnumMileageReducer } from "./contract-allowed-annum-mileage.reducer";

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        [prop]: reducers[prop](acc[prop], action),
      };
    }, state);
  };
};

export const setupsReducer = combineReducers({
  annualMileagesFilter: AnnualMileageFilterReducer,
  annualMileages: AnnualMileageReducer,
  creditRatings: CreditRatingReducer,
  assetMakes: AssetMakeReducer,
  assetModels: AssetModelReducer,
  assetTrims: AssetTrimReducer,
  interestCharts: InterestChartReducer,
  terms: TermsReducer,
  charts: ChartsReducer,
  openDrawerSt: LenderSetupReducer,
  rvCharts: RvChartReducer,
  lenderProfile: LenderProfileReducer,
  toastProps: ToastReducer,
  lenderAddress: LenderAddressReducer,
  financialProduct: ProgramReducer,
  Programs: ProgramsReducer,
  fpAssetGroup: FpAssetGroupReducer,
  usaStates: StatesReducer,
  documentTypes: DocumentTypeReducer,
  stipulations: StipulationReducer,
  currencies: CurrencyReducer,
  feeNames: FeeNameReducer,
  contractAllowedAnnumMileage: ContractAllowedAnnumMileageReducer,
});
