import { ISetupStore } from "Interface";
import { createContext, useContext, useReducer } from "react";
import { setupsReducer } from "Stores/SetupStore/Reducers";

const initialState: ISetupStore = {
  contractAllowedAnnumMileage: [],
  contractAllowedAnnumMileageFilter: [],
};

const SetupsStore = createContext<[ISetupStore, any]>(null);
SetupsStore.displayName = "Setups Store";

export const useSetupsStore = () => useContext(SetupsStore);

export const SetupsStoreProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(setupsReducer, initialState);

  return (
    <SetupsStore.Provider value={[globalState, dispatch]}>
      {children}
    </SetupsStore.Provider>
  );
};
