import { FC, useState } from "react";
import { useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import { Box, Grid, Select, Typography, IBoxProps } from "@ntpkunity/controls";
import { PRICING_PROGRAM } from "../../shared/constants";
import { FinanceRates } from "../../components/AddNewLender/FinanceRates";
import { LeaseRates } from "../../components/AddNewLender/LeaseRates";
import { FinanceCharts } from "../../components/AddNewLender/FinanceCharts";
import { styled } from "@mui/material/styles";

const CompanyPricingWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.pricing-wrap": {
    ".MuiGrid-root": {
      ".MuiGrid-item": {
        maxWidth: "25%",
        marginBottom: 24,
        ".u-custom-autocomplete": {
          marginBottom: 0,
        },
        [theme.breakpoints.down("lg")]: {
          maxWidth: "50%",
        },
      },
    },
    ".inner-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      paddingTop: 24,
      ".MuiGrid-root.MuiGrid-container": {
        marginLeft: -24,
        ".MuiGrid-root.MuiGrid-item": {
          paddingLeft: 24,
        },
      },
    },
  },
}));

const CompanyPricing: FC<{
  form: any;
  setCompanyFieldState: any;
}> = ({ form, setCompanyFieldState }) => {
  const theme = useTheme();
  const {
    control,
    watch,
    formState: { errors },
  } = form;
  const selectedPricingProgram = watch("pricing_program");

  return (
    <CompanyPricingWrap theme={theme} className="pricing-wrap">
      <Grid theme={theme} container spacing={3}>
        <Grid theme={theme} item lg={3} xs={6}>
          <Controller
            name="pricing_program"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={"Pricing"}
                placeholder={"Select"}
                items={[
                  { text: "Standard Rates", value: "Standard Rates" },
                  { text: "Standard Charts", value: "Standard Charts" },
                ]}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {selectedPricingProgram === "Standard Rates" && (
        <>
          <Box theme={theme} className="inner-section">
            <Typography
              className="title-icon"
              variant="h4"
              component="h4"
              theme={theme}
              mb={3}
            >
              <span>Lease Rates</span>
            </Typography>
            <LeaseRates
              form={form}
              popUpOpenState={""}
              disabled={false}
              setCompanyFieldState={setCompanyFieldState}
            />
          </Box>
          <Box theme={theme} className="inner-section">
            <Typography
              className="title-icon"
              variant="h4"
              component="h4"
              theme={theme}
              mb={3}
            >
              <span>Finance Rates</span>
            </Typography>
            <FinanceRates
              form={form}
              popUpOpenState={""}
              disabled={false}
              setCompanyFieldState={setCompanyFieldState}
            />
          </Box>
        </>
      )}
      {selectedPricingProgram === "Standard Charts" && (
        <Box theme={theme} className="inner-section">
          <FinanceCharts
            form={form}
            popUpOpenState={""}
            disabled={false}
            setCompanyFieldState={setCompanyFieldState}
          />
        </Box>
      )}
    </CompanyPricingWrap>
  );
};

export default CompanyPricing;
