import Http from "shared/helper/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  EP_ADD_ON_ACCESSORIES,
  EP_GET_MASTER_LIST_ACCESSORIES,
  EP_GET_OPTIONS,
  EP_GET_TRIMS,
  EP_SAVE_ADD_ON,
  EP_SAVE_OEM_OPTION,
  EP_GET_FILTER_MASTER_LIST,
} from "shared/config/endpoints";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ConfigurationAPI } from "services";
import { QueryKeys, Vehicle_Type } from "Enums";

export const useSaveAddOn = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<
    {
      offered_by: string;
      description: string;
      product_name: string;
      category: string;
      specfic_category: string;
      vehicle_condition: string;
      part_no: number;
      supplier: string;
      installed_mode: boolean;
      price: number;
      rv_adder: string;
      compatible_models: number[];
      status: string;
      trim_image: string;
      dealer_code: string;
    },
    any
  >(
    (body: any) => {
      body.price = parseFloat(body.price);
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_SAVE_ADD_ON}`, body);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_OPTIONS_FILTER);
        queryClient.invalidateQueries(QueryKeys.CHECK_PREFERRED_ACCESSORIES);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateAddOn = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<
    {
      id: number;
      offered_by: string;
      description: string;
      product_name: string;
      category_id: string;
      specfic_category: string;
      vehicle_condition: string;
      part_no: number;
      supplier: string;
      installed_mode: boolean;
      price: number;
      rv_adder: string;
      compatible_models: number[];
      status: string;
      trim_image: string;
      dealer_code: string;
    },
    any
  >(
    (body: any) => {
      body.price = parseFloat(body.price);
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_SAVE_ADD_ON}/${body.id}`, body);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_OPTIONS_FILTER);
        queryClient.invalidateQueries(QueryKeys.CHECK_PREFERRED_ACCESSORIES);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useAddonsAccessories = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      let params = body.data ? `?${body.data}` : "";
      return apiService.get<any>(
        params ? `${EP_ADD_ON_ACCESSORIES}/${params}` : EP_ADD_ON_ACCESSORIES
      );
    },
    {
      onSuccess(_response: any) {
        actions.setOptionsInitialData(_response);
      },
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetMasterListAccessories = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(EP_GET_MASTER_LIST_ACCESSORIES);
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, error, mutate };
};
export const useUpdateAddonById = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<
    { id: number; isActive: boolean },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_SAVE_ADD_ON}/${body.id}`, {
        isActive: body.isActive,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_OPTIONS_FILTER);
        queryClient.invalidateQueries(QueryKeys.CHECK_PREFERRED_ACCESSORIES);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteAddonById = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<
    { id: number; dealer_id: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(`${EP_SAVE_ADD_ON}/${body.id}`);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_OPTIONS_FILTER);
        queryClient.invalidateQueries(QueryKeys.CHECK_PREFERRED_ACCESSORIES);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useGetAddonById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_SAVE_ADD_ON}/${body.id}`);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useGetAddonByTrim = (): any => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection();
      let params = body.data ? `${body.data}` : "";
      return apiService.get<any>(
        params ? `${EP_GET_OPTIONS}?${params}` : EP_GET_OPTIONS
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetAssetMakeTrims = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY,
      });
      return apiService.get<any>(`${EP_GET_TRIMS}`);
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, error, mutate };
};

export const useSaveOemOption = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<
    {
      offered_by: string;
      description: string;
      product_name: string;
      category: string;
      specfic_category: string;
      vehicle_condition: string;
      part_no: number;
      supplier: string;
      installed_mode: boolean;
      price: number;
      rv_adder: string;
      compatible_models: number[];
      status: string;
      trim_image: string;
      dealer_code: string;
    },
    any
  >(
    (body: any) => {
      body.price = parseFloat(body.price);
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_SAVE_OEM_OPTION}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OEM_OPTIONS_FILTER);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateOemOption = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<
    {
      id: number;
      offered_by: string;
      description: string;
      product_name: string;
      category: string;
      specfic_category: string;
      vehicle_condition: string;
      part_no: number;
      supplier: string;
      installed_mode: boolean;
      price: number;
      rv_adder: string;
      compatible_models: number[];
      status: string;
      trim_image: string;
      dealer_code: string;
    },
    any
  >(
    (body: any) => {
      body.price = parseFloat(body.price);
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_SAVE_OEM_OPTION}/${body.id}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OEM_OPTIONS_FILTER);
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useOemOptionAccessories = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      let params = body.data ? `?${body.data}` : "";
      return apiService.get<any>(
        params
          ? `${EP_GET_FILTER_MASTER_LIST}/${params}`
          : EP_GET_FILTER_MASTER_LIST
      );
    },
    {
      onSuccess(_response: any) {
        actions.setOemOptionsInitialData(_response);
      },
    }
  );
  return { data, isLoading, error, mutate };
};

export const useUpdateOemOptionById = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { id: number; isActive: boolean },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_SAVE_OEM_OPTION}/${body.id}`, {
        isActive: body.isActive,
      });
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteOemOptionById = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(`${EP_SAVE_OEM_OPTION}/${body.id}`);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OEM_OPTIONS_FILTER);
      },
    }
  );
  return { data, isLoading, mutate };
};
export const useGetOemOptionById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_SAVE_OEM_OPTION}/${body.id}`);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const getAllOEMOptionsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/master-list-accessorie/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllOEMOptions_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_OEM_OPTIONS_FILTER, columnFilters],
    async () => {
      return getAllOEMOptionsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const getAllOptionsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `dealer/option/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllOptions_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_DEALER_OPTIONS_FILTER, columnFilters],
    async () => {
      return getAllOptionsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true, enabled: !!columnFilters }
  );
};
