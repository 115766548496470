import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { INewTradingAgePopUp } from "./AddNewTradingAge.type";
import { ITradingAge } from "Interface";
import {
  Select,
  Typography,
  Button,
  ConfirmationDialog,
} from "@sharedComponents";
import { Controller, useForm } from "react-hook-form";
import {
  UseMutation_AddBusinessTradingAge,
  UseMutation_UpdateTradingAge,
} from "services";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store/SetupsStoreProvider";
import { useQueryClient } from "react-query";
import { Grid, PersistentDrawer, Input, Icon } from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";

const NewBusinessTradingAge: FC<INewTradingAgePopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  tradingAgeData,
  enabledPin,
}) => {
  const [state] = useSetupsStore();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [tradingAge, setTradingAge] = useState<number[]>([0, 10000]);
  const [unit, setUnit] = useState<string>("");

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    watch,
  } = useForm<ITradingAge>();
  const watchTradingAges = watch(["age_from", "age_to"]);
  const watchUnit = watch(["unit"]);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const { mutate: AddTradingAge } = UseMutation_AddBusinessTradingAge();
  const { mutate: EditTradingAge } = UseMutation_UpdateTradingAge(
    getValues("id")
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setUnit(value.unit || "Months");
    });
    return () => subscription.unsubscribe();
  }, [watchUnit]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let AgeFrom, AgeTo;
      AgeFrom = getValues("age_from") >= 0 ? getValues("age_from") : 0;
      AgeTo = getValues("age_to") >= 0 ? getValues("age_to") : 0;
      setTradingAge([+AgeFrom, +AgeTo]);
    });
    return () => subscription.unsubscribe();
  }, [watchTradingAges]);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", tradingAgeData.id);
    }
    setValue(
      "age_from",
      tradingAgeData ? tradingAgeData.age_from : tradingAge[0]
    );
    setValue("age_to", tradingAgeData ? tradingAgeData.age_to : tradingAge[1]);
    setValue("unit", tradingAgeData ? tradingAgeData.unit : unit);
    setValue("is_active", tradingAgeData ? tradingAgeData.is_active : true);
    setTradingAge([getValues("age_from"), getValues("age_to")]);
    if (actionType === ActionOptions.ADD) {
      reset();
    }
  }, [tradingAgeData]);

  function onSubmit(data) {
    if (data.age_from >= 0 && data.age_from <= 10000) {
      clearErrors("age_from");
    } else {
      setError("age_from", {
        message: "Value should be in between 0 - 10000",
      });
    }
    if (data.age_to >= 0 && data.age_to <= 10000) {
      clearErrors("age_to");
    } else {
      setError("age_to", {
        message: "Value should be in between 0 - 10000",
      });
    }
    if (
      actionType === ActionOptions.ADD &&
      data.age_to >= data.age_from &&
      data.age_to <= 10000 &&
      data.age_from >= 0 &&
      data.age_from <= 10000
    ) {
      AddTradingAge(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
    }
    if (
      actionType === ActionOptions.EDIT &&
      data.age_to >= data.age_from &&
      data.age_to <= 10000 &&
      data.age_from >= 0 &&
      data.age_from <= 10000
    ) {
      EditTradingAge(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
      onClose();
    }
    setTradingAge([0, 10000]);
  }

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onCancel = () => {
    onClose();
  };
  const onDistanceFromChange = (e) => {
    if (e > 10000) {
      setError("age_from", {
        message: "Value should be in between 0 - 10000",
      });
      setValue("age_from", parseInt(e));
    } else {
      setValue("age_from", parseInt(e));
      clearErrors("age_from");
    }
  };

  const onDistanceToChange = (e) => {
    if (e > 10000) {
      setError("age_to", {
        message: "Value should be in between 0 - 10000",
      });
      setValue("age_to", parseInt(e));
    } else {
      setValue("age_to", parseInt(e));
      clearErrors("age_to");
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="age_from"
                  control={control}
                  rules={{
                    required: ValidationMessages.AGE_FROM_REQUIRED,
                    max: {
                      value: getValues("age_to"),
                      message: ValidationMessages.AGE_FROM_LESS_THAN_AGE_TO,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"0"}
                      label={"Age From"}
                      type="text"
                      onChange={(e) => {
                        onDistanceFromChange(e);
                      }}
                      value={value}
                      maskNumeric
                      maskDecimalScale={0}
                      masking
                      error={errors?.age_from?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="age_to"
                  control={control}
                  rules={{
                    required: ValidationMessages.AGE_TO_REQUIRED,
                    min: {
                      value: getValues("age_from"),
                      message: ValidationMessages.AGE_TO_GREATER_THAN_AGE_FROM,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth={true}
                      theme={theme}
                      placeholder={"0"}
                      label={"Age To"}
                      type="text"
                      id="age_to"
                      onChange={(e) => {
                        onDistanceToChange(e);
                      }}
                      value={value}
                      maskNumeric
                      maskDecimalScale={0}
                      masking
                      error={errors?.age_to?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="unit"
                  control={control}
                  rules={{
                    required: ValidationMessages.UNIT_IS_REQUIRED,
                  }}
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Unit"
                      theme={theme}
                      fullWidth={true}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      items={[
                        { text: "Months", value: "Months" },
                        { text: "Years", value: "Years" },
                      ]}
                      selectError={errors?.unit?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      label={"Status"}
                      placeholder={"Select"}
                      items={[
                        { text: "Enabled", value: true },
                        { text: "Disabled", value: false },
                      ]}
                      value={value}
                      onChange={onChange}
                    ></Select>
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            primary
            text="Save Age"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default NewBusinessTradingAge;
