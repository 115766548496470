import IActions from "../configs";
import { Dispatch, ReducerAction } from "react";
import { IFinancialInsurance } from "Interface";

export const GET_ALL_FINANCIAL_INSURANCE_PRODUCT = "GET ALL FINANCIAL PRODUCT";
export const GET_ALL_FINANCIAL_INSURANCE_INITIAL_DATA =
  "GET_ALL_FINANCIAL_INSURANCE_INITIAL_DATA";
export const ADD_FINANCIAL_INSURANCE_ROW = "ADD FINANCIAL INSURANCE ROW";
export const UPDATE_FINANCIAL_INSURANCE = "UPDATE_FINANCIAL_INSURANCE";
export const DELETE_FINANCIAL_INSURANCE = "DELETE_FINANCIAL_INSURANCE";

export const initialState: any = [];

const financialInsuranceReducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case GET_ALL_FINANCIAL_INSURANCE_PRODUCT:
      return action.payload as typeof initialState;
    case GET_ALL_FINANCIAL_INSURANCE_INITIAL_DATA:
      return action.payload as typeof initialState;
    case ADD_FINANCIAL_INSURANCE_ROW: {
      state.unshift(action.payload as any);
      return state;
    }
    case UPDATE_FINANCIAL_INSURANCE: {
      const index = state.findIndex(
        (financialInsurance: any) =>
          financialInsurance.id === (action.payload as IFinancialInsurance).id
      );
      state[index] = action.payload as any;
      return state;
    }
    case DELETE_FINANCIAL_INSURANCE: {
      const index = state.findIndex(
        (financialInsurance: any) =>
          financialInsurance.id === (action.payload as IFinancialInsurance).id
      );
      state.splice(index, 1) == (action.payload as any);
      return state;
    }
  }
  return state;
};

export const setFinancialInsuranceProductData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof financialInsuranceReducer>>
): void => {
  if (data) {
    dispatch({ type: GET_ALL_FINANCIAL_INSURANCE_PRODUCT, payload: data });
  }
};
export const setFinancialInsuranceInitialData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof financialInsuranceReducer>>
): void => {
  if (data) {
    dispatch({ type: GET_ALL_FINANCIAL_INSURANCE_INITIAL_DATA, payload: data });
  }
};
export const AddFinancialInsuranceDataRow = (
  data: IFinancialInsurance,
  dispatch: Dispatch<ReducerAction<typeof financialInsuranceReducer>>
): void => {
  if (data) {
    dispatch({ type: ADD_FINANCIAL_INSURANCE_ROW, payload: data });
  }
};

export const updateFinancialInsuranceDataRow = (
  data: IFinancialInsurance,
  dispatch: Dispatch<ReducerAction<typeof financialInsuranceReducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_FINANCIAL_INSURANCE, payload: data });
  }
};

export const deleteFinancialInsuranceDataRow = (
  data: IFinancialInsurance,
  dispatch: Dispatch<ReducerAction<typeof financialInsuranceReducer>>
): void => {
  if (data) {
    dispatch({ type: DELETE_FINANCIAL_INSURANCE, payload: data });
  }
};

export default financialInsuranceReducer;
