import IActions from "../configs";
import { Dispatch, ReducerAction } from "react";

import { IAddOns, IPaginatedResponse_AddOns } from "Interface";

export const SET_OPTIONS_INIT_STATE = "GET OPTIONS DATA FROM SERVER";
export const UPDATE_OPTIONS_ROW = "UPDATE_OPTIONS_ROW";
export const DELETE_OPTIONS_ROW = "DELETE_OPTIONS_ROW";
export const ADD_OPTIONS_ROW = "ADD_OPTIONS_ROW";
export const initialState: IPaginatedResponse_AddOns = {
  pageNum: 0,
  pageSize: 0,
  result: [],
  totalResults: 0,
};

const optionReducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case SET_OPTIONS_INIT_STATE:
      return action.payload as typeof initialState;
    case UPDATE_OPTIONS_ROW: {
      const index = state.result.findIndex(
        (option) => option.id === (action.payload as IAddOns).id
      );
      state.result[index] = action.payload as any;
      return state;
    }
    case DELETE_OPTIONS_ROW: {
      const index = state.result.findIndex(
        (option) => option.id === (action.payload as IAddOns).id
      );
      state.result.splice(index, 1) == (action.payload as any);
      return state;
    }
    case ADD_OPTIONS_ROW: {
      state.result.unshift(action.payload as any);
      return state;
    }
  }
  return state;
};

export const updateOptionsData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof optionReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_OPTIONS_INIT_STATE, payload: data });
  }
};

export const updateOptionsDataRow = (
  data: IAddOns,
  dispatch: Dispatch<ReducerAction<typeof optionReducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_OPTIONS_ROW, payload: data });
  }
};
export const deleteOptionsDataRow = (
  data: IAddOns,
  dispatch: Dispatch<ReducerAction<typeof optionReducer>>
): void => {
  if (data) {
    dispatch({ type: DELETE_OPTIONS_ROW, payload: data });
  }
};
export const addOptionsDataRow = (
  data: IAddOns,
  dispatch: Dispatch<ReducerAction<typeof optionReducer>>
): void => {
  if (data) {
    dispatch({ type: ADD_OPTIONS_ROW, payload: data });
  }
};
export default optionReducer;
