import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import {
  Icon,
  PersistentDrawer,
  MultiSelect,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog, MultiSelectWrap } from "@sharedComponents";
import { INewDocumentTypePopup } from "./AddNewDocument.type";
import { Controller, useForm } from "react-hook-form";
import { ActionOptions, ValidationMessages, DialogMessages } from "Enums";
import {
  UseMutation_AddDocumentType,
  UseMutation_UpdateDocumentType,
} from "services";
import { ISelectItemList, IDocumentTypes } from "Interface";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { useStoreContext } from "Stores/SetupStore/Store";

const AddNewDocumentType: FC<INewDocumentTypePopup> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  enabledPin,
  documentTypeData,
  financeTypes,
  customerTypes,
  AllFinancedAmounts,
}) => {
  const theme = useTheme();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm<IDocumentTypes>();
  const { actions } = useStoreContext();
  const { mutate: AddDocumentType } = UseMutation_AddDocumentType();
  const { mutate: UpdateDocumentType } = UseMutation_UpdateDocumentType(
    getValues("id")
  );

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", documentTypeData?.id);
    }
    setValue("document_code", documentTypeData?.document_code);
    setValue("description", documentTypeData?.description);
    setValue("is_active", documentTypeData ? documentTypeData.is_active : true);
    setFinanceType(documentTypeData?.finance_type);
    setCustomerType(documentTypeData?.customer_type);
    setFinancedAmounts(documentTypeData?.financed_amount);
    setValue("state", documentTypeData ? documentTypeData.state : true);
  }, [documentTypeData]);

  const [customerType, setCustomerType] = useState([]);
  const [financeType, setFinanceType] = useState([]);
  const [financedAmounts, setFinancedAmounts] = useState([]);

  const [customerTypeSelection, setCustomerTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [financeTypeSelection, setFinanceTypeSelectionState] =
    useState<Array<ISelectItemList>>();
  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    const customerTypeArray = customerTypes
      ?.filter((x) => x.is_active)
      ?.map((customer) => ({
        value: `${customer.description}`,
        text: `${customer.description}`,
      }));
    customerType?.forEach((id) => {
      const selectedCustomerType = customerTypes?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedCustomerType &&
        customerTypeArray.push({
          value: selectedCustomerType?.description,
          text: selectedCustomerType?.description,
        });
    });
    setCustomerTypeSelection(customerTypeArray);
  }, [customerTypes, customerType]);

  useEffect(() => {
    const financeTypeArray = financeTypes
      ?.filter((x) => x.is_active)
      ?.map((finance) => ({
        value: `${finance.name}`,
        text: `${finance.name}`,
      }));
    financeType?.forEach((id) => {
      const selectedFinanceType = financeTypes?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinanceType &&
        financeTypeArray.push({
          value: selectedFinanceType?.name,
          text: selectedFinanceType?.name,
        });
    });
    setFinanceTypeSelectionState(financeTypeArray);
  }, [financeTypes, financeType]);

  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from} - ${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);

  const onCustomerTypeSelection = (e) => {
    setCustomerType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinanceTypeSelection = (e) => {
    setFinanceType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  function onSubmit(data) {
    data.finance_type = financeType;
    data.customer_type = customerType;
    data.financed_amount = financedAmounts;

    if (actionType === ActionOptions.ADD) {
      AddDocumentType(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Description should be unique"
          ) {
            setError("document_code", { message: "Code should be unique" });
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("document_code", { message: "Code should be unique" });
            return;
          }
          if (
            error?.response?.data?.detail?.message ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
    if (actionType === ActionOptions.EDIT) {
      UpdateDocumentType(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Description should be unique"
          ) {
            setError("document_code", { message: "Code should be unique" });
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("document_code", { message: "Code should be unique" });
            return;
          }
          if (
            error?.response?.data?.detail?.message ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="document_code"
              control={control}
              defaultValue={""}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.DOCUMENT_CODE_MAX_LENGTH,
                },
                required: ValidationMessages.DOCUMENT_CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e.replace(/[^a-zA-Z0-9]/gi, ""));
                  }}
                  error={errors?.document_code?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                maxLength: {
                  value: 150,
                  message: ValidationMessages.DOCUMENT_DESCRIPTION_MAX_LENGTH,
                },
                required: ValidationMessages.DOCUMENT_DESCRIPTION_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Description"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(
                      e.replace(/[^a-zA-Z0-9!@#$%^&*()|/,.<>;:""'{}[]]/gi, "")
                    );
                  }}
                  error={errors?.description?.message}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="customer_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    id="DrawerCustomerType"
                    label={"Customer Type"}
                    value={customerType || []}
                    items={customerTypeSelection}
                    onChange={onCustomerTypeSelection}
                    defaultValue={[""]}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="finance_type"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerProductType"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Finance Type"}
                    value={financeType || []}
                    items={financeTypeSelection}
                    onChange={onFinanceTypeSelection}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="financed_amount"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                  />
                )}
              />
            </MultiSelectWrap>
            <Controller
              name="state"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"State"}
                  placeholder={"Select"}
                  items={[
                    { text: "Mandatory", value: true },
                    { text: "Optional", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewDocumentType;
