import { FC, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme, TablePagination } from "@mui/material";
import { Box, DataTable, Icon, Input, Button } from "@ntpkunity/controls";
import ChevronDown from "../../shared/assets/images/chevron-down";
import {
  UseQuery_getAllCharts,
  UseQuery_GetAllRvCharts,
  UseQuery_getAllRVCharts_filter,
} from "services";

import RvChartsTable from "./rv-chart-table";
import { ChartTypes } from "Enums";
import { useQueryClient } from "react-query";
import {
  IAnnualMileage,
  IAssetCondition,
  ICharts,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
  ITerms,
} from "Interface";
import { PaginationWrap } from "@sharedComponents";

const ChartTable: FC<{
  onOptionsClick: (key, value) => unknown;
  onEditCriteria: (data) => unknown;
  AllAssetConditions?: IAssetCondition[];
  AllTerms?: ITerms[];
  AllAnnualMileage?: IAnnualMileage[];
  AllFinanceType?: IFinanceType[];
  AllCustomerType?: ICustomerType[];
  AllAssetCategory?: IAssetCategory[];
  AllAssetType?: IAssetType[];
}> = ({
  onOptionsClick,
  onEditCriteria,
  AllAssetConditions,
  AllTerms,
  AllAnnualMileage,
  AllFinanceType,
  AllCustomerType,
  AllAssetCategory,
  AllAssetType,
}) => {
  UseQuery_getAllCharts();
  UseQuery_GetAllRvCharts();
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllRVCharts_filter(columnFilters);
  const RVChartsFilter: any = data;

  const [nameFilter, setNameFilter] = useState("");
  const [assetsFilter, setAssetsFilter] = useState("");
  const [assetConditionFilter, setAssetConditionFilter] = useState("");
  const [financedTypeFilter, setFinanceTypeFilter] = useState("");
  const [customerTypeFilter, setCustomerTypeFilter] = useState("");
  const [assetCategoryFilter, setAssetCategoryFilter] = useState("");
  const [assetTypeFilter, setAssetTypeFilter] = useState("");
  const [assetUsageFilter, setAssetUsageFilter] = useState("");
  const [rvGuaranteedFilter, setRvGuaranteedFilter] = useState("");
  const [termsFilter, setTermsFilter] = useState("");
  const [annualMileageFilter, setAnnualMileageFilter] = useState("");
  const [rvValueFilter, setRVValueFilter] = useState("");
  const [rvValueRangeFilter, setRVValueRangeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [financedAmountFilter, setFinancedAmountFilter] = useState("");
  const [purchaseOptionFilter, setPurchaseOptionFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (nameFilter.trimStart() != null && nameFilter.trimStart() != "") {
      query_string = query_string.concat(`&name=${nameFilter}`);
    }
    if (assetsFilter.trimStart() != null && assetsFilter.trimStart() != "") {
      query_string = query_string.concat(`&assets=${assetsFilter}`);
    }
    if (
      assetConditionFilter.trimStart() != null &&
      assetConditionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_condition=${assetConditionFilter}`
      );
    }
    if (
      financedTypeFilter.trimStart() != null &&
      financedTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&finance_types=${financedTypeFilter}`
      );
    }
    if (
      customerTypeFilter.trimStart() != null &&
      customerTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&customer_type=${customerTypeFilter}`
      );
    }
    if (
      assetCategoryFilter.trimStart() != null &&
      assetCategoryFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_category=${assetCategoryFilter}`
      );
    }
    if (
      assetTypeFilter.trimStart() != null &&
      assetTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&asset_type=${assetTypeFilter}`);
    }
    if (
      assetUsageFilter.trimStart() != null &&
      assetUsageFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&asset_usages=${assetUsageFilter}`);
    }
    if (termsFilter.trimStart() != null && termsFilter.trimStart() != "") {
      query_string = query_string.concat(`&terms=${termsFilter}`);
    }
    if (
      rvGuaranteedFilter.trimStart() != null &&
      rvGuaranteedFilter.trimStart() != ""
    ) {
      let rvGuarantee = "";
      if (
        "yes".startsWith(
          rvGuaranteedFilter
            .toLowerCase()
            .slice(0, Math.max("yes".length - 1, 1))
        )
      ) {
        rvGuarantee = "True";
      } else if (
        "no".startsWith(
          rvGuaranteedFilter
            .toLowerCase()
            .slice(0, Math.max("no".length - 1, 1))
        )
      ) {
        rvGuarantee = "False";
      }
      query_string = query_string.concat(`&is_rv_guaranteed=${rvGuarantee}`);
    }
    if (
      annualMileageFilter.trimStart() != null &&
      annualMileageFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&annual_usage=${annualMileageFilter}`
      );
    }
    if (
      financedAmountFilter.trimStart() != null &&
      financedAmountFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&financed_amount=${financedAmountFilter}`
      );
    }
    if (
      purchaseOptionFilter.trimStart() != null &&
      purchaseOptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&purchase_option=${purchaseOptionFilter}`
      );
    }
    if (rvValueFilter.trimStart() != null && rvValueFilter.trimStart() != "") {
      query_string = query_string.concat(`&rv_value=${rvValueFilter}`);
    }
    if (
      rvValueRangeFilter.trimStart() != null &&
      rvValueRangeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&rv_value_range=${rvValueRangeFilter}`
      );
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Chart Name </TableCell>
              <TableCell className="w-auto">Asset Make/Model/Trim</TableCell>
              <TableCell>Asset Condition</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>Customer Type</TableCell>
              <TableCell>Asset Category</TableCell>
              <TableCell>Asset Type</TableCell>
              <TableCell>Asset Usage</TableCell>
              <TableCell>RV Guaranteed</TableCell>
              <TableCell>Terms</TableCell>
              <TableCell>Allowed Annual Mileage</TableCell>
              <TableCell>Purchase Option</TableCell>
              <TableCell>Finance Amount</TableCell>
              <TableCell>RV/Balloon Value</TableCell>
              <TableCell>RV/Balloon Range</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>

            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={nameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetsFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetsFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetConditionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetConditionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinanceTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financedTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCustomerTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={customerTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetCategoryFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetCategoryFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetUsageFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetUsageFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setRvGuaranteedFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={rvGuaranteedFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setTermsFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={termsFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAnnualMileageFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={annualMileageFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinancedAmountFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financedAmountFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setPurchaseOptionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={purchaseOptionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setRVValueFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={rvValueFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setRVValueRangeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={rvValueRangeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={statusFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {RVChartsFilter &&
              RVChartsFilter?.result
                ?.filter((x) => x.chart_type_id == ChartTypes.RvChart)
                ?.map((item, index) => (
                  <RvChartsTable
                    key={index}
                    chart={item}
                    onOptionsClick={(key, value) => onOptionsClick(key, value)}
                    onEditCriteria={(data) => onEditCriteria(data)}
                    AllAssetConditions={AllAssetConditions}
                    AllTerms={AllTerms}
                    AllAnnualMileage={AllAnnualMileage}
                    AllFinanceType={AllFinanceType}
                    AllCustomerType={AllCustomerType}
                    AllAssetCategory={AllAssetCategory}
                    AllAssetType={AllAssetType}
                  />
                ))}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={RVChartsFilter ? RVChartsFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
    </>
  );
};

export default ChartTable;
