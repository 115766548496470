import { StoreActionType } from "Types";
import { IProgram, IFpAssetGroup } from "Interface";
import { ActionTypes } from "Enums";

export const ProgramReducer = (
  state: Array<IProgram> = [],
  action: StoreActionType
): Array<IProgram> => {
  switch (action.type) {
    case ActionTypes.ADD_PROGRAM:
      return [...state, action.payload];
    default:
      return state;
  }
};

export const FpAssetGroupReducer = (
  state: Array<IFpAssetGroup> = [],
  action: StoreActionType
): Array<IFpAssetGroup> => {
  switch (action.type) {
    case ActionTypes.ADD_FP_ASSET_GROUP:
      return [...state, action.payload];
    case ActionTypes.REMOVE_FP_ASSET_GROUP:
      return action.payload;
    default:
      return state;
  }
};
