import { StoreActionType } from "Types";
import { IAddress } from "Interface";
import { ActionTypes } from "Enums";

export const LenderAddressReducer = (
  state: Array<IAddress> = [],
  action: StoreActionType
): Array<IAddress> => {
  switch (action.type) {
    case ActionTypes.SET_LENDER_ADDRESS_INIT_STATE:
      return action.payload;

    case ActionTypes.ADD_LENDER_ADDRESS:
      return [...state, action.payload];

    case ActionTypes.UPDATE_LENDER_ADDRESS:
      return [...state, action.payload];

    default:
      return state;
  }
};
