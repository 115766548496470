import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";
import { IToast } from "Interface";

export const ToastReducer = (
  state: IToast,
  action: StoreActionType
): IToast => {
  switch (action.type) {
    case ActionTypes.SET_TOAST:
      return (state = action.payload);
    default:
      return state;
  }
};
