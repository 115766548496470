import { IStates } from "Interface";
import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";

export const setStatesInitialData = (
  states: Array<IStates>
): StoreActionType => {
  return {
    type: ActionTypes.SET_STATES_INITIAL_DATA,
    payload: states,
  };
};
