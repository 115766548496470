import Http from "shared/helper/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  EP_GET_FINANCIAL_INSURANCE_PRODUCTS,
  EP_GET_FINANCIAL_INSURANCE,
  EP_DELETE_ALL_FINANCIAL_INSURANCE,
} from "shared/config/endpoints";
import { IFinancialInsurance } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { ConfigurationAPI } from "services";
import { setToast } from "Stores/SetupStore/Actions";

export const useGetAllFinancialInsuranceProducts = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_GET_FINANCIAL_INSURANCE_PRODUCTS}`);
    },
    {
      onSuccess(_response: any) {
        actions.setFinancialInsuranceProductData(_response);
      },
    }
  );
  return { data, isLoading, error, mutate };
};

export const useSaveFinancialInsurance = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      // body.dealer_id = 1;
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_GET_FINANCIAL_INSURANCE}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `${EP_GET_FINANCIAL_INSURANCE}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(`${EP_GET_FINANCIAL_INSURANCE}/${body.id}`);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const getAllFinancialInsuranceProductFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/financial-insurances/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllFinancialInsuranceProduct_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER, columnFilters],
    async () => {
      return getAllFinancialInsuranceProductFilterFromServer(
        columnFilters
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useDeleteAllFinancialInsuranceByDealerId = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation(
    (dealer_code: string) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `${EP_DELETE_ALL_FINANCIAL_INSURANCE}/${dealer_code}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};
