export enum DecisionStatus {
  Cancelled = "Cancelled",
  Approved = "Approved",
  Conditioned = "Conditioned",
  Draft = "Draft",
  Submitted = "Submitted",
  Converted = "Converted",
  InReview = "In Review",
  Reviewed = "Reviewed",
  AwaitingScheduling = "Awaiting Scheduling",
  AppointmentScheduled = "Appointment Scheduled",
  ReSubmitted = "Re-Submitted",
  Completed = "Completed",
  Declined = "Declined",
  Withdrawn = "Withdrawn",
  AwaitingMore = "Awaiting More",
  FullyReceived = "Fully Received",
}
