import { StoreActionType } from "Types";
import { IAssetTrim } from "Interface";
import { ActionTypes } from "Enums";

export const AssetTrimReducer = (
  state: Array<IAssetTrim> = [],
  action: StoreActionType
): Array<IAssetTrim> => {
  switch (action.type) {
    case ActionTypes.ADD_ASSET_TRIM:
      return [...state, action.payload];

    case ActionTypes.SET_ASSET_TRIM_INIT_STATE:
      return [...action.payload];

    case ActionTypes.SET_ASSET_TRIM_BY_MODEL:
      const trimsToRetain = state.filter(
        (trims) => trims.model_id !== action.optionalData.modelId
      );
      return [...trimsToRetain, ...action.payload];

    case ActionTypes.UPDATE_ASSET_TRIM:
      const indexToUpdate = state.findIndex(
        (assetTrim) => assetTrim.id == action.payload?.id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
