import { IToast } from "Interface";
import IActions from "Stores/SetupStore/Store/configs";
import { Dispatch, ReducerAction } from "react";

export const SET_TOAST = "SET_TOAST";

export const initialState: IToast = {
  toastMessage: "",
  toastState: false,
  toastLength: undefined,
  variant: undefined,
  showIcon: false,
};

const ToastReducer = (state: typeof initialState, action: IActions): IToast => {
  switch (action.type) {
    case SET_TOAST:
      return (state = action.payload as IToast);
    default:
      return state;
  }
};

export const setToast = (
  toastProps: IToast,
  dispatch: Dispatch<ReducerAction<typeof ToastReducer>>
): void => {
  if (toastProps) {
    dispatch({ type: SET_TOAST, payload: toastProps });
  }
};

export default ToastReducer;
