import { FC, useState } from "react";
import {
  UseQuery_getAllSubsidyCharts_filter,
  UseQuery_GetAllSubsidyCharts,
} from "services";
import MasterTable from "../shared/master-table";
import { ChartTypes, QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { ICriteria } from "Interface";

const SubsidyTable: FC<{
  onOptionsClick: (key, value) => unknown;
  onEditCriteria: (data) => unknown;
}> = ({ onOptionsClick, onEditCriteria }) => {
  UseQuery_GetAllSubsidyCharts();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const { data } = UseQuery_getAllSubsidyCharts_filter(columnFilters);
  const subsidyChartsFilter: any = data;
  const queryClient = useQueryClient();
  const subsidyCharts: Array<ICriteria> = queryClient.getQueryData(
    QueryKeys.GET_ALL_SUBSIDY_CHARTS
  );

  return (
    <MasterTable
      onOptionsClick={(key, value) => onOptionsClick(key, value)}
      onEditCriteria={(data) => onEditCriteria(data)}
      chartsFilter={subsidyChartsFilter}
      setColumnFilters={setColumnFilters}
      setPageSize={setPageSize}
      setPageNumber={setPageNumber}
      pageSize={pageSize}
      pageNumber={pageNumber}
      chartType={ChartTypes.SubsidyChart}
      criteria={subsidyCharts}
    />
  );
};

export default SubsidyTable;
