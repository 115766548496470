import { Grid, IGridProps } from "@ntpkunity/controls";
import { styled } from "@mui/system";

export const CustomGrid = styled(
  Grid,

  {}
)<Partial<IGridProps>>(() => ({
  "&.main-content-section": {
    marginTop: 0,
    ".u-custom-autocomplete": {
      marginBottom: 0,
    },
    ".u-date-picker": {
      ".u-form-group": {
        marginBottom: 0,
      },
    },
  },
}));
