import { FC, useState } from "react";
import {
  FilePreview,
  Tooltip,
  CircleLoader,
  Box,
  FileDragDrop,
  Grid,
} from "@ntpkunity/controls";
import { FileDragDropWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { styled, Theme } from "@mui/material";
import {
  UseMutation_uploadS3Document,
  UseMutation_deleteS3Image,
} from "services";
import { ActionTypes, QueryKeys } from "Enums";

import { IImage } from "Interface";
const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

interface IDocumentProps {
  theme: Theme;
  form: any;
  files?: any;
  setFiles?: any;
  newuploadedfiles?: any;
  setNewUploadedFiles?: any;
  setCompanyFieldState?: any;
  companyFieldState?: any;
  setDeletedFiles?: any;
  deleteFiles?: any;
  s3response?: any;
}

const Documents: FC<IDocumentProps> = ({
  theme,
  form,
  files,
  setFiles,
  newuploadedfiles,
  setNewUploadedFiles,
  setCompanyFieldState,
  setDeletedFiles,
  s3response,
}) => {
  const { control } = form;
  const { mutate: uploadImage } = UseMutation_uploadS3Document();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const [imageLoader, setImageLoader] = useState<boolean>(false);

  const onimageDrop = (e) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];

      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          setCompanyFieldState(true);
          response.data.map((document: IImage) => {
            document.size = file.size;
            document.name = file.name;
            setFiles([...files, document]);
            setNewUploadedFiles([...newuploadedfiles, document]);
          });
        },
      });
    }
  };

  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          setCompanyFieldState(true);
          response.data.map((document: IImage) => {
            document.size = file.size;
            document.name = file.name;
            setFiles([...files, document]);
            setNewUploadedFiles([...newuploadedfiles, document]);
          });
        },
      });
    }
  };
  const onRemoveFile = (fileKey: string, index: number, name: any) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const documentArray = files;
          const newFileArray = newuploadedfiles;
          const isInNewUploadedFiles = newFileArray?.some(
            (file) => file.key === fileKey
          );
          if (!isInNewUploadedFiles) {
            setDeletedFiles((prevDeleted) => {
              if (!prevDeleted.includes(fileKey)) {
                return [...prevDeleted, fileKey];
              }
              return prevDeleted;
            });
          }
          const newFileIndex = newFileArray.findIndex(
            (file) => file.key === fileKey
          );
          documentArray.splice(index, 1);
          if (newFileIndex > -1) {
            newFileArray.splice(newFileIndex, 1);
          }
          setFiles([...documentArray]);
          setNewUploadedFiles([...newFileArray]);
          setImageLoader(false);
        },
      });
      setCompanyFieldState(true);
    }
  };
  const onClickIcon = (indexNumber: number) => {
    const document_url = files[indexNumber]?.location;
    if (!document_url) {
      return;
    }
    window.open(document_url, "_blank");
  };

  return (
    <Grid theme={theme} container item spacing={3} mb={2}>
      <Grid theme={theme} item sm={4} xs={12}>
        <FileDragDropWrap className="mb-24">
          <Controller
            name="company_documents"
            control={control}
            render={({ field }) => (
              <Tooltip
                theme={theme}
                title={
                  !s3response?.data
                    ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                    : "Add Documents"
                }
              >
                <FileDragDrop
                  {...field}
                  hoverLabel={
                    <>
                      Drag and drop file, or{" "}
                      <span className="text-primary">browse</span>
                    </>
                  }
                  width="200px"
                  height="200px"
                  backgroundColor="white"
                  onDrop={onimageDrop}
                  onChange={onimageUpload}
                  theme={theme}
                  disabled={!s3response?.data}
                />
              </Tooltip>
            )}
          />
          <FilePreview
            files={files && files.length > 0 ? files : []}
            theme={theme}
            preview="fileIcon"
            onRemoveFile={(e, index) => {
              onRemoveFile(e, index, "company_documents");
            }}
            onClickIcon={onClickIcon}
            viewIcon={true}
          />
          {imageLoader && (
            <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
              <CircleLoader theme={theme} size="xs" />
            </ImgLoader>
          )}
        </FileDragDropWrap>
      </Grid>
    </Grid>
  );
};

export default Documents;
