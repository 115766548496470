import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { setStatesInitialData } from "Stores/SetupStore/Actions";

export const getAll_USA_StatesFromServer = () => {
  return ConfigurationAPI.get("/state").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAll_USA_States = () => {
  const [, dispatch] = useSetupsStore();
  return useQuery(QueryKeys.GET_ALL_USA_STATES, async () => {
    return getAll_USA_StatesFromServer().then((data) => {
      dispatch(setStatesInitialData(data));
      return data;
    });
  });
};
