import { FC, useState, Dispatch, SetStateAction, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu } from "@ntpkunity/controls";
import {
  useMutation_DeleteAssetModel,
  useMutation_UpdateAssetModel,
} from "services";
import {
  Checkbox,
  Box,
  Button,
  ConfirmationDialog,
  Switch,
  ConfirmationUpdateDialog,
} from "@sharedComponents";
import { IAssetModel, IAssetTrim, IImage } from "Interface";
import AssetTrimTable from "./asset-trim-table";
import { ActionOptions, QueryKeys } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useTheme } from "@mui/material";

interface AssetModelProps {
  assetModel: IAssetModel;
  showCheckBox?: boolean;
  onEditAssetModel?: (data: IAssetModel) => unknown;
  onEditAssetTrim?: (data: IAssetTrim) => unknown;
  onAddAssetModelTrim?: (data: IAssetModel) => unknown;
  handleImage?: (data: IImage[]) => unknown;
  modelIndex?: number;
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="AddIcon" />
        {ActionOptions.ADD_TRIM}
      </>
    ),
    optionkey: ActionOptions.ADD_TRIM,
    optionValue: {},
  },
];

const AssetModelTable: FC<AssetModelProps> = ({
  assetModel,
  showCheckBox,
  onEditAssetModel,
  onEditAssetTrim,
  onAddAssetModelTrim,
  modelIndex,
  handleImage,
}) => {
  const [open2, setOpen2] = useState(false);
  const assetTrims: Array<IAssetTrim> = assetModel?.asset_trims || [];
  const [colSpanValue, setColSpanValue] = useState<number>(6);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [confirmationPopupStateDisable, setConfirmationPopupStateDisable] =
    useState<boolean>(false);
  const { mutate: deleteModel } = useMutation_DeleteAssetModel();
  const { mutate: UpdateModel } = useMutation_UpdateAssetModel(undefined);
  const [actionData, setActionData] = useState<IAssetModel>(undefined);
  const { actions } = useStoreContext();
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEditAssetModel(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      case ActionOptions.ADD_TRIM:
        onAddAssetModelTrim(value);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteModel(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const onDisableConfirm = (e: any) => {
    UpdateModel(actionData, {
      onSuccess: () => {
        // setConfirmationPopupStateDisable(false);
      },
      onError: (error) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    });
  };

  const handleEnableChange = (data: IAssetModel, event) => {
    let updatedData = {
      id: data.id,
      is_active: event?.target?.checked,
      model_name: data.model_name,
      model_code: data.model_code,
      description: data.description,
      make_id: data.make_id,
    };
    if (event?.target?.checked) {
      UpdateModel(updatedData, {
        onError: (error) => {
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        },
      });
    } else {
      setConfirmationPopupStateDisable(true);
      setConfirmationPopupState(false);
      setActionData(updatedData);
    }
    setActionData(updatedData);
  };

  useEffect(() => {
    if (showCheckBox === true) {
      setColSpanValue(7);
    }
  }, [showCheckBox]);

  const theme = useTheme();

  return (
    <>
      <TableRow
        className={`child-tr ${
          modelIndex % 2 === 0 ? "row-white" : "row-dark"
        }`}
      >
        {showCheckBox ? (
          <TableCell className="checkbox-cell fixed-cell">
            <Checkbox label="" />
          </TableCell>
        ) : null}
        <TableCell className="indent-cell second-level-cell">
          <Box theme={theme} className="group-title">
            {open2 ? (
              <>
                <Icon
                  name="ChevronDown"
                  className="toggle-btn ch-right"
                  onClick={() => setOpen2(!open2)}
                />
              </>
            ) : (
              <>
                <Icon
                  name="ChevronDown"
                  className="toggle-btn"
                  onClick={() => setOpen2(!open2)}
                />
              </>
            )}
            {assetModel.model_name}
          </Box>
        </TableCell>

        <TableCell className="img-cell" />
        <TableCell>{assetModel.description}</TableCell>
        <TableCell>{assetModel.model_code}</TableCell>
        <TableCell>
          <Switch
            id={"modelStatus" + assetModel.model_name}
            theme={theme}
            varient={"basic"}
            label={assetModel.is_active ? "Enabled" : "Disabled"}
            switchEnabled={assetModel.is_active}
            onChange={(event) => handleEnableChange(assetModel, event)}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            disablePortal
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: assetModel,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                id={"modelEllipses" + assetModel.model_name}
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {/* Level 3 Child Table Start*/}
      {open2 &&
        assetTrims.map((item, index) => (
          <AssetTrimTable
            onEditAssetTrim={(data) => onEditAssetTrim(data)}
            key={index}
            assetTrim={item}
            showCheckBox={showCheckBox}
            handleImage={handleImage}
          />
        ))}
      {(confirmationPopupState || confirmationPopupStateDisable) && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState || confirmationPopupStateDisable}
          onConfirm={
            confirmationPopupState ? onDeleteConfirm : onDisableConfirm
          }
          setPopUpState={
            confirmationPopupState
              ? setConfirmationPopupState
              : setConfirmationPopupStateDisable
          }
          confirmationTitle={`Are you sure you want to ${
            confirmationPopupState ? "delete" : "update"
          }?`}
          confirmationText={`${
            confirmationPopupState
              ? "Once deleted, you cannot undo this action."
              : ""
          }`}
          icon={
            confirmationPopupState ? (
              <Icon name="DialogDeleteIcon" />
            ) : (
              <Icon name="BellIcon" />
            )
          }
          primaryBtnText={confirmationPopupState ? "Delete" : "Update"}
        />
      )}
    </>
  );
};

export default AssetModelTable;
