import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SortDropdownWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  display: "flex",
  marginBottom: 24,
  fontWeight: theme.customVariables.fontWeightSemiBold,
  fontSize: 14,
  ".u-form-group": {
    width: 200,
    ".u-form-control": {
      height: "auto !important",
      display: "inline-block",
      "&.u-select": {
        ".MuiInputBase-input": {
          padding: "1px 20px 0 5px !important",
          color: theme.palette.grey[900],
          fontWeight: theme.customVariables.fontWeightSemiBold,
          width: "auto",
          float: "left",
        },
        ".MuiSelect-icon": {
          right: "0 !important",
          position: "initial !important",
          float: "left",
          marginLeft: -20,
          path: {
            stroke: theme.palette.grey[900],
          },
        },
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: "0 solid transparent",
      },
    },
    ".MuiPopover-root .MuiPaper-root": {
      maxWidth: "initial",
      // transformOrigin: "initial !important",
      // left: "-80px !important"
    },
  },
  ".custom-radio-wrap": {
    margin: "1px 0 0 10px",
    ".MuiFormGroup-row .u-custom-control-label": {
      marginBottom: 0,
    },
  },
}));
