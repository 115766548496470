import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IPreQualification } from "Interface";

export const getAllPreQualificationFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/pre-qualification/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_Pre_qualification_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_PRE_QUALIFICATION_FILTER, columnFilters],
    async () => {
      return getAllPreQualificationFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const getActivePreQualificationFromServer = () => {
  return ConfigurationAPI.get("/pre-qualification/active").then((res) => {
    return res.data;
  });
};

export const getAllPreQualificationsFromServer = () => {
  return ConfigurationAPI.get("/pre-qualification").then((res) => {
    return res.data;
  });
};

export const getAllCreditScoreFromServer = () => {
  return ConfigurationAPI.get("/credit-ratings").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetActivePreQualification = () => {
  return useQuery(QueryKeys.GET_ALL_PRE_QUALIFICATION_ACTIVE, async () => {
    return getActivePreQualificationFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_GetAllPreQualifications = () => {
  return useQuery(QueryKeys.Get_All_PRE_QUALIFICATION, async () => {
    return getAllPreQualificationsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getAllCreditRatings = () => {
  return useQuery(QueryKeys.GET_ALL_CREDIT_RATINGS, async () => {
    return getAllCreditScoreFromServer().then((data) => {
      return data;
    });
  });
};

export const UseMutation_AddPreQualification = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IPreQualification>(
    (pre) => {
      return ConfigurationAPI.post("/pre-qualification", pre);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_PRE_QUALIFICATION_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.Get_All_PRE_QUALIFICATION);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdatePreQualification = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IPreQualification>(
    (prequalification: any) => {
      return ConfigurationAPI.patch(
        `/pre-qualification/${prequalification.id}`,
        prequalification
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_PRE_QUALIFICATION_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.Get_All_PRE_QUALIFICATION);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeletePreQualification = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IPreQualification>(
    (prequalificationId) => {
      return ConfigurationAPI.delete(
        `/pre-qualification/${prequalificationId}`
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_PRE_QUALIFICATION_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.Get_All_PRE_QUALIFICATION);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
