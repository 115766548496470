import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  DualSlider,
  Input,
  Box,
  Select,
  Button,
  ConfirmationDialog,
} from "@sharedComponents";
import { Icon, PersistentDrawer } from "@ntpkunity/controls";
import { INewContractAllowedAnnumMileagePopUp } from "./AddNewContractAllowedAnnumMileage.type";
import { IContractAllowedAnnumMileage } from "../../Interface/IContractAllowedAnnumMileage";
import { Controller, useForm } from "react-hook-form";
import { logout } from "@ntpkunity/controls-ums";
import {
  UseMutation_AddContractAllowedAnnumMileage,
  UseMutation_UpdateContractAllowedAnnumMileage,
} from "services";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";

const AddNewContractAllowedAnnumMileage: FC<
  INewContractAllowedAnnumMileagePopUp
> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  enabledPin,
  contractAllowedAnnumMileageData,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [navigationState, setNavigationState] = useState<any>();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<IContractAllowedAnnumMileage>();
  const navigate = useNavigate();
  const { actions } = useStoreContext();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const { mutate: AddContractAllowedAnnumMileage } =
    UseMutation_AddContractAllowedAnnumMileage();
  const { mutate: UpdateContractAllowedAnnumMileage } =
    UseMutation_UpdateContractAllowedAnnumMileage(getValues("id"));

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", contractAllowedAnnumMileageData?.id);
    }
    setValue("code", contractAllowedAnnumMileageData?.code);
    setValue("value", contractAllowedAnnumMileageData?.value);
    setValue(
      "is_active",
      contractAllowedAnnumMileageData
        ? contractAllowedAnnumMileageData.is_active
        : true
    );
  }, [contractAllowedAnnumMileageData]);


  function onSubmit(data) {
    const handleError = (error) => {
      const errorDetail = error?.response?.data?.detail;
      
      if (errorDetail) {
        if (error?.response?.data?.detail?.message === "Code and Value should be unique" ||
          errorDetail.code && errorDetail.value
        ) {
          setError("code", { message: "Code should be unique" });
          setError("value", { message: "Value should be unique" });
        } else if (errorDetail.message === "Code should be unique" ||
          errorDetail.code
        ) {
          setError("code", { message: "Code should be unique" });
        } else if (errorDetail.message === "Value should be unique" ||
          errorDetail.value
        ) {
          setError("value", { message: "Value should be unique" });
        } else {
          onClose();
          actions.setToast({
            toastMessage: errorDetail.message || "An unknown error occurred",
            toastState: true,
            variant: "error",
          });
        }
      } else {
        onClose();
        actions.setToast({
          toastMessage: "An unknown error occurred",
          toastState: true,
          variant: "error",
        });
      }
    };
  
    if (actionType === ActionOptions.ADD) {
      AddContractAllowedAnnumMileage(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: handleError,
      });
    } else if (actionType === ActionOptions.EDIT) {
      UpdateContractAllowedAnnumMileage(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: handleError,
      });
    }
  }
  
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
  };

  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        className="header-p-sm footer-btn-p-sm"
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                required: ValidationMessages.CODE_REQUIRED,
                maxLength: {
                  value: 5,
                  message: ValidationMessages.CODE_MAX_LENGTH,
                },
                pattern: {
                  value: /^[^\s]/,
                  message: ValidationMessages.CODE_REQUIRED,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Code"}
                  type="text"
                  id="code"
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e.replace(/[^a-zA-Z0-9]/gi, ""));
                  }}
                  error={errors?.code?.message}
                />
              )}
            />
            <Controller
              name="value"
              control={control}
              rules={{
                required: ValidationMessages.VALUE_REQUIRED,
                maxLength: {
                  value: 6,
                  message: ValidationMessages.VALUE_MAX_LENGTH,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  placeholder={"0"}
                  label={"Value"}
                  fullWidth={true}
                  type="text"
                  value={value}
                  onChange={(e) => {
                    onChange(e.replace(/[^0-9]/g, ""));
                  }}
                  error={errors?.value?.message}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddNewContractAllowedAnnumMileage;
