import { useTheme } from "@mui/material";
import { IGridProps, unityTheme, Grid } from "@ntpkunity/controls";
import { FC } from "react";

const Component: FC<Partial<IGridProps>> = (props) => {
  const theme = useTheme();

  return <Grid theme={theme} {...props} />;
};

export default Component;
