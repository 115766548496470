import { StoreActionType } from "Types";
import { IAssetModel } from "Interface";
import { ActionTypes } from "Enums";

export const AssetModelReducer = (
  state: Array<IAssetModel> = [],
  action: StoreActionType
): Array<IAssetModel> => {
  switch (action.type) {
    case ActionTypes.ADD_ASSET_MODEL:
      return [...state, action.payload];

    case ActionTypes.SET_ASSET_MODEL_INIT_STATE:
      return [...action.payload];

    case ActionTypes.SET_ASSET_MODEL_BY_MAKE:
      const modelsToRetain = state.filter(
        (models) => models.make_id !== action.optionalData.makeId
      );
      return [...modelsToRetain, ...action.payload];

    case ActionTypes.UPDATE_ASSET_MODEL:
      const indexToUpdate = state.findIndex(
        (assetModel) => assetModel.id == action.payload?.model_id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
