import IActions from "../configs";
import { Dispatch, ReducerAction } from "react";
import { IDealerFee } from "Interface";

export const SET_DEALER_FEE_INIT_STATE = "GET DEALER FEE DATA FROM SERVER";
export const DELETE_DEALER_FEE_ROW = "DELETE DEALER FEE ROW";
export const UPDATE_DEALER_FEE_ROW = "UPDATE DEALER FEE ROW";
export const ADD_DEALER_FEE_ROW = "ADD DEALER FEE ROW";
export const initialState: IDealerFee[] = [];

const dealerFeeReducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case SET_DEALER_FEE_INIT_STATE:
      return action.payload as typeof initialState;
    case UPDATE_DEALER_FEE_ROW: {
      const index = state.findIndex(
        (dealerFee) => dealerFee.id === (action.payload as IDealerFee).id
      );
      state[index] = action.payload as any;
      return state;
    }
    case DELETE_DEALER_FEE_ROW: {
      const index = state.findIndex(
        (dealerFee) => dealerFee.id === (action.payload as IDealerFee).id
      );
      state.splice(index, 1) == (action.payload as any);
      return state;
    }
    case ADD_DEALER_FEE_ROW: {
      state.unshift(action.payload as any);
      return state;
    }
  }
  return state;
};

export const updateDealerFeeData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof dealerFeeReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_FEE_INIT_STATE, payload: data });
  }
};
export const deleteDealerFeeDataRow = (
  data: IDealerFee,
  dispatch: Dispatch<ReducerAction<typeof dealerFeeReducer>>
): void => {
  if (data) {
    dispatch({ type: DELETE_DEALER_FEE_ROW, payload: data });
  }
};

export const updateDealerFeeDataRow = (
  data: IDealerFee,
  dispatch: Dispatch<ReducerAction<typeof dealerFeeReducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_DEALER_FEE_ROW, payload: data });
  }
};

export const AddDealerFeeDataRow = (
  data: IDealerFee,
  dispatch: Dispatch<ReducerAction<typeof dealerFeeReducer>>
): void => {
  if (data) {
    dispatch({ type: ADD_DEALER_FEE_ROW, payload: data });
  }
};
export default dealerFeeReducer;
