import { styled } from "@mui/material/styles";
export const DialogWrap: any = styled("div")(() => ({
  width: "100%",
  ul: {
    margin: "0 0 0 15px",
    padding: 0,
    width: "100%",
    li: {
      width: "100%",
      margin: "0 0 10px 0",
      textAlign: "left",
    },
  },
}));
