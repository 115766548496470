import { FC, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material";
import {
  Button,
  Select,
  Input,
  FileDragDrop,
  FilePreview,
  Textarea,
  Box,
  PersistentDrawer,
  MultiSelect,
  Icon,
  CircleLoader,
  Tooltip,
  ImageViewer,
} from "@ntpkunity/controls";
import {
  // PersistentDrawer,
  ConfirmationDialog,
  FileDragDropWrap,
  MultiSelectWrap,
  //MultiSelect,
  TextAreaWrap,
  TextBox,
} from "@sharedComponents";
import {
  useSaveFinancialInsurance,
  useUpdateFinancialInsuranceById,
} from "services/financial-insurance.service";
import {
  useGetProductByProviderId,
  useGetProvider,
  usePenProductRegister,
} from "services/integration-api.service";
import { logout } from "@ntpkunity/controls-ums";
import { IDealerProfile, IFinancialInsurance, IImage } from "Interface";
import { FinancialInsurancePopup } from "./financial-insurance-popup.type";
import { Controller } from "react-hook-form";
import {
  ActionOptions,
  ActionType,
  ActionTypes,
  AvailableFinanceTypes,
  QueryKeys,
  DialogMessages,
  ValidationMessages,
  FinancialInsuranceCategory,
} from "Enums";
import {
  UseMutation_uploadS3Image,
  UseMutation_deleteS3Image,
  useMutation_CheckS3Credentials,
  useGetProfilebyDealerCode,
} from "services";
import { validation } from "shared/helper/methods";
import { WEBSITE_REGEX } from "shared/config/constants";
import { usePrompt } from "utilities/usePrompt";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  UseQueryGetDealerIntergrations,
  UseQueryGetDealerProviders,
} from "services/dealer-integration.service";
import { setOpenDrawer } from "Stores/SetupStore/Actions";	
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

const NewFinancialInsurance: FC<FinancialInsurancePopup> = ({
  popUpState,
  actionType,
  financialInsuranceData,
  setPopUpState,
  form,
  dealer_code,
  enabledPin,
  title,
  setImages,
  images,
}) => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const { actions } = useStoreContext();
  const [applyValidation, setApplyValidation] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { mutate: SaveFinancialInsurance } = useSaveFinancialInsurance();
  const { mutate: getDealerProfile, data: profile_data } =
    useGetProfilebyDealerCode();
  const { mutate: setProductRegister } = usePenProductRegister();
  const { mutate: getProvider, data: provider_data } = useGetProvider();
  const { mutate: getProduct, data: product_data } =
    useGetProductByProviderId();
  const { mutate: UpdateFinancialInsurance } =
    useUpdateFinancialInsuranceById();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [product, setProduct] = useState([]);
  const [financeTypes, setFinanceTypes] = useState([]);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [visibility, setVisibility] = useState(false);
  const [sliderImages, setSilderImages] = useState([]);
  const [index, setIndex] = useState(0);
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const { data: dealerIntegrations, isFetched: isIntegrationsFetched } =
    UseQueryGetDealerIntergrations(dealer_code);
  const { data: dealerProvider } = UseQueryGetDealerProviders(dealer_code);

  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();

  useEffect(() => {
    if (actionType === ActionOptions.ADD && popUpState) {
      setApplyValidation(false);
    }
  }, [popUpState]);
  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);

  const categoriesArray = [
    { code: "-1", name: "Others" },
    { code: "2", name: "GAP Insurance" },
    { code: "3", name: "Tire & Wheel Protection" },
    { code: "4", name: "EVP/EMP- Extended Service Contract" },
    { code: "5", name: "Windshield Protection" },
    { code: "6", name: "Dent Protection" },
    { code: "7", name: "Lease Protection" },
    { code: "8", name: "Key Protection" },
    { code: "9", name: "CPO Wrap" },
    { code: "10", name: "Presence Protection" },
    { code: "11", name: "All Surface Protection" },
    { code: "12", name: "BMW Bundle" },
    { code: "13", name: "3rd Party Bundle" },
    { code: "14", name: "Maintenance Agreement 1" },
    { code: "27", name: "Maintenance Agreement 2" },
    { code: "28", name: "Other 1" },
    { code: "29", name: "Other 2" },
    { code: "30", name: "Other 3" },
  ];

  const categories = categoriesArray.map(({ code, name }) => ({
    text: name,
    value: name,
  }));

  useEffect(() => {
    if (isIntegrationsFetched && dealerProvider)
      getDealerProfile(
        { dealer_code: dealer_code },
        {
          onSuccess: (response: IDealerProfile) => {
            if (
              response &&
              response.pen_dealer_id &&
              isIntegrationActive("F&I Products", "PEN")
            ) {
              getProvider(dealer_code);
              setIsDisabled(false);
            } else {
              setIsDisabled(true);
            }
          },
        }
      );
  }, [dealer_code, isIntegrationsFetched, dealerProvider]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    resetField,
    formState: { errors, isDirty },
    getValues,
  } = form;

  const isIntegrationActive = (integration_type, provider_name) => {
    let is_active = false;

    const integration = dealerIntegrations?.find(
      (integration) => integration.integration_type === integration_type
    );

    integration?.providers?.map((provider) => {
      const dealer_provider = dealerProvider?.filter(
        (item) => item?.company_provider_id == provider?.id
      );
      if (
        provider?.provider_name == provider_name &&
        dealer_provider?.length == 1
      ) {
        is_active = provider?.is_active;
      }
    });
    return is_active;
  };
  const onSubmit = (data: any) => {

    const categoryName = data.category;

    const category = categoriesArray.find(item => item.name === categoryName);
    if (category) {
      data.category_code = category.code;
    } else {
      data.category_code = null;
    }
    if (!isDisabled) {
      if (actionType === ActionOptions.ADD) {
        data.applicable_finance_type = financeTypes;
        data.dealer_code = dealer_code;
        data.image = images;
        SaveFinancialInsurance(
          {
            ...data,
          },
          {
            onSuccess(response: IFinancialInsurance) {
              reset();
              const penProductRegisterData = {
                action: "REGISTER",
                pen_dealer_id: profile_data?.pen_dealer_id,
                pen_product_id: data?.product_id,
                provider_dealer_code: data?.provider_code,
                provider_product_code: "",
                contract_prefix_override: "",
                dealer_code: dealer_code,
              };

              setProductRegister(
                { penProductRegisterData },
                {
                  onSuccess(response: any) {
                    if (response.success === "true") {
                      onClose();
                    } else {
                      actions.setToast({
                        toastMessage: response?.error?.description,
                        toastState: true,
                        variant: "error",
                      });
                      return;
                    }
                  },
                  onError(error) {
                    actions.setToast({
                      toastMessage: error?.error?.toString(),
                      toastState: true,
                      variant: "error",
                    });
                  },
                }
              );
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error?.error?.toString(),
                toastState: true,
                variant: "error",
              });
            },
          }
        );
      } else if (actionType === ActionOptions.EDIT) {
        data.applicable_finance_type = financeTypes;
        data.image = images;
        UpdateFinancialInsurance(
          {
            ...data,
          },
          {
            onSuccess(response: IFinancialInsurance) {
              reset();
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error?.error?.toString(),
                toastState: true,
                variant: "error",
              });
            },
          }
        );
      }
      onClose()
    }
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const handleChange = () => {
    setValue("product_type", "");
    setValue("product_id", "");
  };

  const onClickIcon = (indexNumber) => {
    const image_url = images?.map((image) => {
      return image?.location;
    });
    setIndex(indexNumber);
    setSilderImages(image_url);
    setVisibility(true);
  };
  const handleClose = () => setVisibility(false);

  useEffect(() => {
    if (actionType === ActionOptions.EDIT) {
      setFinanceTypes(financialInsuranceData?.applicable_finance_type);
      if (financialInsuranceData?.taxable) {
        setApplyValidation(true);
      } else {
        setApplyValidation(false);
      }
      setImages(financialInsuranceData?.image);
      getProduct(
        { id: financialInsuranceData.provider_id, dealer_code: dealer_code },
        {
          onSuccess(response) {
            setProduct(
              response?.filter((fi: any) => {
                return fi.product_type == financialInsuranceData.product_type;
              })
            );
          },
        }
      );
    } else {
      setApplyValidation(false);
      setFinanceTypes([]);
      // setImages([]);
    }
  }, [financialInsuranceData]);

  const taxable = getValues("taxable");
  useEffect(() => {
    if (taxable == false) {
      setFinanceTypes([]);
    }
  }, [taxable]);

  const dataURLtoFile = (dataurl: string, filename: string) => {
    if (dataurl && dataurl.length > 0) {
      var arr = dataurl.split(",") || [],
        mime = arr[0].match(/:(.*?);/),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime ? mime[1] : "" });
    }
  };

  const onFinanceTypeSelection = (e: any) => {
    setFinanceTypes(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onimageDrop = (e: any) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const addFilesForPreview = (files: any) => {
    let fileReader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      fileReader.readAsDataURL(files[i]);
      fileReader.onload = (event) => {
        setImages([...images, files[i]]);
      };
    }
  };

  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const imageArray = images;
          imageArray.splice(index, 1);
          setImages([...imageArray]);
          setImageLoader(false);
        },
      });
    }
  };
  const onSaveConfirm = (e: any) => {
    form.handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        customFooter={
          <Button
            theme={theme}
            primary
            onClick={handleSubmit(onSubmit)}
            text="Save"
            fullWidth
          />
        }
      >
        <Controller
          name="provider_id"
          control={control}
          defaultValue={""}
          rules={{
            required: "Provider is required",
          }}
          render={({ field }) => (
            <Select
              id="providerDrawer"
              theme={theme}
              label={"Provider"}
              items={provider_data?.map((fi: any) => {
                return {
                  value: parseInt(fi.provider_id),
                  text: fi.name,
                };
              })}
              {...field}
              value={
                provider_data?.find((x) => x.provider_id == field.value)
                  ? field.value
                  : ""
              }
              onChange={(e: any, index: any) => {
                field.onChange(e, index);
                setValue("provider_name", index.props.children);
                getProduct({
                  id: watch("provider_id"),
                  dealer_code: dealer_code,
                });
                setProduct(null);
                handleChange();
              }}
              sxProps={""}
              selectError={errors?.provider_id?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="provider_code"
          control={control}
          rules={{
            pattern: {
              value: /^[^\s]/,
              message: ValidationMessages.PROVIDER_CODE_REQUIRED,
            },
            required: ValidationMessages.PROVIDER_CODE_REQUIRED,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth={true}
              placeholder={"Type here..."}
              label={"Provider Code"}
              type="text"
              value={value?.trimStart()}
              onChange={onChange}
              error={errors?.provider_code?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="product_type"
          control={control}
          rules={{
            required: "Product type is required",
          }}
          defaultValue={""}
          render={({ field }) => (
            <Select
              id="productTypeDrawer"
              theme={theme}
              label={"Product Type"}
              items={product_data
                ?.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (item) => item?.product_type === obj?.product_type
                    )
                )
                ?.map((fi: any) => {
                  return {
                    value: fi.product_type,
                    text: fi.product_type,
                  };
                })}
              {...field}
              value={
                product_data?.find((x) => x.product_type == field.value)
                  ? field.value
                  : ""
              }
              onChange={(e: any, index: any) => {
                field.onChange(e, index);
                setProduct(
                  product_data?.filter((fi: any) => {
                    return fi.product_type == watch("product_type");
                  })
                );
                setValue("product_id", "");
              }}
              sxProps={""}
              selectError={errors?.product_type?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="product_id"
          rules={{
            required: "Product name is required",
          }}
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <Select
              id="productIdDrawer"
              theme={theme}
              label={"Product Name"}
              items={product?.map((fi: any) => {
                return {
                  value: parseInt(fi.product_id),
                  text: fi.name,
                };
              })}
              {...field}
              value={
                product?.find((x) => x.product_id == field.value)
                  ? field.value
                  : ""
              }
              onChange={(e: any, index: any) => {
                field.onChange(e, index);
                setValue("product_name", index.props.children);
              }}
              sxProps={""}
              selectError={errors?.product_id?.message}
              disabled={isDisabled}
            />
          )}
        />
        <TextAreaWrap>
          <Controller
            name="description"
            control={control}
            rules={{
              required: "Description is required",
            }}
            render={({ field }) => (
              <Textarea
                theme={theme}
                label={"Description"}
                type="text"
                id="descriptionDrawer"
                rows={3}
                fullWidth
                {...field}
                value={field.value?.trimStart()}
                error={errors?.description?.message}
                disabled={isDisabled}
              />
            )}
          />
        </TextAreaWrap>
        <Controller
          name="category"
          control={control}
          rules={{
            required: "Category is required",
          }}
          defaultValue=""
          render={({ field }) => (
            <Select
              id="CategoryTypeDrawer"
              theme={theme}
              label="Category"
              items={categories}
              {...field}
              value={field.value}
              onChange={field.onChange}
              sxProps={{}}
              selectError={errors?.category?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="markup"
          defaultValue={0}
          control={control}
          rules={{
            required: "Markup value is required",
            min: {
              value: -100,
              message: "Value should be in range -100 to 100",
            },
            max: {
              value: 100,
              message: "Value should be in range -100 to 100",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth={true}
              placeholder={"Type here.."}
              label={"Markup Percentage"}
              type="text"
              endAdornment={
                <>
                  <div className="MuiInputAdornment-root">%</div>
                </>
              }
              startAdornment=""
              id="markupDrawer"
              onChange={(e) => {
                onChange(e);
              }}
              value={value}
              masking={true}
              maskNumeric={true}
              maskDecimalScale={2}
              maskAllowNegative
              error={errors?.markup?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="taxable"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <Select
              id="taxableDrawer"
              theme={theme}
              label={"Taxable"}
              items={[
                { text: "Applicable", value: true },
                { text: "Not Applicable", value: false },
              ]}
              value={value}
              onChange={(e) => {
                onChange(e);
                if (e.target.value === true) {
                  setApplyValidation(true);
                } else {
                  setApplyValidation(false);
                  setFinanceTypes([]);
                }
              }}
              sxProps={""}
              disabled={isDisabled}
            />
          )}
        />
        <MultiSelectWrap>
          <Controller
            name="applicable_finance_type"
            control={control}
            defaultValue={financeTypes || []}
            rules={{
              required: {
                value: watch("taxable") == true,
                message: ValidationMessages.APPLICABLE_FINANCE_TYPE_REQUIRED,
              },
            }}
            render={({ field }) => (
              <MultiSelect
                id="applicableFinanceTypeDrawer"
                theme={theme}
                label={"Applicable Finance Type(s)"}
                items={[
                  { text: "Finance", value: "Finance" },
                  { text: "Lease", value: "Lease" },
                ]}
                {...field}
                value={financeTypes || []}
                onChange={(e) => {
                  onFinanceTypeSelection(e);
                  field.onChange(e);
                }}
                disabled={!applyValidation || isDisabled}
                sxProps={""}
                selectError={errors?.applicable_finance_type?.message}
              />
            )}
          />
        </MultiSelectWrap>
        <FileDragDropWrap className="mb-24">
          <Controller
            name="image"
            control={control}
            render={({ field }) => (
              <Tooltip
                theme={theme}
                title={
                  !s3Response?.data
                    ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                    : ""
                }
              >
                <FileDragDrop
                  {...field}
                  hoverLabel="Drag and drop product images, or browse"
                  width="300"
                  height="200px"
                  backgroundColor="white"
                  onDrop={onimageDrop}
                  onChange={onimageUpload}
                  disabled={!s3Response?.data}
                  theme={theme}
                />
              </Tooltip>
            )}
          />
          <FilePreview
            files={images.length > 0 ? images : []}
            onRemoveFile={onRemoveFile}
            theme={theme}
            viewIcon={true}
            onClickIcon={onClickIcon}
            preview="image"
          />
          {imageLoader && (
            <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
              <CircleLoader theme={theme} size="xs" />
            </ImgLoader>
          )}
        </FileDragDropWrap>
        <Controller
          name="video_url"
          control={control}
          defaultValue={""}
          rules={validation(
            "Video Url",
            false,
            true,
            WEBSITE_REGEX,
            "Valid URL is required."
          )}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              label={"Video URL (Optional)"}
              type="text"
              id="videoUrl"
              fullWidth
              onChange={onChange}
              value={value?.trimStart()}
              error={errors?.video_url?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="brochure_url"
          control={control}
          rules={validation(
            "Brochure Url",
            false,
            true,
            WEBSITE_REGEX,
            "Valid URL is required."
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Brochure URL (Optional)"}
              type="text"
              id="brochureUrl"
              fullWidth
              {...field}
              value={field?.value?.trimStart()}
              error={errors?.brochure_url?.message}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="MSP"
          control={control}
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Maintenance & Services Plan"}
              placeholder={"Select"}
              items={[
                { text: "Yes", value: true },
                { text: "No", value: false },
              ]}
              value={value}
              onChange={onChange}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          name="is_active"
          control={control}
          defaultValue={true}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              sxProps={""}
              label={"Status"}
              placeholder={"Select"}
              items={[
                { text: "Enabled", value: true },
                { text: "Disabled", value: false },
              ]}
              value={value}
              onChange={onChange}
              disabled={isDisabled}
            ></Select>
          )}
        />
      </PersistentDrawer>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={sliderImages}
        isLoading={false}
        visible={visibility}
        onClose={handleClose}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};

export default NewFinancialInsurance;
