import { StoreActionType } from "Types";
import { IInterestCharts } from "Interface";
import { ActionTypes } from "Enums";

export const InterestChartReducer = (
  state: Array<IInterestCharts> = [],
  action: StoreActionType
): Array<IInterestCharts> => {
  switch (action.type) {
    case ActionTypes.ADD_INTEREST_CHARTS:
      return [...state, action.payload];

    case ActionTypes.SET_INTEREST_CHART_INIT_STATE:
      return [...action.payload];

    case ActionTypes.SET_INTEREST_CHART_BY_CHART:
      const interestChartToRetrain = state.filter(
        (interestChart) =>
          interestChart.chart_id != action.optionalData.chart_id
      );
      return [...interestChartToRetrain, ...action.payload];

    case ActionTypes.UPDATE_INTEREST_CHARTS:
      const indexToUpdate = state.findIndex(
        (interestChart) => interestChart.id == action.payload?.id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
