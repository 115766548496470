export enum FinancialInsuranceCategory {
  GapInsurance = "Gap Insurance",
  TireAndWheelProtection = "Tire & Wheel Protection",
  EVP_EMP_ExtendedServiceContracts = "EVP/EMP Extended Service Contracts",
  WindshieldProtection = "Windshield Protection",
  DentProtection = "Dent Protection",
  LeaseProtection = "Lease Protection",
  KeyProtection = "Key Protection",
  CPOWrap = "CPO Wrap",
  PresenceProtection = "Presence Protection",
  AllSurfaceProtection = "All Surface Protection",
  OEMBundle = "OEM Bundle",
  ThirdPartyBundle = "3rd Party Bundle",
  MaintenanceAgreement = "Maintenance Agreement",
  Others = "Others",
}
