import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageHeader: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-page-header": {
    display: "flex",
    width: "100%",
    paddingTop: 24,
    paddingBottom: 24,
    fontFamily: theme.typography.fontFamily,
    ".back-btn": {
      paddingLeft: "0 !Important",
      marginRight: 12,
    },
    h3: {
      height: 42,
      color:
        theme.palette.mode === "dark"
          ? [theme.palette.common.white]
          : [theme.palette.common.black],
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    "&.border-bottom": {
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    ".title": {
      float: "left",
    },
    ".counter": {
      minWidth: 40,
      height: 40,
      borderRadius: 40,
      lineHeight: "40px",
      padding: "0 5px",
      display: "inline-block",
      textAlign: "center",
      color: "white",
      fontWeight: theme.typography.subtitle1.fontWeight,
      fontSize: theme.typography.subtitle1.fontSize,
      backgroundColor: theme.palette.primary.main,
      position: "relative",
      marginLeft: 4,
      float: "left",
    },
    ".caption": {
      width: "100%",
      display: "block",
      clear: "both",
      color: theme.palette.grey[600],
    },
    ".stack": {
      display: "inline-flex",
      marginRight: 20,
      ".MuiDivider-root": {
        borderColor: theme.palette.grey[100],
        height: 24,
        marginTop: 20,
      },
      ".search": {
        width: "25%",
        display: "inline-block",
        borderBottom: "1px solid" + theme.palette.divider,
        backgroundColor: theme.palette.common.white,
        position: "sticky",
        top: -60,
        right: 0,
        zIndex: 1,
        float: "right",
        paddingLeft: 24,
        paddingTop: 8,
        ".MuiOutlinedInput-notchedOutline": {
          border: "0 solid transparent",
          borderRadius: 0,
        },
        ".Mui-focused fieldset": {
          border: "0 solid transparent",
        },
        ".MuiInputAdornment-root": {
          position: "absolute",
          right: 0,
          zIndex: -1,
          opacity: 0.75,
        },
        ".MuiOutlinedInput-input": {
          paddingRight: "15px !important",
        },
      },
    },
    ".action-area": {
      ".btn": {
        "&.with-icon": {
          display: "inline-flex",
        },
        "&:not(:first-of-type)": {
          marginLeft: 16,
        },
      },
    },
    ".btn": {
      "&.with-icon": {
        display: "inline-flex",
      },
    },
    ".btn-secondary": {
      margin: "0 0px 0 0",
      display: "inline-flex !important",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
    ".btn-primary": {
      margin: "0 0px 0 0",
      display: "inline-flex !important",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
    ".btn-primary, .btn-secondary": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: 10,
      },
    },
  },
}));
