import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useSetupsStore, useStoreContext } from "../Stores/SetupStore/Store";
import { setAssetTrimByModel } from "../Stores/SetupStore/Actions";
import { IAssetTrim } from "../Interface";

export const getAllAssetTrimsFromServer = () => {
  return ConfigurationAPI.get("/asset-trim").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllAssetTrims = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_TRIMS, () => {
    return getAllAssetTrimsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAssetTrimsByModel = (model_id) => {
  return ConfigurationAPI.get(`/asset-trim/by-model/${model_id}`).then(
    (res) => {
      return res.data;
    }
  );
};

export const UseQuery_GetTrimsByModel = (model_id, options: any = {}) => {
  const [, dispatch] = useSetupsStore();

  return useQuery(
    QueryKeys.GET_ASSET_TRIM_BY_MODEL,
    () => {
      return getAssetTrimsByModel(model_id).then((data) => {
        dispatch(setAssetTrimByModel(data, model_id));
        return data;
      });
    },
    options
  );
};

export const useMutation_AddNewAssetTrim = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IAssetTrim>>(
    (assetTrim) => {
      return ConfigurationAPI.post("/asset-trim", assetTrim);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_TRIMS);

        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useMutation_AddNewAssetTrimMultiple: any = () => {
  const queryClient = useQueryClient();
  return useMutation<Array<IAssetTrim>>(
    (assetTrims) => {
      return ConfigurationAPI.post("/asset-trim/add-multiple", assetTrims);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_TRIMS);
      },
    }
  );
};
export const useMutation_BulkAddAssetCatalogue: any = () => {
  const queryClient = useQueryClient();
  return useMutation<any>(
    (obj: any) => {
      return ConfigurationAPI.post(
        `/asset-list-import/${obj.tenant_id}`,
        obj.files
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
      },
    }
  );
};

export const useMutation_UpdateAssetTrim: any = (trimId) => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IAssetTrim>>(
    (assetTrim: any) => {
      return ConfigurationAPI.patch(`/asset-trim/${assetTrim.id}`, assetTrim);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_TRIMS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useMutation_DeleteAssetTrim: any = () => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<Array<IAssetTrim>>(
    (trimId: any) => {
      return ConfigurationAPI.delete(`/asset-trim/${trimId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_MAKES_MODELS_TRIMS
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ASSET_TRIMS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_CheckDuplicateTrimCode: any = () => {
  return useMutation<IAssetTrim>(
    (trim_code) => {
      return ConfigurationAPI.get(`/asset-trim/trim_code/${trim_code}`);
    },
    {
      onSuccess: async (response: any) => {
        return response.data;
      },
    }
  );
};
