import { IAnnualMileage, IAnnualMileageFilter } from "Interface";
import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";

export const addAnnualMileage = (
  annualMileages: IAnnualMileage
): StoreActionType => {
  return { type: ActionTypes.ADD_ANNUAL_MILEAGE, payload: annualMileages };
};

export const setAnnualMileageInitialData = (
  annualMileages: Array<IAnnualMileage>
): StoreActionType => {
  return {
    type: ActionTypes.SET_ANNUAL_MILEAGES_INIT_STATE,
    payload: annualMileages,
  };
};

export const setAnnualMileageFilterInitialData = (
  annualMileages: IAnnualMileageFilter
): StoreActionType => {
  return {
    type: ActionTypes.SET_ANNUAL_MILEAGES_FILTER_INIT_STATE,
    payload: annualMileages,
  };
};
