import { useStoreContext } from "Stores/SetupStore/Store";
import { useEffect } from "react";

interface ILocalStorage {}
interface ISessionStorage {}

export const LOCAL_STORAGE_ITEM_KEY = "settings";
export const SESSION_STORAGE_ITEM_KEY = "unity";

/** Local Storage Hook */
export const useLocalStorage = () => {
  const {
    states: { localStorage: storage },
    actions: { setLocalStorage },
  } = useStoreContext();

  useEffect(() => {
    setLocalStorage(
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY) ?? "null")
    );
  }, []);

  const setItem = (key: string, data: any) => {
    const prevData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_ITEM_KEY) ?? ""
    );
    const newData = { ...prevData, [key]: data };

    localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(newData));
    setLocalStorage(newData);
  };

  const clearStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY);
  };
  const removeItem = (key: string) => {
    const prevData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_ITEM_KEY) ?? ""
    );
    const newData = { ...prevData, [key]: undefined };

    localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(newData));
    setLocalStorage(newData);
  };

  return {
    storage: storage as ILocalStorage,
    setItem,
    clearStorage,
    removeItem,
  };
};

export class LocalStorage {
  getItemByKey(key: string) {
    const data = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_ITEM_KEY) ?? "null"
    );
    if (data?.[key]) return data[key];
    return null;
  }
  setItembyKey(key: string, value: any) {
    const data = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_ITEM_KEY) ?? "null"
    );
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      data
        ? JSON.stringify({ ...data, [key]: value })
        : JSON.stringify({ [key]: value })
    );
  }
  removeItembyKey(key: string) {
    const data = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_ITEM_KEY) ?? "null"
    );
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      JSON.parse({ ...data, [key]: undefined })
    );
  }
  clearStorage() {
    localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY);
  }
}

/** Session Storage Hook */
export const useSessionStorage = () => {
  const {
    states: { sessionStorage: storage },
    actions: { setSessionStorage },
  } = useStoreContext();

  useEffect(() => {
    setSessionStorage(
      JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ITEM_KEY) ?? "null")
    );
  }, []);

  const setItem = (key: string, data: any) => {
    const prevData = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_ITEM_KEY) ?? "null"
    );
    const newData = { ...prevData, [key]: data };

    sessionStorage.setItem(SESSION_STORAGE_ITEM_KEY, JSON.stringify(newData));
    setSessionStorage(newData);
  };

  const clearStorage = () => {
    sessionStorage.removeItem(SESSION_STORAGE_ITEM_KEY);
  };
  const removeItem = (key: string) => {
    const prevData = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_ITEM_KEY) ?? "null"
    );
    const newData = { ...prevData, [key]: undefined };

    sessionStorage.setItem(SESSION_STORAGE_ITEM_KEY, JSON.stringify(newData));
    setSessionStorage(newData);
  };

  return {
    storage: storage as ISessionStorage,
    setItem,
    clearStorage,
    removeItem,
  };
};
