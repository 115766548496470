import { IProgram, IFpAssetGroup } from "Interface";
import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";

export const addProgram = (fp: IProgram): StoreActionType => {
  return { type: ActionTypes.ADD_PROGRAM, payload: fp };
};

export const addFpAssetGroup = (asset: IFpAssetGroup): StoreActionType => {
  return { type: ActionTypes.ADD_FP_ASSET_GROUP, payload: asset };
};

export const removeFpAssetGroup = (
  asset: Array<IFpAssetGroup>
): StoreActionType => {
  return { type: ActionTypes.REMOVE_FP_ASSET_GROUP, payload: asset };
};
