import { StoreActionType } from "Types";
import { IRvChart } from "Interface";
import { ActionTypes } from "Enums";

export const RvChartReducer = (
  state: Array<IRvChart> = [],
  action: StoreActionType
): Array<IRvChart> => {
  switch (action.type) {
    case ActionTypes.ADD_RV_CHART:
      return [...state, action.payload];

    case ActionTypes.SET_RV_CHART_INIT_STATE:
      return [...action.payload];

    case ActionTypes.UPDATE_RV_CHART:
      const indexToUpdate = state.findIndex(
        (rvChart) => rvChart.id == action.payload?.id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
