import { StoreActionType } from "Types";
import { IAnnualMileage, IAnnualMileageFilter } from "Interface";
import { ActionTypes } from "Enums";

export const AnnualMileageReducer = (
  state: Array<IAnnualMileage> = [],
  action: StoreActionType
): Array<IAnnualMileage> => {
  switch (action.type) {
    case ActionTypes.ADD_ANNUAL_MILEAGE:
      return [...state, action.payload];

    case ActionTypes.SET_ANNUAL_MILEAGES_INIT_STATE:
      return [...action.payload];

    case ActionTypes.UPDATE_ANNUAL_MILEAGE:
      const indexToUpdate = state.findIndex(
        (mileage) => mileage.id == action.payload?.id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};

export const AnnualMileageFilterReducer = (
  state: IAnnualMileageFilter,
  action: StoreActionType
): IAnnualMileageFilter => {
  switch (action.type) {
    case ActionTypes.SET_ANNUAL_MILEAGES_FILTER_INIT_STATE:
      return action.payload;

    default:
      return state;
  }
};
