import { useTheme } from "@mui/material";
import { IBoxProps, unityTheme, Box } from "@ntpkunity/controls";
import { FC } from "react";

const Component: FC<Partial<IBoxProps>> = (props) => {
  const theme = useTheme();

  return <Box theme={theme} {...props} />;
};

export default Component;
