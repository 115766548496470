export enum CalculationMethod {
  TARGET_BASE_SUBSIDY = "Target Rate Base Subsidy",
  PERCENTAGE_FROM_DEALER_AND_MANUFACTURER = "% from Dealer and % from Manufacturer",
  ADJUSTER_CUSTOMER_RATE = "Adjusted Customer Rate",
}
export enum PurchaseOptionType {
  ONE_$_OUT = "$1 out",
  FMV = "FMV",
}
export enum SubsidyAmortizationMethod {
  // ANNUITY = "Annuity",
  STRAIGHT_LINE = "Straight line",
  STRAIGHT_LINE_WITH_ACTUAL_NUMBER_OF_DAYS = "Straight line with actual number of days",
  STRAIGHT_LINE_PRO_RATA = "Straight line pro rata",
  ANNUITY_ACTUAL_NUMBER_OF_DAYS = "Annuity - Actual number of days",
}
export enum TaxAmortization {
  YES = "Yes",
  NO = "No",
}
export enum SubsidyOverride {
  YES = "Yes",
  NO = "No",
}
