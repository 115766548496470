import { useMutation } from "react-query";
import { IFeedback } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IntegrationAPI } from "services";
import { ToastMessages } from "Enums";

export const UseMutation_sendFeedbackEmail = (): any => {
  const { actions } = useStoreContext();
  return useMutation<IFeedback>(
    (feedback) => {
      return IntegrationAPI.post("/email/send-feedback-email", feedback);
    },
    {
      onSuccess: async () => {
        actions.setToast({
          toastMessage: ToastMessages.Feedback_Submitted_Successfully,
          toastState: true,
        });
      },
    }
  );
};
