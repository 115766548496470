import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useSetupsStore, useStoreContext } from "../Stores/SetupStore/Store";
import { IChartCriteria, ICriteria } from "../Interface";

export const useMutation_AddNewFeesCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IChartCriteria>>(
    (feesChart) => {
      return ConfigurationAPI.post("/fees-chart/criteria", feesChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewFeesCriteria = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (subsidyChart) => {
      return ConfigurationAPI.post("/fees-chart", subsidyChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const getAllFeesChartsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/fees-chart/paginated/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllFeesCharts_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_FEES_CHARTS_FILTER, columnFilters],
    async () => {
      return getAllFeesChartsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const getAllFeesChartsFromServer = () => {
  return ConfigurationAPI.get("/fees-chart").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllFeesCharts = () => {
  return useQuery(QueryKeys.GET_ALL_FEES_CHARTS, async () => {
    return getAllFeesChartsFromServer().then((data) => {
      return data;
    });
  });
};

export const useMutation_UpdateFeesChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (feesChart: any) => {
      return ConfigurationAPI.patch(`/fees-chart/${feesChart.id}`, feesChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_DeleteFeesChart = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (feesChartId: any) => {
      return ConfigurationAPI.delete(`/fees-chart/${feesChartId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_CheckDuplicateFeesChartName: any = () => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      return ConfigurationAPI.get<any>(
        params ? `${"/fees-chart/name"}?${params}` : "/fees-chart/name"
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};
