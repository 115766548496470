import { useEffect, useState, FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText, styled } from "@mui/material";
import { ISelect, Checkbox } from "@ntpkunity/controls";
// import { ChevronDown } from '@ntpkunity/controls'
import clsx from "clsx";

const ChevronDown: FC<{
  className?: string;
  onClick?: (e: unknown) => void;
}> = ({ onClick, className }) => (
  <svg
    className={className}
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#9A9EB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CustomizedSelectWrapper = styled(
  "div",
  {}
)<Partial<ISelect>>(({ theme }) => ({
  "&.u-form-group": {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
    ".u-form-control": {
      height: 48,
      color: "rgba(0,0,0,0.87)",
      fontFamily: theme.typography.fontFamily,
      "&.u-select": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 16px",
        },
        ".MuiSelect-icon": {
          position: "absolute",
          top: "auto",
          right: 12,
          cursor: "pointer",
          userSelect: "none",
          PointerEvent: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)",
        ".MuiInputBase-input": {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            textFillColor: "rgba(0, 0, 0, 0.38)",
            cursor: "not-allowed",
          },
        },
        fieldset: {
          borderColor: "rgba(0, 0, 0, 0.12)",
        },
        "+": {
          ".u-input-label": {
            color: "rgba(0, 0, 0, 0.38)",
            "+": {
              ".u-help-text": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          },
        },
      },
      "&.MuiInputBase-adornedStart": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 0px",
        },
        fieldset: {
          legend: {
            maxWidth: 0,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            legend: {
              maxWidth: "100%",
            },
          },
        },
        "~": {
          ".u-input-label": {
            transform: "translate(38px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
          },
        },
      },
      "&.MuiInputBase-adornedEnd": {
        ".MuiInputBase-input": {
          padding: "12px 0px 12px 16px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        "&:hover": {
          fieldset: {
            borderColor: theme.palette.error.main,
          },
        },
        "~": {
          ".u-input-label": {
            color: theme.palette.error.main,
            "&.Mui-focused": {
              color: theme.palette.error.main,
            },
            "&.MuiFormLabel-filled": {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(14px, 12px) scale(1)",
      padding: "0 5px 0 0px",
      backgroundColor: theme.palette.common.white,
      "&.Mui-focused": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
    },
    ".u-help-text": {
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".text-capitalize": {
      textTransform: "capitalize",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
    ".MuiPopover-root": {
      position: "absolute",
      top: -15,
      left: -15,
      width: "100%",
      ".MuiPaper-root": {
        position: "relative",
        top: "64px !important",
        left: "16px !important",
      },
    },
  },
}));

const MenuSx = (theme) => ({
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EBECF2",
  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
  padding: "0",
  color: "#515466",
  maxHeight: 250,
  "@supports (-moz-appearance:none)": {
    scrollbarWidth: "thin",
  },
  ".MuiMenu-list": {
    padding: "8px 0",
  },
  "&::-webkit-scrollbar": {
    width: 7,
    height: 7,
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-track": {
    padding: "0 1px",
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-thumb": {
    transition: "all 0.3s",
    backgroundColor: "#cdcdcd",
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a6a6a6",
  },
  ".u-dropdown-item": {
    padding: "12px 24px 12px 16px",
    borderRadius: "8px",
    whiteSpace: "normal",
    margin: "0 8px",
    fontFamily: "'Inter', sans-serif",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
    },
    ".MuiTouchRipple-root": {
      display: "none",
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        margin: 0,
        display: "inline-block",
        width: "100%",
        ".custom-checkbox": {
          color: theme.palette.grey[300],
          marginRight: 0,
          position: "absolute",
          width: "97%",
          textAlign: "left",
          display: "inline-block",
          "&.Mui-checked ~ .MuiFormControlLabel-label": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.common.white + "!important"
                : theme.palette.common.black + "!important"
          }
        },
        ".MuiFormControlLabel-label": {
          marginLeft: "30px",
          paddingTop: "24px",
          color: theme.palette.grey[600]
        }
      }
    }
  }
});

const MultiSelect: React.FC<any> = ({
  theme,
  onChange: onChangeProps,
  name,
  items = [],
  label,
  value = [],
  helperText = "",
  disabled = false,
  id = "select-simple",
  capitalizeLabel,
  uppercaseLabel,
  selectError,
  getValues,
  setValue,
  isSelectAll,
  disablePortal = true,
}) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(value as any[]);
  const [selectAll, setSelectAll] = useState(isSelectAll);
  useEffect(() => {
    setSelectedValues(Array.isArray(value) ? value : []);
  }, [items]);

  const handleItemChange = (event) => {
    const {
      target: { value },
    } = event;
    if (selectedValues?.includes(value)) {
      setSelectedValues(selectedValues?.filter((item) => item !== value));
    } else {
      setSelectedValues(typeof value === "string" ? value.split(",") : value);
    }
    onChangeProps?.(event, selectedValues);
  };

  const handleSelectAll = (event) => {
    const allSelected = event.target.checked;
    const allValues = items.map((item) => item.value);

    if (allSelected) {
      setValue(name, allValues);
      setSelectedValues(allValues);
      onChangeProps?.(allValues);
    } else {
      setValue(name, []);
      setSelectedValues([]);
      onChangeProps?.([]);
    }

    setSelectAll(allSelected);
  };
  return (
    <CustomizedSelectWrapper theme={theme} className="u-form-group">
      <FormControl fullWidth>
        <Select
          MenuProps={{
            disablePortal,
            PaperProps: {
              sx: MenuSx,
            },
          }}
          inputProps={{
            name: [name],
          }}
          disabled={disabled}
          className="u-form-control u-select"
          labelId="demo-simple-select-label"
          id={id}
          multiple
          label={label}
          value={(selectedValues as []) || []}
          error={!!selectError}
          onChange={handleItemChange}
          renderValue={(selected: any[]) =>
            selected
              ? items
                ?.filter((item) => selected.includes(item.value))
                ?.map((item) => item.text)
                ?.join(", ")
              : []
          }
          sx={{ borderRadius: "8px" }}
          IconComponent={ChevronDown}
        >
          <MenuItem
            className="u-dropdown-item u-dropdown-item-multi"
            key="select-all"
          // value={}
          >
            <Checkbox
              checkBoxChecked={
                selectAll || selectedValues?.length == items.length
              }
              // indeterminate={
              //   selectedValues.length > 0 && selectedValues.length < items.length
              // }
              label={"All"}
              onChange={(event) => handleSelectAll(event)}
            />
          </MenuItem>
          {items.map((item) => (
            <MenuItem
              className="u-dropdown-item u-dropdown-item-multi"
              key={item.value}
              value={item.value}
            >
              <Checkbox
                checkBoxChecked={selectedValues?.includes(item.value)}
                label={item.text}
              // onChange={handleItemChange}
              />
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          className={clsx({
            "u-input-label": true,
            "text-capitalize": capitalizeLabel,
            "text-uppercase": uppercaseLabel,
          })}
          id="demo-simple-select-label"
        >
          {label}
        </InputLabel>
        {selectError && (
          <FormHelperText className="u-error-text" id={id}>
            {selectError}
          </FormHelperText>
        )}
        <FormHelperText className="u-help-text">{helperText}</FormHelperText>
      </FormControl>
    </CustomizedSelectWrapper>
  );
};

export default MultiSelect;
