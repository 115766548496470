import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ProgramState, QueryKeys, ToastMessages } from "Enums";
import { IProgram, IFpGeneral } from "Interface";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setProgramsInitialData, setToast } from "Stores/SetupStore/Actions";

export const getAllProgramsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/financial-product/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllPrograms_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_PROGRAM_FILTER, columnFilters],
    async () => {
      return getAllProgramsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseQuery_getAllPrograms = () => {
  const [, dispatch] = useSetupsStore();
};

export const UseMutation_UpdateProgram = (): any => {
  const queryClient = useQueryClient();
  return useMutation<IFpGeneral>(
    (financialProduct: any) => {
      return ConfigurationAPI.patch(
        `/financial-product/update-fp/${financialProduct?.financial_product_general?.id}`,
        financialProduct
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_PROGRAM_FILTER);
      },
    }
  );
};

export const UseMutation_AddProgram = (): any => {
  const queryClient = useQueryClient();
  return useMutation<IProgram>(
    (fp) => {
      return ConfigurationAPI.post("/financial-product/create-fp", fp);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries([QueryKeys.GET_ALL_PROGRAM_FILTER], {
          refetchInactive: true,
          refetchActive: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteProgram = (): any => {
  const queryClient = useQueryClient();
  return useMutation<IProgram>(
    (financialProductId) => {
      return ConfigurationAPI.delete(
        `/financial-product/${financialProductId}`
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_PROGRAM_FILTER);
      },
    }
  );
};

export const UseMutation_UpdateFpStatus = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IFpGeneral>(
    (financialProduct: any) => {
      return ConfigurationAPI.patch(
        `/financial-product/${financialProduct.id}`,
        financialProduct
      );
    },
    {
      onSuccess: async (response: any) => {
        // queryClient.setQueryData(
        //   [QueryKeys.GET_ALL_PROGRAM_FILTER],
        //   (previousData: any) => {
        //     const updatedData = previousData.result.map((item) =>
        //       item.id === response.data.id
        //         ? { ...item, ...response.data }
        //         : item
        //     );
        //     return { ...previousData, result: updatedData };
        //   }
        // );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_PROGRAM_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_CheckDuplicateProgramName: any = () => {
  return useMutation<IFpGeneral>(
    (name) => {
      return ConfigurationAPI.get(`/financial-product/name/${name}`);
    },
    {
      onSuccess: async (response: any) => {
        return response.data;
      },
    }
  );
};

export const UseMutation_CheckDuplicateProgramCode: any = () => {
  return useMutation<IFpGeneral>(
    (code) => {
      return ConfigurationAPI.get(`/financial-product/code/${code}`);
    },
    {
      onSuccess: async (response: any) => {
        return response.data;
      },
    }
  );
};

export const UseMutation_findProgramById: any = () => {
  return useMutation<any>(
    (id) => {
      return ConfigurationAPI.get(`/financial-product/${id}`);
    },
    {
      onSuccess: async (response: any) => {
        return response.data;
      },
    }
  );
};
