import { FC, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu } from "@ntpkunity/controls";
import {
  UseQuery_getAllMileage,
  UseQuery_GetAllRvCharts,
  UseMutation_DeleteCharts,
  UseMutation_UpdateCharts,
  UseQuery_GetAllTerms,
} from "services";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { Button, ConfirmationDialog, Switch } from "@sharedComponents";
import {
  IRvChart,
  ICharts,
  IRvChartCriteria,
  IAssetCondition,
  ITerms,
  IAnnualMileage,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
} from "Interface";
import RvCriteriaTable from "./rv-chart-criteria";
import { useQueryClient } from "react-query";
import { useTheme } from "@mui/material";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.RENAME_CHART}
      </>
    ),
    optionkey: ActionOptions.RENAME_CHART,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="AddIcon" />
        {ActionOptions.ADD_CRITERIA}
      </>
    ),
    optionkey: ActionOptions.ADD_CRITERIA,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

interface ChartProps {
  chart: ICharts;
  onOptionsClick?: (key, value) => unknown;
  onEditCriteria?: (data) => unknown;
  AllAssetConditions?: IAssetCondition[];
  AllTerms?: ITerms[];
  AllAnnualMileage?: IAnnualMileage[];
  AllFinanceType?: IFinanceType[];
  AllCustomerType?: ICustomerType[];
  AllAssetCategory?: IAssetCategory[];
  AllAssetType?: IAssetType[];
}

const RvChartsTable: FC<ChartProps> = ({
  chart,
  onOptionsClick,
  onEditCriteria,
  AllAssetConditions,
  AllTerms,
  AllAnnualMileage,
  AllFinanceType,
  AllCustomerType,
  AllAssetCategory,
  AllAssetType,
}) => {
  UseQuery_getAllMileage();
  UseQuery_GetAllTerms();
  UseQuery_GetAllRvCharts();

  const queryClient = useQueryClient();
  const rvCharts: Array<IRvChart> = queryClient.getQueryData(
    QueryKeys.GET_ALL_RV_CHARTS
  );
  const [open, setOpen] = useState(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [actionData, setActionData] = useState<IRvChartCriteria>(undefined);
  const { mutate: deleteChart } = UseMutation_DeleteCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();

  const handleSelection = (event, key, value) => {
    onOptionsClick(key, value);

    if (key === ActionOptions.DELETE) {
      setActionData(value.id);
      setConfirmationPopupState(true);
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteChart(actionData, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const handleEnableChange = (data: ICharts, event) => {
    let updatedData = {
      chart_name: data.chart_name,
      chart_type_id: data.chart_type_id,
      id: data.id,
      is_active: event?.target?.checked,
    };
    UpdateChart(updatedData);
  };

  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="indent-cell" colSpan={15}>
          {rvCharts?.filter((item) => item?.chart_id == chart?.id).length >=
          0 ? (
            open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )
          ) : (
            <></>
          )}
          {chart?.chart_name}
        </TableCell>
        <TableCell>
          <Switch
            theme={theme}
            varient={"basic"}
            label={chart?.is_active ? "Enabled" : "Disabled"}
            switchEnabled={chart?.is_active}
            onChange={(event) => handleEnableChange(chart, event)}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell space-fixed">
          <Menu
            theme={theme}
            disablePortal
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: chart,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>

      {open ? (
        <>
          <TableRow>
            <TableCell colSpan={17} className="second-level-cell chart-header">
              {" "}
              Chart Criteria {"("}
              {rvCharts &&
                rvCharts.filter((item) => item.chart_id == chart.id).length}
              {")"}
            </TableCell>
          </TableRow>

          {rvCharts &&
            rvCharts
              ?.filter((item) => item.chart_id == chart.id)
              ?.map((item, index) => (
                <RvCriteriaTable
                  key={index}
                  chart={chart}
                  criteria={item}
                  onEditCriteria={(data) => onEditCriteria(data)}
                  AssetCondition={AllAssetConditions}
                  terms={AllTerms}
                  annualMileages={AllAnnualMileage}
                  financeType={AllFinanceType}
                  customerType={AllCustomerType}
                  assetCategory={AllAssetCategory}
                  assetType={AllAssetType}
                />
              ))}
        </>
      ) : null}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default RvChartsTable;
