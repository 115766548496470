import { StoreActionType } from "Types";
import { ICreditRating } from "Interface";
import { ActionTypes } from "Enums";

export const CreditRatingReducer = (
  state: Array<ICreditRating> = [],
  action: StoreActionType
): Array<ICreditRating> => {
  switch (action.type) {
    case ActionTypes.ADD_CREDIT_RATING:
      return [...state, action.payload];
    // return state.concat(action.payload)

    case ActionTypes.SET_CREDIT_RATING_INIT_STATE:
      return [...action.payload];
    // return state.concat(action.payload)

    case ActionTypes.UPDATE_CREDIT_RATING:
      const indexToUpdate = state.findIndex(
        (rating) => rating.id == action.payload?.credit_rating_id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
