export enum QueryKeys {
  GET_ALL_ANNUAL_MILEAGES = "GET ALL MILEAGES",
  GET_ALL_FINANCED_AMOUNTS = "GET ALL FINANCED AMOUNTS",
  GET_ALL_ANNUAL_MILEAGES_ACTIVE = "GET ALL MILEAGES ACTIVE",
  GET_ALL_FINANCED_AMOUNTS_ACTIVE = "GET ALL FINANCED AMOUNTS ACTIVE",
  GET_ALL_ANNUAL_MILEAGES_FILTER = "GET ALL MILEAGES FILTER",
  GET_ALL_FINANCED_AMOUNTS_FILTER = "GET ALL FINANCED AMOUNTS FILTER",
  GET_ALL_CREDIT_RATINGS = "GET ALL CREDIT RATINGS",
  GET_ALL_CREDIT_RATINGS_ACTIVE = "GET ALL CREDIT RATINGS ACTIVE",
  GET_ALL_CREDIT_RATING_FILTER = "GET ALL CREDIT RATINGS FILTER",
  GET_ALL_ASSET_CATALOGUE = "GET_ALL_ASSET_CATALOGUE",
  GET_ALL_ASSET_MAKES = "GET ALL ASSET MAKES",
  GET_ALL_ASSET_MAKES_LIST = "GET_ALL_ASSET_MAKES_LIST",
  GET_ALL_ASSET_MAKES_MODELS_TRIMS = "GET_ALL_ASSET_MAKES_MODELS_TRIMS",
  GET_ALL_ASSET_MODELS = "GET ALL ASSET MODELS",
  GET_ASSET_MODEL_BY_MAKE = "GET ASSET MODEL BY MAKE",
  GET_ALL_ASSET_TRIMS = "GET ALL ASSET TRIMS",
  GET_ASSET_TRIM_BY_MODEL = "GET ASSET TRIM BY MODEL",
  GET_ALL_INTEREST_CHARTS = "GET_ALL_INTEREST_CHARTS",
  GET_ALL_INTEREST_CHARTS_FILTER = "GET_ALL_INTEREST_CHARTS_FILTER",
  GET_ALL_RV_CHARTS_FILTER = "GET_ALL_RV_CHARTS_FILTER",
  GET_ALL_INTEREST_CHARTS_BY_CHART = "GET_ALL_INTEREST_CHARTS_BY_CHART",
  GET_ALL_TERMS = "GET_ALL_TERMS",
  GET_ALL_TERMS_ACTIVE = "GET ALL TERMS ACTIVE",
  GET_ALL_TERMS_FILTER = "GET ALL TERMS FILTER",
  GET_ALL_CHARTS = "GET ALL CHARTS",
  GET_ALL_RV_CHARTS = "GET ALL RV CHARTS",
  GET_COMPANY_PROFILE = "GET LENDER PROFILE",
  GET_LENDER_PROFILE_BY_TENANT = "GET LENDER PROFILE BY TENANT",
  GET_ALL_OPTION_CATEGORIES = "GET_ALL_OPTION_CATEGORIES",
  GET_ALL_OPTION_CATEGORIES_ACTIVE = "GET_ALL_OPTION_CATEGORIES_ACTIVE",
  GET_ALL_OPTION_CATEGORY_FILTER = "GET_ALL_OPTION_CATEGORY_FILTER",
  GET_ALL_CONTRACT_TERMS_FILTER = "GET ALL CONTRACT TERMS FILTER",
  GET_ALL_OPTIONS_FILTER = "GET ALL DEALER OPTIONS FILTER",
  GET_ALL_OEM_OPTIONS_FILTER = "GET ALL OEM OPTIONS FILTER",
  GET_ALL_CONTRACT_ALLOWED_ANNUM_MILEAGE = "GET ALL CONTRACT ALLOWED ANNUM MILEAGE",
  GET_ALL_CONTRACT_ALLOWED_ANNUM_MILEAGE_FILTER = "GET ALL CONTRACT ALLOWED ANNUM MILEAGE FILTER",
  GET_ALL_DEALERS = "GET ALL DEALERS",
  GET_ALL_PROGRAM = "GET_ALL_PROGRAM",
  GET_ALL_PROGRAM_FILTER = "GET ALL PROGRAM FILTER",
  GET_ALL_ADDRESSESS = "GET ALL ADRESSESS",
  GET_ALL_USA_STATES = "GET ALL USA STATES",
  GET_ALL_DOCUMENT_TYPES = "GET ALL DOCUMENT TYPES",
  GET_ALL_BUSINESS_UNITS = "GET ALL BUSINESS UNITS",
  GET_ALL_OTHER_COSTS = "GET ALL OTHER COSTS",
  GET_ALL_DOCUMENT_TYPES_FILTER = "GET ALL DOCUMENT TYPES FILTER",
  GET_ALL_BUSINESS_UNITS_FILTER = "GET ALL BUSINESS UNITS FILTER",
  GET_ALL_OTHER_COSTS_FILTER = "GET ALL OTHER COSTS FILTER",
  GET_ALL_STIPULATIONS = "GET_ALL_STIPULATIONS",
  GET_ALL_STIPULATIONS_FILTER = "GET ALL STIPULATIONS FILTER",
  GET_ALL_ASSET_CONDITIONS_FILTER = "GET ALL ASSET CONDITIONS FILTER",
  GET_ALL_ASSET_USAGES_FILTER = "GET ALL ASSET USAGES FILTER",
  GET_ALL_ASSET_USAGES = "GET ALL ASSET USAGES",
  GET_THEME_BUILDER = "GET THEME BUILDER",
  Get_All_Asset_Conditions = "GET ALL ASSET CONDITIONS",
  Get_All_Business_Units = "GET ALL BUSINESS UNITS",
  GET_ALL_ASSET_CONDITIONS_ACTIVE = "GET ALL ASSET CONDITIONS ACTIVE",
  GET_ALL_CURRENCIES = "GET ALL CURRENCIES",
  GET_ALL_FEE_NAMES = "GET_ALL_FEE_NAMES",
  GET_ALL_FEE_NAMES_FILTER = "GET ALL FEE NAMES FILTER",
  GET_ALL_INTEGRATION_STATUS = "GET_ALL_INTEGRATION_STATUS",
  GET_ALL_INTEGRATION_STATUS_FILTER = "GET ALL INTEGRATION STATUS FILTER",
  GET_ALL_LENDER_INTEGRATIONS = "GET ALL LENDER INTEGRATIONS",
  GET_ALL_LENDER_INTEGRATIONS_FILTER = "GET ALL LENDER INTEGRATIONS FILTER",
  GET_ALL_DEALER_FILTER = "GET ALL DEALER FILTER",
  GET_ACTIVE_DEALERS = "GET ACTIVE DEALERS",
  GET_DEALER_BY_EMAIL = "GET DEALER BY EMAIL",
  GET_ALL_DECISION_STATUS = "GET ALL DECISION STATUS",
  GET_ALL_DECISION_STATUS_FILTER = "GET ALL DECISION STATUS FILTER",
  GET_ALL_DEALER_INTEGRATION = "GET ALL DEALER INTEGRATIONS",
  GET_ALL_DEALER_INTEGRATION_FILTER = "GET ALL DEALER INTEGRATION FILTER",
  GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER = "GET ALL FINANCIAL INSURANCE PRODUCT FILTER",
  GET_ALL_DEALER_FEE_FILTER = "GET ALL DEALER FEE FILTER",
  GET_ALL_INVENTORY_FILTER = "GET ALL INVENTORY FILTER",
  GET_ALL_INVENTORY_OPTION_FILTER = "GET ALL INVENTORY OPTION FILTER",
  GET_ALL_STATES = "GET_ALL_STATES",
  GET_ALL_DEALER_OPTIONS_FILTER = "GET ALL DEALER OPTIONS FILTER",
  GET_ALL_COUNTRIES = "GET ALL COUNTRIES",
  GET_ALL_RADIUS = "GET ALL RADIUS",
  GET_ALL_SUBSIDY_CHARTS_FILTER = "GET ALL SUBSIDY CHARTS FILTER",
  GET_ALL_SUBSIDY_CHARTS = "GET ALL SUBSIDY CHARTS",
  GET_ALL_COMMISSION_CHARTS_FILTER = "GET ALL COMMISSION CHARTS FILTER",
  GET_ALL_COMMISSION_CHARTS = "GET ALL COMMISSION CHARTS",
  GET_ALL_DOWN_PAYMENT_CHARTS_FILTER = "GET ALL DOWN_PAYMENT CHARTS FILTER",
  GET_ALL_DOWN_PAYMENT_CHARTS = "GET ALL DOWN_PAYMENT CHARTS",
  GET_ALL_CHARTS_FILTER = "GET ALL CHARTS FILTER",
  GET_ALL_ACTIVE_DEALERS = "GET ALL Active Dealers",
  GET_ALL_ACTIVE_LANGUAGES = "GET ALL ACTIVE LANGUAGES",
  GET_ALL_TENANT_LANGUAGES = "GET ALL TENANT LANGUAGES",
  GET_LENDER_LANGUAGES_AND_TRANSLATIONS = "Get Lender Languages and Translations",
  GET_ALL_STATIONERY_RECORDS = "Get all stationery records",
  GET_ALL_TENANT_DOCUMENTS = "Get all tenant documents",
  Get_All_Events = "GET ALL Events",
  Get_All_Base_Rate_source = "Get all base rate source",
  GET_ALL_LENDER = "GET ALL LENDERS",
  GET_ALL_LENDER_FILTER = "GET ALL LENDER FILTER",
  GET_ALL_CONFIGURED_LENDERS = "GET ALL CONFIGURED LENDERS",
  GET_ALL_TRADING_AGE_FILTER = "GET ALL TRADING AGE FILTER",
  GET_ALL_TRADING_AGE = "GET ALL TRADING AGES",
  GET_ALL_TRADING_AGE_ACTIVE = "GET ALL TRADING AGE ACTIVE",
  GET_ALL_FINANCE_TYPES = "GET ALL FINANCE TYPES",
  Get_All_FINANCE_TYPE = "GET ALL FINANCE TYPE",
  Get_All_CUSTOMER_TYPE = "GET ALL CUSTOMER TYPE",
  Get_All_ASSET_CATEGORY = "GET ALL ASSET CATEGORY",
  Get_All_ASSET_TYPE = "GET ALL ASSET TYPE",
  GET_DEALER_PROVIDERS = "GET DEALER PROVIDERs",
  GET_ALL_PRE_QUALIFICATION_FILTER = "GET ALL PRE QUALIFICATION FILTER",
  GET_ALL_PRE_QUALIFICATION_ACTIVE = "GET ALL PRE QUALIFICATION ACTIVE",
  Get_All_PRE_QUALIFICATION = "GET ALL PRE QUALIFICATION ",
  Get_All_PRODUCT_TYPE = "GET_ALL_PRODUCT_TYPES",
  GET_ALL_FEES_CHARTS_FILTER = "GET ALL FEES CHART FILTER",
  GET_ALL_FEES_CHARTS = "GET ALL FEES CHARTS",
  Get_All_COMPANY_DOCUMENTS = "GET ALL COMPANY DOCUMENTS",
  GET_ALL_COMPATIBLE_OPTIONS = "GET ALL COMPATIBLE OPTIONS",
  Get_All_SLUGS = "GET ALL SLUGS",
  GET_ALL_FEES_MAPPINGS = "GET_ALL_FEES_MAPPINGS",
  GET_ALL_OPTION_MAPPINGS = "GET_ALL_OPTION_MAPPINGS",
  GET_ALL_FP_CATEGORY_MAPPINGS = "GET_ALL_FP_CATEGORY_MAPPINGS",
  GET_TENANT_CONFIGURATION = "GET_TENANT_CONFIGURATION",
  GET_ALL_DEALER_CHAT = "GET ALL DEALER CHAT",
  GET_ALL_DEALER_RESERVATIONS = "GET ALL DEALER RESERVATIONS",
  GET_USER_SUBSCRIBE_PRODUCTS = "GET USER SUBSRIBE PRODUCTS",
  GET_COMPANY_ADDED_LENDERS = "GET COMPANY ADDED LENDER DETAILS",
  GET_ASSOCIATED_DEALERSHIPS = "GET_ASSOCIATED_DEALERSHIPS",
  CHECK_PREFERRED_ACCESSORIES = "CHECK_PREFERRED_ACCESSORIES",
  GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BY_FINANCETYPE = "GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BY_FINANCETYPE",
  GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER = "GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER",
  GET_ALL_REBATE_FILTER = "GET ALL REBATE FILTER",
  GET_ALL_FINANCE_MAPPINGS = "GET_ALL_FINANCE_MAPPINGS",
  CHECK_PREFERRED_FNI_PRODUCTS = "CHECK_DEALER_PREFERRED_FNI_PRODUCTS",
  GET_ALL_CONFIGURED_APPOINTMENTS = "GET_ALL_CONFIGURED_APPOINTMENTS",
  GET_ALL_DEALER_APPOINTMENTS = "GET_ALL_DEALER_APPOINTMENTS",
  GET_ALL_CONFIGURED_TIMESLOTINTERVALS = "GET_ALL_CONFIGURED_TIME_SLOT_INTERVALS",
  GET_ALL_DEALER_MARKUPS_SLABS = "GET_ALL_DEALER_MARKUPS_SLABS",
  GET_ALL_DEALER_MARKUPS_FILTERS = "GET_ALL_DEALER_MARKUPS_FILTER",
  GET_DEALER_CONFIGURED_WORKING_DAYS = "GET_ALL_CONFIGURED_DEALER_WORKING_DAYS",
  GET_DEALER_CONFIGURED_HOLIDAYS = "GET_ALL_CONFIGURED_DEALER_HOLIDAYS",
  GET_ALL_LENDER_SELECTION_RULES = "GET_ALL_LENDER_SELECTION_RULES",
  ADD_LENDER_SELECTION_RULE = "ADD_LENDER_SELECTION_RULE",
  EDIT_LENDER_SELECTION_RULE = "EDIT_LENDER_SELECTION_RULE",
  DELETE_LENDER_SELECTION_RULE = "DELETE_LENDER_SELECTION_RULE",
  GET_USER_BY_ROLE = "GET_USER_BY_ROLE",
}
