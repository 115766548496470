import { ConfigurationAPI } from "services";
import { QueryKeys } from "Enums";
import { useQuery } from "react-query";

export const getAllFinanceTypesFromServer = () => {
  return ConfigurationAPI.get("/finance_type").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllFinanceTypes = () => {
  return useQuery(QueryKeys.GET_ALL_FINANCE_TYPES, async () => {
    return getAllFinanceTypesFromServer().then((data) => {
      return data;
    });
  });
};
