import { FC, useContext, useEffect, useState } from "react";
import { LenderProfile } from "shared/components";
import { DealerContext } from "context";
import { Module } from "Enums";
import { UseQueryGetDealerIntergrations } from "services";
import { IntegrationType, Providers } from "Enums/IntegrationTypesAndProviders";

const DealerLenderList: FC = () => {
  const { dealer_code } = useContext(DealerContext);
  const [provider, setProvider] = useState(null);
  const { data: dealerIntegrations, isFetched } =
    UseQueryGetDealerIntergrations(dealer_code);

  useEffect(() => {
    if (dealerIntegrations) {
      let integration = dealerIntegrations?.find(
        (x) => x.integration_type == IntegrationType.DEALER_LENDER_ASSOCIATION
      );
      let provider = integration?.providers?.find(
        (x) =>
          (x.is_active && x.provider_name == Providers.DEALER_TRACK_LENDER) ||
          x.provider_name == Providers.ROUTEONE
      );
      setProvider(provider);
    }
  }, [dealerIntegrations]);

  return (
    <LenderProfile
      module={Module.DEALER_MODULE}
      dealer_code={dealer_code}
      provider={provider}
    />
  );
};

export default DealerLenderList;
