import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";

export const LenderSetupReducer = (
  state: boolean,
  action: StoreActionType
): boolean => {
  switch (action.type) {
    case ActionTypes.SET_DRAWER_OPEN:
      return (state = action.payload);
    default:
      return state;
  }
};
