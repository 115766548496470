import { useTheme } from "@mui/material";
import {
  IFileDragDropProps,
  unityTheme,
  FileDragDrop,
} from "@ntpkunity/controls";
import { FC } from "react";

const Component: FC<Partial<IFileDragDropProps>> = ({
  // theme,
  accept,
  imageButton = false,
  hoverLabel = "Click or drag to upload file",
  dropLabel = "Drop file here",
  width = "600px",
  height = "100px",
  backgroundColor = "#000000",
  image,
  onChange,
  disabled,
  onDrop,
}) => {
  const theme = useTheme();

  return (
    <FileDragDrop
      disabled={disabled}
      theme={theme}
      accept={accept}
      imageButton={imageButton}
      hoverLabel={hoverLabel}
      dropLabel={dropLabel}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      image={image}
      onChange={onChange}
      onDrop={onDrop}
      allowMultiple
    />
  );
};

export default Component;
