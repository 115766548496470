import { StoreActionType } from "Types";
import { IContractAllowedAnnumMileage } from "Interface";
import { ActionTypes } from "Enums";

export const ContractAllowedAnnumMileageReducer = (
  state: Array<IContractAllowedAnnumMileage> = [],
  action: StoreActionType
): Array<IContractAllowedAnnumMileage> => {
  switch (action.type) {
    case ActionTypes.SET_CONTRACT_ALLOWED_ANNUM_MILLEAGE_DATA:
      return [...action.payload];

    case ActionTypes.UPDATE_CONTRACT_ALLOWED_ANNUM_MILLEAGE:
      const indexToUpdate = state.findIndex(
        (contractAllowedAnnumMileage) =>
          contractAllowedAnnumMileage.id == action.payload?.id
      );
      const newArray = [...state];
      newArray[indexToUpdate] = action.payload;
      return newArray;

    default:
      return state;
  }
};
