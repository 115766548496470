import { ConfigurationAPI } from "../services/http-common";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "../Enums";
import { useStoreContext } from "../Stores/SetupStore/Store";
import { IChartCriteria, ICharts, ICriteria } from "../Interface";

export const getAllCommissionChartsFromServer = () => {
  return ConfigurationAPI.get("/commissions").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllCommissionCharts = () => {
  return useQuery(QueryKeys.GET_ALL_COMMISSION_CHARTS, async () => {
    return getAllCommissionChartsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAllCommissionChartsFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/commissions/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllCommissionCharts_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER, columnFilters],
    async () => {
      return getAllCommissionChartsFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_DeleteCommissionChart = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ICharts>(
    (id) => {
      return ConfigurationAPI.delete(`/commissions/${id}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_COMMISSION_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewCommissionCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<IChartCriteria>>(
    (commissionChart) => {
      return ConfigurationAPI.post("/commissions/criteria", commissionChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_COMMISSION_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddNewCommissionCriteria = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (commissionChart) => {
      return ConfigurationAPI.post("/commissions", commissionChart);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_COMMISSION_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_UpdateCommissionCharts = (
  down_payment_id: number
): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<Array<ICriteria>>(
    (commissionCharts: any) => {
      return ConfigurationAPI.patch(
        `/commissions/${commissionCharts.id}`,
        commissionCharts
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_COMMISSION_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
